<template>

  <q-card style="width: 1024px; max-width: 80vw" class="q-pa-md">
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5">
          <p>Удаление сервиса</p>
          <p>«{{ item.name }}»</p>
        </q-item-label>
      </q-card-section>
    </q-item>

    <q-separator />

    <div v-if="!isServiceListBusy">
      <q-card-section>
        <p>Вы действительно хотите удалить камеру ?</p>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn color="negative" @click="actualDelete"> Удалить </q-btn>
        <q-btn color="primary" @click="closeDelete"> Отмена </q-btn>
      </q-card-actions>
    </div>
  </q-card>

</template>

<script lang="ts">

import store from "@/store";
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ServiceDelete',

  props: {
    serviceId: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      item: {
        id: 0,
        name: ''
      },
    }
  },

  computed: {
    isServiceListBusy() {
      return store.getters.getIsServiceListBusy
    },
  },

  methods: {

    //Удалить сервис
    actualDelete() {
      store.dispatch('deleteServiceListItem', {id: this.serviceId}).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )

    },

    //Закрыть модалку
    closeDelete() {
      this.$emit('hideModal')
    },

    //Загрузить данные о сервисе
    loadData() {
      store
          .dispatch('getServiceListItem', {
            filter: [
              {
                field_name: 'service_id',
                operand: '=',
                value: this.serviceId,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getServiceId(),
                  name: resolve.getServiceName(),
                }
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },
  },

  created() {
    this.loadData()
  },


})
</script>

<style lang="scss">

</style>
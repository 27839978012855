import HomePage from '../views/HomePage.vue'
import {createRouter, createWebHistory} from 'vue-router'

// import accounts from '@/components/modules/accounts/router/index'
import users from '@/components/modules/users/router/index'
import cameras from '@/components/modules/cameras/router/index'
import zone from '@/components/modules/zone/router/index'
import model from '@/components/modules/models/router/index'
import services from '@/components/modules/services/router/index'
import companies from '@/components/modules/company/router/index'
import statistic from '@/components/modules/statistic/router/index'
import message from '@/components/modules/message/router/index'
import change from '@/components/modules/change/router/index'

let routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
        meta: {
            breadCrumb: [
                {text: 'Главная', to: {name: 'HomePage'}, icon: 'home'}
            ]
        }
    },
    {
        path: '/:catchAll(.*)*',
        component: () => import('../ErrorNotFound.vue'),
    },
]

// routes = routes.concat(accounts.routes as any)
routes = routes.concat(users.routes as any)
routes = routes.concat(cameras.routes as any)
routes = routes.concat(zone.routes as any)
routes = routes.concat(model.routes as any)
routes = routes.concat(services.routes as any)
routes = routes.concat(companies.routes as any)
routes = routes.concat(statistic.routes as any)
routes = routes.concat(message.routes as any)
routes = routes.concat(change.routes as any)

export default () => createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return {top: 0}
    },
})

<template>

  <q-card
      style="width: 1024px; max-width: 80vw; height: 640px; max-height: 80vh"
      class="q-pa-md"
  >
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5"
        >Добавлении новой камеры</q-item-label
        >
      </q-card-section>
    </q-item>

    <div v-if="!isCamerasListBusy">
      <q-form
          @submit="saveCamera"
          greedy
      >
        <q-card-section>
          <q-input
              ref="item.name"
              v-model="item.name"
              label="Название"
              filled
              lazy-rules
              :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              ref="model"
              v-model="item.model_id"
              :options="getModelListItems"
              option-label="label"
              option-value="id"
              filled
              map-options
              emit-value
              label="Модели"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-input
              ref="item"
              v-model="item.login"
              label="Логин"
              filled
              lazy-rules
              :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              ref="password"
              v-model="item.password"
              label="Пароль"
              type="password"
              filled
              :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.survey_period"
              label="Период"
              hint="Период необходимо указываеть в миллисекунды"
              filled
              type="number"
              lazy-rules
              :rules="[
              (val) => (val && val >= 15000) || 'Поле должно быть заполнено и минимальное значение должно составлять 15000 мс',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.stat_period"
              label="Период обновления статистики"
              hint="Период обновления статистики необходимо указываеть в миллисекунды"
              filled
              type="number"
              lazy-rules
              :rules="[
              (val) => (val && val >= 300000) || 'Поле должно быть заполнено и минимальное значение должно составлять 300000 мс',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              v-model="item.type"
              :options="typeList"
              option-label="label"
              option-value="value"
              filled
              map-options
              emit-value
              label="Тип подключения к камере"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-input
              ref="ip"
              v-model="item.ip"
              label="IP"
              filled
              lazy-rules
              :rules="[
              (val) => (val.length > 0 || item.mac.length > 0 || item.url.length > 0) || 'Одно из полей IP/MAC/URL должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              type="text"
              ref="mac"
              v-model="item.mac"
              label="MAC-адрес"
              filled
              mask="NN:NN:NN:NN:NN:NN"
              lazy-rules
              :rules="[
              (val) => (val.length > 0 || item.ip.length > 0 || item.url.length > 0) || 'Одно из полей IP/MAC/URL должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              ref="url"
              v-model="item.url"
              label="URL"
              filled
              lazy-rules
              :rules="[
              (val) => (val.length > 0 || item.mac.length > 0 || item.ip.length > 0) || 'Одно из полей IP/MAC/URL должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              ref="service"
              v-model="item.service_id"
              :options="getServiceListItems"
              option-label="label"
              option-value="id"
              filled
              map-options
              emit-value
              label="Сервисы"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-select
              ref="company"
              v-model="item.company_id"
              :options="getCompanyListItems"
              option-label="label"
              option-value="id"
              filled
              map-options
              emit-value
              label="Компании"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <div id="map">
          <q-spinner
              v-if="distanceLoaded"
              color="primary"
              size="60px"
          ></q-spinner>
        </div>
        <q-card-actions align="right">
          <q-btn color="positive" type="submit"> Сохранить </q-btn>
          <q-btn color="negative" @click="closeCameraAdd"> Отмена </q-btn>
        </q-card-actions>
      </q-form>
    </div>
  </q-card>

</template>

<script lang="js">

import store from "@/store";
import { defineComponent } from 'vue'
import { loadYmap } from "vue-yandex-maps";

export default defineComponent({
  name: 'CameraAdd',

  data() {
    return {
      userLatitude: '',
      userLongitude: '',
      item: {
        name: '',
        model: '',
        model_id: '',
        login: '',
        password: '',
        survey_period: '',
        ip: '',
        mac: '',
        url: '',
        service: '',
        service_id: '',
        company: '',
        company_id: '',
        created: '',
        uuid: '',
        latitude: '',
        longitude: '',
        stat_period: '',
        type:''
      },
      distanceLoaded: true,
      typeList: []
    }
  },

  computed: {

    isCamerasListBusy() {
      return store.getters.getIsCamerasListBusy
    },

    //Формирование списка сервисов
    getServiceListItems() {
      const items = store.getters.getServiceListItems
      let rows = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getServiceId(),
            label: item.getServiceName()
          })
        }
      }
      return rows
    },

    //Формирование списка моделей
    getModelListItems() {
      const items = store.getters.getModelListItems
      let rows = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            id: model.getModelId(),
            label: model.getModelName()
          })
        }
      }
      return rows
    },

    //Формирование списка компаний
    getCompanyListItems() {
      const items = store.getters.getCompanyListItems
      let rows = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            id: model.getCompanyId(),
            label: model.getCompanyName()
          })
        }
      }
      return rows
    },

  },

  methods: {

    //Закрытие модалки
    closeCameraAdd() {
      this.$emit('hideModal')
    },

    //Фильтр сервисов
    getFilters() {
      return [
        {
          field_name: 'service_name',
          operand: '>',
          value: 0,
        }
      ]
    },

    //Сохранение камеры
    saveCamera() {

      let data = {
        name: this.item.name,
        model_id: this.item.model_id,
        login: this.item.login,
        password: this.item.password,
        survey_period: this.item.survey_period,
        ip: this.item.ip,
        mac: this.item.mac,
        url: this.item.url,
        service_id: this.item.service_id,
        company_id: this.item.company_id,
        created: Date(),
        uuid: this.item.uuid,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        stat_period: Number(this.item.stat_period),
        type: this.item.type
      }
      store.dispatch('createCamerasItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
      return true
    },

    //Получение списка сервисов
    loadServiceListItems() {
      store
          .dispatch('getServiceListItems', {
            filter: this.getFilters(),
          })
          .then(
              (resolve) => {
                console.log('getServiceListItems resolve', resolve)
              },
              (reject) => {
                console.log('getServiceListItems reject', reject)
              }
          )
    },

    //Получение списка моделей
    loadModelListItems() {
      store
          .dispatch('getModelListItems', {})
          .then(
              (resolve) => {
                console.log('getModelListItems resolve', resolve)
              },
              (reject) => {
                console.log('getModelListItems reject', reject)
              }
          )
    },

    //Получение списка компаний
    loadCompanyListItems() {
      store
          .dispatch('getCompanyListItems', {})
          .then(
              (resolve) => {
                console.log('getCompanyListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCompanyListItems reject', reject)
              }
          )
    },

    camerasTypes(){
      store
        .dispatch('camerasTypes', {})
        .then(
          (resolve) => {
            console.log('camerasTypes resolve', resolve)
            resolve.getItemsList().forEach(res => {
              const item = {
                value: res.getCameraTypeId(),
                label: res.getCameraTypeName()
              }
              this.typeList.push(item)
            })
          },
          (reject) => {
            console.log('camerasTypes reject', reject)
          }
        )
    },

    //Определение геолокации
    getGeolocation(){
      navigator.geolocation.getCurrentPosition(position => {
        this.userLatitude = position.coords.latitude
        this.userLongitude = position.coords.longitude
        this.map()
        this.distanceLoaded = false
      })
    },

    //Яндекс карта
    async map() {

      await loadYmap({apiKey: 'e98d34f8-157a-43e9-9dc5-46d8dd7e4f59'}, {debug: true});

      setTimeout(() => {
        let mapCenter = [this.userLatitude,this.userLongitude],
            selectedLatitude,
            selectedLongitude

        function init() {
          let placemark,
            map = new window.ymaps.Map('map', {
            center: mapCenter,
            zoom: 13,
            controls: ['zoomControl', 'searchControl']
          }, {});


          map.events.add('click', function (e) {
            let coords = e.get('coords');

            // Если метка уже создана – просто передвигаем ее.
            if (placemark) {
              placemark.geometry.setCoordinates(coords);
              selectedLatitude = placemark.geometry.getCoordinates()[0]
              selectedLongitude = placemark.geometry.getCoordinates()[1]
            }
            // Если нет – создаем.
            else {
              placemark = createPlacemark(coords);
              map.geoObjects.add(placemark);
              selectedLatitude = placemark.geometry.getCoordinates()[0]
              selectedLongitude = placemark.geometry.getCoordinates()[1]
            }
          });

          function createPlacemark(coords) {
            return new window.ymaps.Placemark(coords, {
            }, {
              preset: 'islands#violetDotIconWithCaption',
              draggable: true
            });
          }

        }

        document.addEventListener('click', () => {
          this.item.latitude = selectedLatitude
          this.item.longitude = selectedLongitude
        })

        window.ymaps.ready(init)
      }, 50)

    }

  },

  mounted() {
    this.camerasTypes()
    this.getGeolocation()
    this.loadServiceListItems()
    this.loadModelListItems()
    this.loadServiceListItems()
  },
})
</script>

<style lang="scss">

  #map{
    width: 100%;
    height: 400px;
    padding: 0 16px;
    margin: 0 0 16px 0;
    position: relative;
    .q-spinner{
      position: absolute;
      top: 45%;
      left: 45%;
      transform: translate(-45%,-45%);
      z-index: 9999;
    }
  }
  .q-separator--horizontal{
    display: none !important;
  }
  .q-card__actions{
    padding: 0 16px 8px 16px!important;
    .q-btn{
      padding: 12px 24px !important;
      border-radius: 10px;
      &:last-child{
        background: #3742fa !important;
      }
    }
  }

</style>
import { AuthApi } from './api/AuthApi';
import * as grpcWeb from 'grpc-web';

import Notify from 'quasar/src/plugins/Notify.js';;

const authApi = new AuthApi();

export class RefreshTokenInterceptor implements grpcWeb.UnaryInterceptor<any, any> {

  async intercept(
    request: grpcWeb.Request<any, any>,
    invoker: (request: grpcWeb.Request<any, any>) => any,
    numRetries = 2,
  ) {
    return await invoker(request)
      .then((response: grpcWeb.UnaryResponse<any, any>) => {
        return response;
      })
      .catch(async (err: any) => {

        if (err.message.match('refresh-токен не найден')?.[0]) {

          Notify.create({
            message: 'Сессия устарела,перезайдите в аккаунт',
            type: 'negative',
          });

          throw err;
        }

        if (err.message.match('текущего пользователя')?.[0]){

          if (authApi.refreshRequest) {
            const response = await authApi.refreshRequest;

            request.getMetadata().Authorization = response.getAccessToken();
            return await this.intercept(request, invoker, numRetries - 1);
          }

          const refreshKey = localStorage.getItem('refreshToken');

          if (!refreshKey) throw err;

          return await authApi
            .checkRefreshToken({
              refreshToken: refreshKey,
            })
            .then(async (res: any) => {

              /*
              if (res.message.match('refresh-токен не найден')?.[0]) {
                Notify.create({
                  message: 'Сессия устарела,перезайдите в аккаунт',
                  type: 'negative',
                  badgeStyle: 'opacity: 0'
                });

                setTimeout(() => {
                  location.reload()
                },500)

                throw err;
              }*/
              request.getMetadata().Authorization = res.accessToken;
              console.log('request', request);
              return await this.intercept(request, invoker, numRetries - 1);
            });
        }

        return err;
      });
  }
}

<template>
  <div class="message-list">
    <q-card style="box-shadow: none">
      <div class="row">
        <div class="col-12">
          <div class="pre-header">
            <div>
              <h3 style="margin: 0;">Сообщения: {{ getMessageListItems.length }}</h3>
            </div>
          </div>
          <q-table
              flat
              :rows="getMessageListItems"
              :columns="columns"
              row-key="id"
              @request="onRequestMessage"
          >
            <template v-slot:top>
              <q-input
                  dense
                  v-model="selectedCameraUuid"
                  filled
                  label="UUID камеры"
              >
              </q-input>
              <q-input
                  dense
                  v-model="selectedCameraName"
                  filled
                  label="Название камеры"
              >
              </q-input>
              <q-btn class="button" @click="clearSelect">Очистить</q-btn>
              <q-btn class="button" @click="search">Отфильтровать</q-btn>
            </template>
            <template v-slot:body-cell-camera="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-zone="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-date="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-busy="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-allowed="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </q-card>
  </div>
</template>



<script lang="ts">

/*import store from '@/store'*/
import {defineComponent} from 'vue'
import store from "@/store";

export default defineComponent({
  name: "MessageList",

  data(){
    return{
      item: {
        id: 0,
        camera_id: 0,
        camera_name: '',
        camera_uuid: '',
        message: '',
        created: ''
      },
      columns: [
        {
          name: 'camera_name',
          label: 'Название камеры',
          field: 'camera_name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'message',
          label: 'Сообщение',
          field: 'message',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }
      ] as any,
      messagePagination: {
        sortBy: 'message_id',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      selectedCameraUuid: null as any,
      selectedCameraName: '',
    }
  },

  computed: {
    //Формирование списка сообщений
    getMessageListItems() {
      const messages = store.getters.getMessageListItems
      const cameras = store.getters.getCamerasListItems

      let rows: any = []

      if (messages !== undefined && cameras !== undefined) {
        for (let message of messages) {
          for (let camera of cameras){
            if(message.getMessageCameraId() === camera.getCameraId()){
              rows.push({
                id: message.getMessageId(),
                camera_id: message.getMessageCameraId(),
                camera_name: camera.getCameraName(),
                camera_uuid: camera.getCameraUuid(),
                message: message.getMessageText(),
                created: message.getMessageCreated()
              })
            }
          }
        }
      }
      return rows
    },

  },

  methods: {

    //Фильтры для сообщений
    getMessageFilters(){
      let filter = []
      if(this.selectedCameraUuid > 0){
        filter.push({
          field_name: 'message_camera_id',
          operand: '=',
          value: this.selectedCameraUuid
        })
      }else{
        filter.push({
          field_name: 'message_id',
          operand: '>',
          value: 0
        })
      }
      return filter
    },

    //Загрузка списка сообщений
    loadMessageListItems() {
      store
          .dispatch('getMessageListItems', {
            filter: this.getMessageFilters(),
            sort: {
              name: this.messagePagination.sortBy,
              exp: this.messagePagination.descending ? 'ASC' : 'DESC',
            }
          })
          .then(
              (resolve) => {
                console.log('getMessageListItems resolve', resolve)
              },
              (reject) => {
                console.log('getMessageListItems reject', reject)
              }
          )
    },

    //Фильтры для камер
    getCamerasFilters(){
      let filter = []
      if(this.selectedCameraName.length > 0){
        filter.push({
          field_name: 'camera_name',
          operand: 'ilike',
          value: this.selectedCameraName
        })
      }
      return filter
    },

    //Загрузка списка камер
    loadCamerasListItems() {

      store
          .dispatch('getCamerasListItems', {
            filter: this.getCamerasFilters()
          })
          .then(
              (resolve) => {
                console.log('getCamerasListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCamerasListItems reject', reject)
              }
          )
    },

    //Запрос к таблице с камерами
    onRequestMessage(props: any) {
      console.log(props)
      console.log('onRequestMessage props', props)
      this.loadMessageListItems()
    },

    //Поиск
    search() {
      this.loadCamerasListItems()
      this.loadMessageListItems()
    },

    //Очистка поиска
    clearSelect() {
      this.selectedCameraUuid = null
      this.selectedCameraName = ''
      this.loadMessageListItems()
      this.loadCamerasListItems()
    },

  },

  created() {
    this.loadMessageListItems()
    this.loadCamerasListItems()
  },

})
</script>

<style lang="scss">

.message-list{
  max-width: 1440px;
  margin: 0 auto;
  .pre-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
    }
  }
  .q-table__top {
    padding: 20px !important;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    border-radius: 10px !important;
    .q-field{
      max-width: 215px;
      &__native{
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__control{
        border-radius: 5px;
      }
    }
    .q-field__native {
      min-width: 150px;
    }
    .q-field__inner {
      margin: 0 15px 0 0;
    }
    button{
      margin: 0 0 0 auto;
      height: 50px;
      width: 170px;
      border-radius: 10px;
      background: #3742fa !important;
      color: #fff;
      font-weight: 600;
      &:last-child{
        margin: 0 0 0 20px;
      }
    }
  }
  .q-table__middle{
    margin: 35px 0 0 0;
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .q-td{
      vertical-align: middle;
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  .q-card{
    padding: 0 !important;
  }
  .filter {
    margin: 0 0 0 25px;
    padding: 9px 15px;
    font-size: 15px;
  }
  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal{
    height: 56px;

  }
  .q-field--dense .q-field__label{
    font-size: 16px !important;
    top: 18px !important;
  }
}


</style>
/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.4
// source: user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateUserRequest,
 *   !proto.proto.CreateUserResponse>}
 */
const methodDescriptor_UserService_CreateUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateUserRequest,
  proto.proto.CreateUserResponse,
  /**
   * @param {!proto.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser,
      callback);
};


/**
 * @param {!proto.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateUserRequest,
 *   !proto.proto.UpdateUserResponse>}
 */
const methodDescriptor_UserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateUserRequest,
  proto.proto.UpdateUserResponse,
  /**
   * @param {!proto.proto.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.proto.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteUserRequest,
 *   !proto.proto.DeleteUserResponse>}
 */
const methodDescriptor_UserService_DeleteUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteUserRequest,
  proto.proto.DeleteUserResponse,
  /**
   * @param {!proto.proto.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteUser,
      callback);
};


/**
 * @param {!proto.proto.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListUserRequest,
 *   !proto.proto.ListUserResponse>}
 */
const methodDescriptor_UserService_ListUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/ListUser',
  grpc.web.MethodType.UNARY,
  proto.proto.ListUserRequest,
  proto.proto.ListUserResponse,
  /**
   * @param {!proto.proto.ListUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.listUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/ListUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUser,
      callback);
};


/**
 * @param {!proto.proto.ListUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.listUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/ListUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListUserRequest,
 *   !proto.proto.ListUserResponse>}
 */
const methodDescriptor_UserService_ListUserMenu = new grpc.web.MethodDescriptor(
  '/proto.UserService/ListUserMenu',
  grpc.web.MethodType.UNARY,
  proto.proto.ListUserRequest,
  proto.proto.ListUserResponse,
  /**
   * @param {!proto.proto.ListUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.listUserMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/ListUserMenu',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUserMenu,
      callback);
};


/**
 * @param {!proto.proto.ListUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.listUserMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/ListUserMenu',
      request,
      metadata || {},
      methodDescriptor_UserService_ListUserMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListRolesRequest,
 *   !proto.proto.ListRolesResponse>}
 */
const methodDescriptor_UserService_ListRoles = new grpc.web.MethodDescriptor(
  '/proto.UserService/ListRoles',
  grpc.web.MethodType.UNARY,
  proto.proto.ListRolesRequest,
  proto.proto.ListRolesResponse,
  /**
   * @param {!proto.proto.ListRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListRolesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.listRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/ListRoles',
      request,
      metadata || {},
      methodDescriptor_UserService_ListRoles,
      callback);
};


/**
 * @param {!proto.proto.ListRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListRolesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.listRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/ListRoles',
      request,
      metadata || {},
      methodDescriptor_UserService_ListRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCompaniesOfUserRequest,
 *   !proto.proto.ListCompaniesOfUserResponse>}
 */
const methodDescriptor_UserService_ListCompanyOfUser = new grpc.web.MethodDescriptor(
  '/proto.UserService/ListCompanyOfUser',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCompaniesOfUserRequest,
  proto.proto.ListCompaniesOfUserResponse,
  /**
   * @param {!proto.proto.ListCompaniesOfUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCompaniesOfUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCompaniesOfUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListCompaniesOfUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCompaniesOfUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.UserServiceClient.prototype.listCompanyOfUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.UserService/ListCompanyOfUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ListCompanyOfUser,
      callback);
};


/**
 * @param {!proto.proto.ListCompaniesOfUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCompaniesOfUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.UserServicePromiseClient.prototype.listCompanyOfUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.UserService/ListCompanyOfUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ListCompanyOfUser);
};


module.exports = proto.proto;


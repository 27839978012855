/* eslint-disable */
// source: stat.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.BatchDetectionResponse', null, global);
goog.exportSymbol('proto.proto.CameraParamsRequest', null, global);
goog.exportSymbol('proto.proto.CameraParamsResponse', null, global);
goog.exportSymbol('proto.proto.CreateStatRequest', null, global);
goog.exportSymbol('proto.proto.CreateStatResponse', null, global);
goog.exportSymbol('proto.proto.CurrentStatRequest', null, global);
goog.exportSymbol('proto.proto.CurrentStatResponse', null, global);
goog.exportSymbol('proto.proto.DetectionBatchItem', null, global);
goog.exportSymbol('proto.proto.DetectionBatchParamsItem', null, global);
goog.exportSymbol('proto.proto.DetectionBatchRequest', null, global);
goog.exportSymbol('proto.proto.DetectionResponse', null, global);
goog.exportSymbol('proto.proto.DtParametersSet', null, global);
goog.exportSymbol('proto.proto.ItemStatRequest', null, global);
goog.exportSymbol('proto.proto.ItemStatResponse', null, global);
goog.exportSymbol('proto.proto.LatestCameraStatRequest', null, global);
goog.exportSymbol('proto.proto.LatestCameraStatResponse', null, global);
goog.exportSymbol('proto.proto.ListStatRequest', null, global);
goog.exportSymbol('proto.proto.ListStatResponse', null, global);
goog.exportSymbol('proto.proto.RenewSingleCamRequest', null, global);
goog.exportSymbol('proto.proto.RenewSingleCamResponse', null, global);
goog.exportSymbol('proto.proto.RenewStatRequest', null, global);
goog.exportSymbol('proto.proto.RenewStatResponse', null, global);
goog.exportSymbol('proto.proto.Stat', null, global);
goog.exportSymbol('proto.proto.StatDetection', null, global);
goog.exportSymbol('proto.proto.StatFilter', null, global);
goog.exportSymbol('proto.proto.StatFilterItem', null, global);
goog.exportSymbol('proto.proto.StatPagination', null, global);
goog.exportSymbol('proto.proto.StatSort', null, global);
goog.exportSymbol('proto.proto.UpdateCameraParamsResponse', null, global);
goog.exportSymbol('proto.proto.UpdateDetectParamsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Stat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Stat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Stat.displayName = 'proto.proto.Stat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DtParametersSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DtParametersSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DtParametersSet.displayName = 'proto.proto.DtParametersSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DetectionBatchParamsItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DetectionBatchParamsItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DetectionBatchParamsItem.displayName = 'proto.proto.DetectionBatchParamsItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DetectionBatchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.DetectionBatchRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.DetectionBatchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DetectionBatchRequest.displayName = 'proto.proto.DetectionBatchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DetectionBatchItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DetectionBatchItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DetectionBatchItem.displayName = 'proto.proto.DetectionBatchItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.BatchDetectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.BatchDetectionResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.BatchDetectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.BatchDetectionResponse.displayName = 'proto.proto.BatchDetectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatDetection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatDetection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatDetection.displayName = 'proto.proto.StatDetection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatFilterItem.displayName = 'proto.proto.StatFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.StatFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.StatFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatFilter.displayName = 'proto.proto.StatFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatSort.displayName = 'proto.proto.StatSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.StatPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.StatPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.StatPagination.displayName = 'proto.proto.StatPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemStatRequest.displayName = 'proto.proto.ItemStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemStatResponse.displayName = 'proto.proto.ItemStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LatestCameraStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.LatestCameraStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LatestCameraStatRequest.displayName = 'proto.proto.LatestCameraStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.LatestCameraStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.LatestCameraStatResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.LatestCameraStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.LatestCameraStatResponse.displayName = 'proto.proto.LatestCameraStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListStatRequest.displayName = 'proto.proto.ListStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListStatResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListStatResponse.displayName = 'proto.proto.ListStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateStatRequest.displayName = 'proto.proto.CreateStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateStatResponse.displayName = 'proto.proto.CreateStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RenewStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RenewStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RenewStatRequest.displayName = 'proto.proto.RenewStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RenewStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RenewStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RenewStatResponse.displayName = 'proto.proto.RenewStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RenewSingleCamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RenewSingleCamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RenewSingleCamRequest.displayName = 'proto.proto.RenewSingleCamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RenewSingleCamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RenewSingleCamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RenewSingleCamResponse.displayName = 'proto.proto.RenewSingleCamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CurrentStatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CurrentStatRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.CurrentStatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CurrentStatRequest.displayName = 'proto.proto.CurrentStatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateDetectParamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.UpdateDetectParamsRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.UpdateDetectParamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateDetectParamsRequest.displayName = 'proto.proto.UpdateDetectParamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateCameraParamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateCameraParamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateCameraParamsResponse.displayName = 'proto.proto.UpdateCameraParamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CurrentStatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CurrentStatResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.CurrentStatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CurrentStatResponse.displayName = 'proto.proto.CurrentStatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DetectionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DetectionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DetectionResponse.displayName = 'proto.proto.DetectionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraParamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CameraParamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraParamsRequest.displayName = 'proto.proto.CameraParamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraParamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CameraParamsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.CameraParamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraParamsResponse.displayName = 'proto.proto.CameraParamsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Stat.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Stat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Stat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Stat.toObject = function(includeInstance, msg) {
  var f, obj = {
    statId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statCameraId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statCameraName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statCameraPictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statZoneId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    statZoneName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statZoneSpot: jspb.Message.getFieldWithDefault(msg, 7, ""),
    statZoneIsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    statShotCreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    statOccupiedCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    statVacantCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    statAllowedCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    statDetectedCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    statDetectionId: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Stat}
 */
proto.proto.Stat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Stat;
  return proto.proto.Stat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Stat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Stat}
 */
proto.proto.Stat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatCameraId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatCameraName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatCameraPictureUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatZoneId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatZoneName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatZoneSpot(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatZoneIsAllowed(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatShotCreated(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatOccupiedCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatVacantCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatAllowedCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatDetectedCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatDetectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Stat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Stat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Stat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Stat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatCameraId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatCameraName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatCameraPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatZoneId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatZoneName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatZoneSpot();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatZoneIsAllowed();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getStatShotCreated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatOccupiedCount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatVacantCount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getStatAllowedCount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStatDetectedCount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getStatDetectionId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional int64 stat_id = 1;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 stat_camera_id = 2;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string stat_camera_name = 3;
 * @return {string}
 */
proto.proto.Stat.prototype.getStatCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string stat_camera_picture_url = 4;
 * @return {string}
 */
proto.proto.Stat.prototype.getStatCameraPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatCameraPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 stat_zone_id = 5;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string stat_zone_name = 6;
 * @return {string}
 */
proto.proto.Stat.prototype.getStatZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string stat_zone_spot = 7;
 * @return {string}
 */
proto.proto.Stat.prototype.getStatZoneSpot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatZoneSpot = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool stat_zone_is_allowed = 8;
 * @return {boolean}
 */
proto.proto.Stat.prototype.getStatZoneIsAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatZoneIsAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string stat_shot_created = 9;
 * @return {string}
 */
proto.proto.Stat.prototype.getStatShotCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatShotCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 stat_occupied_count = 10;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatOccupiedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatOccupiedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 stat_vacant_count = 11;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatVacantCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatVacantCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 stat_allowed_count = 12;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatAllowedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatAllowedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 stat_detected_count = 13;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatDetectedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatDetectedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 stat_detection_id = 14;
 * @return {number}
 */
proto.proto.Stat.prototype.getStatDetectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Stat} returns this
 */
proto.proto.Stat.prototype.setStatDetectionId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DtParametersSet.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DtParametersSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DtParametersSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DtParametersSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtParamsId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dtCameraId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dtMethod: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dtConfidence: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    dtSegment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dtGarbage: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    dtAxis: jspb.Message.getFieldWithDefault(msg, 7, 0),
    dtObjPadding: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dtSegmentAttitude: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DtParametersSet}
 */
proto.proto.DtParametersSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DtParametersSet;
  return proto.proto.DtParametersSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DtParametersSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DtParametersSet}
 */
proto.proto.DtParametersSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtParamsId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCameraId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtMethod(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDtConfidence(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtSegment(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDtGarbage(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtAxis(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtObjPadding(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtSegmentAttitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DtParametersSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DtParametersSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DtParametersSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DtParametersSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtParamsId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDtCameraId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDtMethod();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDtConfidence();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getDtSegment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDtGarbage();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getDtAxis();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getDtObjPadding();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getDtSegmentAttitude();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int64 dt_params_id = 1;
 * @return {number}
 */
proto.proto.DtParametersSet.prototype.getDtParamsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtParamsId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 dt_camera_id = 2;
 * @return {number}
 */
proto.proto.DtParametersSet.prototype.getDtCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string dt_method = 3;
 * @return {string}
 */
proto.proto.DtParametersSet.prototype.getDtMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional float dt_confidence = 4;
 * @return {number}
 */
proto.proto.DtParametersSet.prototype.getDtConfidence = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtConfidence = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string dt_segment = 5;
 * @return {string}
 */
proto.proto.DtParametersSet.prototype.getDtSegment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtSegment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float dt_garbage = 6;
 * @return {number}
 */
proto.proto.DtParametersSet.prototype.getDtGarbage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtGarbage = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 dt_axis = 7;
 * @return {number}
 */
proto.proto.DtParametersSet.prototype.getDtAxis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtAxis = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 dt_obj_padding = 8;
 * @return {number}
 */
proto.proto.DtParametersSet.prototype.getDtObjPadding = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtObjPadding = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string dt_segment_attitude = 9;
 * @return {string}
 */
proto.proto.DtParametersSet.prototype.getDtSegmentAttitude = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DtParametersSet} returns this
 */
proto.proto.DtParametersSet.prototype.setDtSegmentAttitude = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DetectionBatchParamsItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DetectionBatchParamsItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DetectionBatchParamsItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionBatchParamsItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    bdLocalImage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bdParams: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DetectionBatchParamsItem}
 */
proto.proto.DetectionBatchParamsItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DetectionBatchParamsItem;
  return proto.proto.DetectionBatchParamsItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DetectionBatchParamsItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DetectionBatchParamsItem}
 */
proto.proto.DetectionBatchParamsItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBdLocalImage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBdParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DetectionBatchParamsItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DetectionBatchParamsItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DetectionBatchParamsItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionBatchParamsItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBdLocalImage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBdParams();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string bd_local_image = 1;
 * @return {string}
 */
proto.proto.DetectionBatchParamsItem.prototype.getBdLocalImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionBatchParamsItem} returns this
 */
proto.proto.DetectionBatchParamsItem.prototype.setBdLocalImage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bd_params = 2;
 * @return {string}
 */
proto.proto.DetectionBatchParamsItem.prototype.getBdParams = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionBatchParamsItem} returns this
 */
proto.proto.DetectionBatchParamsItem.prototype.setBdParams = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.DetectionBatchRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DetectionBatchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DetectionBatchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DetectionBatchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionBatchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtBatchList: jspb.Message.toObjectList(msg.getDtBatchList(),
    proto.proto.DetectionBatchParamsItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DetectionBatchRequest}
 */
proto.proto.DetectionBatchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DetectionBatchRequest;
  return proto.proto.DetectionBatchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DetectionBatchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DetectionBatchRequest}
 */
proto.proto.DetectionBatchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DetectionBatchParamsItem;
      reader.readMessage(value,proto.proto.DetectionBatchParamsItem.deserializeBinaryFromReader);
      msg.addDtBatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DetectionBatchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DetectionBatchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DetectionBatchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionBatchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtBatchList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DetectionBatchParamsItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DetectionBatchParamsItem dt_batch = 1;
 * @return {!Array<!proto.proto.DetectionBatchParamsItem>}
 */
proto.proto.DetectionBatchRequest.prototype.getDtBatchList = function() {
  return /** @type{!Array<!proto.proto.DetectionBatchParamsItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DetectionBatchParamsItem, 1));
};


/**
 * @param {!Array<!proto.proto.DetectionBatchParamsItem>} value
 * @return {!proto.proto.DetectionBatchRequest} returns this
*/
proto.proto.DetectionBatchRequest.prototype.setDtBatchList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DetectionBatchParamsItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DetectionBatchParamsItem}
 */
proto.proto.DetectionBatchRequest.prototype.addDtBatch = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DetectionBatchParamsItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.DetectionBatchRequest} returns this
 */
proto.proto.DetectionBatchRequest.prototype.clearDtBatchList = function() {
  return this.setDtBatchList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DetectionBatchItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DetectionBatchItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DetectionBatchItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionBatchItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dtImageUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dtImageKey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DetectionBatchItem}
 */
proto.proto.DetectionBatchItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DetectionBatchItem;
  return proto.proto.DetectionBatchItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DetectionBatchItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DetectionBatchItem}
 */
proto.proto.DetectionBatchItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtImageUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtImageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DetectionBatchItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DetectionBatchItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DetectionBatchItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionBatchItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDtImageUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDtImageKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string dt_text = 1;
 * @return {string}
 */
proto.proto.DetectionBatchItem.prototype.getDtText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionBatchItem} returns this
 */
proto.proto.DetectionBatchItem.prototype.setDtText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dt_image_url = 2;
 * @return {string}
 */
proto.proto.DetectionBatchItem.prototype.getDtImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionBatchItem} returns this
 */
proto.proto.DetectionBatchItem.prototype.setDtImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dt_image_key = 3;
 * @return {string}
 */
proto.proto.DetectionBatchItem.prototype.getDtImageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionBatchItem} returns this
 */
proto.proto.DetectionBatchItem.prototype.setDtImageKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.BatchDetectionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.BatchDetectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.BatchDetectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.BatchDetectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchDetectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionBatchItemsList: jspb.Message.toObjectList(msg.getDetectionBatchItemsList(),
    proto.proto.DetectionBatchItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.BatchDetectionResponse}
 */
proto.proto.BatchDetectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.BatchDetectionResponse;
  return proto.proto.BatchDetectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.BatchDetectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.BatchDetectionResponse}
 */
proto.proto.BatchDetectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DetectionBatchItem;
      reader.readMessage(value,proto.proto.DetectionBatchItem.deserializeBinaryFromReader);
      msg.addDetectionBatchItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.BatchDetectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.BatchDetectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.BatchDetectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.BatchDetectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionBatchItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DetectionBatchItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DetectionBatchItem detection_batch_items = 1;
 * @return {!Array<!proto.proto.DetectionBatchItem>}
 */
proto.proto.BatchDetectionResponse.prototype.getDetectionBatchItemsList = function() {
  return /** @type{!Array<!proto.proto.DetectionBatchItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DetectionBatchItem, 1));
};


/**
 * @param {!Array<!proto.proto.DetectionBatchItem>} value
 * @return {!proto.proto.BatchDetectionResponse} returns this
*/
proto.proto.BatchDetectionResponse.prototype.setDetectionBatchItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DetectionBatchItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DetectionBatchItem}
 */
proto.proto.BatchDetectionResponse.prototype.addDetectionBatchItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DetectionBatchItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.BatchDetectionResponse} returns this
 */
proto.proto.BatchDetectionResponse.prototype.clearDetectionBatchItemsList = function() {
  return this.setDetectionBatchItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatDetection.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatDetection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatDetection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatDetection.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    detectionObjects: jspb.Message.getFieldWithDefault(msg, 2, ""),
    detectionObjectsFull: jspb.Message.getFieldWithDefault(msg, 3, ""),
    detectionCreated: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatDetection}
 */
proto.proto.StatDetection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatDetection;
  return proto.proto.StatDetection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatDetection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatDetection}
 */
proto.proto.StatDetection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDetectionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectionObjects(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectionObjectsFull(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectionCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatDetection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatDetection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatDetection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatDetection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDetectionObjects();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDetectionObjectsFull();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDetectionCreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 detection_id = 1;
 * @return {number}
 */
proto.proto.StatDetection.prototype.getDetectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatDetection} returns this
 */
proto.proto.StatDetection.prototype.setDetectionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string detection_objects = 2;
 * @return {string}
 */
proto.proto.StatDetection.prototype.getDetectionObjects = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatDetection} returns this
 */
proto.proto.StatDetection.prototype.setDetectionObjects = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string detection_objects_full = 3;
 * @return {string}
 */
proto.proto.StatDetection.prototype.getDetectionObjectsFull = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatDetection} returns this
 */
proto.proto.StatDetection.prototype.setDetectionObjectsFull = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string detection_created = 4;
 * @return {string}
 */
proto.proto.StatDetection.prototype.getDetectionCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatDetection} returns this
 */
proto.proto.StatDetection.prototype.setDetectionCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.StatFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatFilterItem}
 */
proto.proto.StatFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatFilterItem;
  return proto.proto.StatFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatFilterItem}
 */
proto.proto.StatFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.StatFilter;
      reader.readMessage(value,proto.proto.StatFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.StatFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.StatFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatFilterItem} returns this
 */
proto.proto.StatFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.StatFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatFilterItem} returns this
 */
proto.proto.StatFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.StatFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatFilterItem} returns this
 */
proto.proto.StatFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional StatFilter or = 4;
 * @return {?proto.proto.StatFilter}
 */
proto.proto.StatFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.StatFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.StatFilter, 4));
};


/**
 * @param {?proto.proto.StatFilter|undefined} value
 * @return {!proto.proto.StatFilterItem} returns this
*/
proto.proto.StatFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.StatFilterItem} returns this
 */
proto.proto.StatFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.StatFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.StatFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.StatFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatFilter}
 */
proto.proto.StatFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatFilter;
  return proto.proto.StatFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatFilter}
 */
proto.proto.StatFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StatFilterItem;
      reader.readMessage(value,proto.proto.StatFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.StatFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatFilterItem items = 1;
 * @return {!Array<!proto.proto.StatFilterItem>}
 */
proto.proto.StatFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.StatFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.StatFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.StatFilterItem>} value
 * @return {!proto.proto.StatFilter} returns this
*/
proto.proto.StatFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.StatFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.StatFilterItem}
 */
proto.proto.StatFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.StatFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.StatFilter} returns this
 */
proto.proto.StatFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatSort}
 */
proto.proto.StatSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatSort;
  return proto.proto.StatSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatSort}
 */
proto.proto.StatSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.StatSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatSort} returns this
 */
proto.proto.StatSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.StatSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.StatSort} returns this
 */
proto.proto.StatSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.StatPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.StatPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.StatPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.StatPagination}
 */
proto.proto.StatPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.StatPagination;
  return proto.proto.StatPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.StatPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.StatPagination}
 */
proto.proto.StatPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.StatPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.StatPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.StatPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.StatPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.StatPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatPagination} returns this
 */
proto.proto.StatPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.StatPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatPagination} returns this
 */
proto.proto.StatPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.StatPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatPagination} returns this
 */
proto.proto.StatPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.StatPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.StatPagination} returns this
 */
proto.proto.StatPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemStatRequest}
 */
proto.proto.ItemStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemStatRequest;
  return proto.proto.ItemStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemStatRequest}
 */
proto.proto.ItemStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 stat_id = 1;
 * @return {number}
 */
proto.proto.ItemStatRequest.prototype.getStatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ItemStatRequest} returns this
 */
proto.proto.ItemStatRequest.prototype.setStatId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.proto.Stat.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemStatResponse}
 */
proto.proto.ItemStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemStatResponse;
  return proto.proto.ItemStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemStatResponse}
 */
proto.proto.ItemStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Stat;
      reader.readMessage(value,proto.proto.Stat.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Stat.serializeBinaryToWriter
    );
  }
};


/**
 * optional Stat item = 1;
 * @return {?proto.proto.Stat}
 */
proto.proto.ItemStatResponse.prototype.getItem = function() {
  return /** @type{?proto.proto.Stat} */ (
    jspb.Message.getWrapperField(this, proto.proto.Stat, 1));
};


/**
 * @param {?proto.proto.Stat|undefined} value
 * @return {!proto.proto.ItemStatResponse} returns this
*/
proto.proto.ItemStatResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ItemStatResponse} returns this
 */
proto.proto.ItemStatResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ItemStatResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LatestCameraStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LatestCameraStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LatestCameraStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LatestCameraStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LatestCameraStatRequest}
 */
proto.proto.LatestCameraStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LatestCameraStatRequest;
  return proto.proto.LatestCameraStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LatestCameraStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LatestCameraStatRequest}
 */
proto.proto.LatestCameraStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LatestCameraStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LatestCameraStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LatestCameraStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LatestCameraStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.LatestCameraStatRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.LatestCameraStatRequest} returns this
 */
proto.proto.LatestCameraStatRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.LatestCameraStatResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.LatestCameraStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.LatestCameraStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.LatestCameraStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LatestCameraStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.Stat.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.LatestCameraStatRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.LatestCameraStatResponse}
 */
proto.proto.LatestCameraStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.LatestCameraStatResponse;
  return proto.proto.LatestCameraStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.LatestCameraStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.LatestCameraStatResponse}
 */
proto.proto.LatestCameraStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Stat;
      reader.readMessage(value,proto.proto.Stat.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.LatestCameraStatRequest;
      reader.readMessage(value,proto.proto.LatestCameraStatRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.LatestCameraStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.LatestCameraStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.LatestCameraStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.LatestCameraStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Stat.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.LatestCameraStatRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Stat items = 1;
 * @return {!Array<!proto.proto.Stat>}
 */
proto.proto.LatestCameraStatResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.Stat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Stat, 1));
};


/**
 * @param {!Array<!proto.proto.Stat>} value
 * @return {!proto.proto.LatestCameraStatResponse} returns this
*/
proto.proto.LatestCameraStatResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Stat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Stat}
 */
proto.proto.LatestCameraStatResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Stat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.LatestCameraStatResponse} returns this
 */
proto.proto.LatestCameraStatResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional LatestCameraStatRequest params = 2;
 * @return {?proto.proto.LatestCameraStatRequest}
 */
proto.proto.LatestCameraStatResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.LatestCameraStatRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.LatestCameraStatRequest, 2));
};


/**
 * @param {?proto.proto.LatestCameraStatRequest|undefined} value
 * @return {!proto.proto.LatestCameraStatResponse} returns this
*/
proto.proto.LatestCameraStatResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.LatestCameraStatResponse} returns this
 */
proto.proto.LatestCameraStatResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.LatestCameraStatResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.StatFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.StatSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.StatPagination.toObject(includeInstance, f),
    mycompanyidlist: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inmycompanyonly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListStatRequest}
 */
proto.proto.ListStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListStatRequest;
  return proto.proto.ListStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListStatRequest}
 */
proto.proto.ListStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.StatFilter;
      reader.readMessage(value,proto.proto.StatFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.StatSort;
      reader.readMessage(value,proto.proto.StatSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.StatPagination;
      reader.readMessage(value,proto.proto.StatPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMycompanyidlist(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInmycompanyonly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.StatFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.StatSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.StatPagination.serializeBinaryToWriter
    );
  }
  f = message.getMycompanyidlist();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInmycompanyonly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional StatFilter filter = 1;
 * @return {?proto.proto.StatFilter}
 */
proto.proto.ListStatRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.StatFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.StatFilter, 1));
};


/**
 * @param {?proto.proto.StatFilter|undefined} value
 * @return {!proto.proto.ListStatRequest} returns this
*/
proto.proto.ListStatRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStatRequest} returns this
 */
proto.proto.ListStatRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStatRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatSort sort = 2;
 * @return {?proto.proto.StatSort}
 */
proto.proto.ListStatRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.StatSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.StatSort, 2));
};


/**
 * @param {?proto.proto.StatSort|undefined} value
 * @return {!proto.proto.ListStatRequest} returns this
*/
proto.proto.ListStatRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStatRequest} returns this
 */
proto.proto.ListStatRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStatRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional StatPagination pagination = 3;
 * @return {?proto.proto.StatPagination}
 */
proto.proto.ListStatRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.StatPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.StatPagination, 3));
};


/**
 * @param {?proto.proto.StatPagination|undefined} value
 * @return {!proto.proto.ListStatRequest} returns this
*/
proto.proto.ListStatRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStatRequest} returns this
 */
proto.proto.ListStatRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStatRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string myCompanyIdList = 4;
 * @return {string}
 */
proto.proto.ListStatRequest.prototype.getMycompanyidlist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListStatRequest} returns this
 */
proto.proto.ListStatRequest.prototype.setMycompanyidlist = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool inMyCompanyOnly = 5;
 * @return {boolean}
 */
proto.proto.ListStatRequest.prototype.getInmycompanyonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListStatRequest} returns this
 */
proto.proto.ListStatRequest.prototype.setInmycompanyonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListStatResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.Stat.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListStatRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListStatResponse}
 */
proto.proto.ListStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListStatResponse;
  return proto.proto.ListStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListStatResponse}
 */
proto.proto.ListStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Stat;
      reader.readMessage(value,proto.proto.Stat.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListStatRequest;
      reader.readMessage(value,proto.proto.ListStatRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Stat.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListStatRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Stat items = 1;
 * @return {!Array<!proto.proto.Stat>}
 */
proto.proto.ListStatResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.Stat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Stat, 1));
};


/**
 * @param {!Array<!proto.proto.Stat>} value
 * @return {!proto.proto.ListStatResponse} returns this
*/
proto.proto.ListStatResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Stat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Stat}
 */
proto.proto.ListStatResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Stat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListStatResponse} returns this
 */
proto.proto.ListStatResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListStatRequest params = 2;
 * @return {?proto.proto.ListStatRequest}
 */
proto.proto.ListStatResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListStatRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListStatRequest, 2));
};


/**
 * @param {?proto.proto.ListStatRequest|undefined} value
 * @return {!proto.proto.ListStatResponse} returns this
*/
proto.proto.ListStatResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListStatResponse} returns this
 */
proto.proto.ListStatResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListStatResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statCameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statZoneId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statOccupiedCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statVacantCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    statAllowedCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    statDetectedCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    statDetectionId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateStatRequest}
 */
proto.proto.CreateStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateStatRequest;
  return proto.proto.CreateStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateStatRequest}
 */
proto.proto.CreateStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatCameraId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatZoneId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatOccupiedCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatVacantCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatAllowedCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatDetectedCount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatDetectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatZoneId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatOccupiedCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatVacantCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getStatAllowedCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatDetectedCount();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getStatDetectionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 stat_camera_id = 1;
 * @return {number}
 */
proto.proto.CreateStatRequest.prototype.getStatCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 stat_zone_id = 2;
 * @return {number}
 */
proto.proto.CreateStatRequest.prototype.getStatZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 stat_occupied_count = 3;
 * @return {number}
 */
proto.proto.CreateStatRequest.prototype.getStatOccupiedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatOccupiedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 stat_vacant_count = 4;
 * @return {number}
 */
proto.proto.CreateStatRequest.prototype.getStatVacantCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatVacantCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 stat_allowed_count = 5;
 * @return {number}
 */
proto.proto.CreateStatRequest.prototype.getStatAllowedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatAllowedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 stat_detected_count = 6;
 * @return {number}
 */
proto.proto.CreateStatRequest.prototype.getStatDetectedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatDetectedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string stat_detection_id = 7;
 * @return {string}
 */
proto.proto.CreateStatRequest.prototype.getStatDetectionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateStatRequest} returns this
 */
proto.proto.CreateStatRequest.prototype.setStatDetectionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateStatResponse}
 */
proto.proto.CreateStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateStatResponse;
  return proto.proto.CreateStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateStatResponse}
 */
proto.proto.CreateStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 stat_id = 1;
 * @return {number}
 */
proto.proto.CreateStatResponse.prototype.getStatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateStatResponse} returns this
 */
proto.proto.CreateStatResponse.prototype.setStatId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RenewStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RenewStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RenewStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statRenewToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RenewStatRequest}
 */
proto.proto.RenewStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RenewStatRequest;
  return proto.proto.RenewStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RenewStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RenewStatRequest}
 */
proto.proto.RenewStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatRenewToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RenewStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RenewStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RenewStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatRenewToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stat_renew_token = 1;
 * @return {string}
 */
proto.proto.RenewStatRequest.prototype.getStatRenewToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RenewStatRequest} returns this
 */
proto.proto.RenewStatRequest.prototype.setStatRenewToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RenewStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RenewStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RenewStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statRenewSuccessFlag: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    statRenewRes: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RenewStatResponse}
 */
proto.proto.RenewStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RenewStatResponse;
  return proto.proto.RenewStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RenewStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RenewStatResponse}
 */
proto.proto.RenewStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatRenewSuccessFlag(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatRenewRes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RenewStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RenewStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RenewStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatRenewSuccessFlag();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStatRenewRes();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool stat_renew_success_flag = 1;
 * @return {boolean}
 */
proto.proto.RenewStatResponse.prototype.getStatRenewSuccessFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.RenewStatResponse} returns this
 */
proto.proto.RenewStatResponse.prototype.setStatRenewSuccessFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string stat_renew_res = 2;
 * @return {string}
 */
proto.proto.RenewStatResponse.prototype.getStatRenewRes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RenewStatResponse} returns this
 */
proto.proto.RenewStatResponse.prototype.setStatRenewRes = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RenewSingleCamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RenewSingleCamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RenewSingleCamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewSingleCamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    shot: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RenewSingleCamRequest}
 */
proto.proto.RenewSingleCamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RenewSingleCamRequest;
  return proto.proto.RenewSingleCamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RenewSingleCamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RenewSingleCamRequest}
 */
proto.proto.RenewSingleCamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RenewSingleCamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RenewSingleCamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RenewSingleCamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewSingleCamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getShot();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.RenewSingleCamRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RenewSingleCamRequest} returns this
 */
proto.proto.RenewSingleCamRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string shot = 2;
 * @return {string}
 */
proto.proto.RenewSingleCamRequest.prototype.getShot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RenewSingleCamRequest} returns this
 */
proto.proto.RenewSingleCamRequest.prototype.setShot = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RenewSingleCamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RenewSingleCamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RenewSingleCamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewSingleCamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statRenewSuccessFlag: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RenewSingleCamResponse}
 */
proto.proto.RenewSingleCamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RenewSingleCamResponse;
  return proto.proto.RenewSingleCamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RenewSingleCamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RenewSingleCamResponse}
 */
proto.proto.RenewSingleCamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatRenewSuccessFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RenewSingleCamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RenewSingleCamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RenewSingleCamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RenewSingleCamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatRenewSuccessFlag();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool stat_renew_success_flag = 1;
 * @return {boolean}
 */
proto.proto.RenewSingleCamResponse.prototype.getStatRenewSuccessFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.RenewSingleCamResponse} returns this
 */
proto.proto.RenewSingleCamResponse.prototype.setStatRenewSuccessFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CurrentStatRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CurrentStatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CurrentStatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CurrentStatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CurrentStatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    localImage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    paramsSetList: jspb.Message.toObjectList(msg.getParamsSetList(),
    proto.proto.DtParametersSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CurrentStatRequest}
 */
proto.proto.CurrentStatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CurrentStatRequest;
  return proto.proto.CurrentStatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CurrentStatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CurrentStatRequest}
 */
proto.proto.CurrentStatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalImage(value);
      break;
    case 3:
      var value = new proto.proto.DtParametersSet;
      reader.readMessage(value,proto.proto.DtParametersSet.deserializeBinaryFromReader);
      msg.addParamsSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CurrentStatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CurrentStatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CurrentStatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CurrentStatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLocalImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParamsSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.proto.DtParametersSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.CurrentStatRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CurrentStatRequest} returns this
 */
proto.proto.CurrentStatRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string local_image = 2;
 * @return {string}
 */
proto.proto.CurrentStatRequest.prototype.getLocalImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CurrentStatRequest} returns this
 */
proto.proto.CurrentStatRequest.prototype.setLocalImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DtParametersSet params_set = 3;
 * @return {!Array<!proto.proto.DtParametersSet>}
 */
proto.proto.CurrentStatRequest.prototype.getParamsSetList = function() {
  return /** @type{!Array<!proto.proto.DtParametersSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DtParametersSet, 3));
};


/**
 * @param {!Array<!proto.proto.DtParametersSet>} value
 * @return {!proto.proto.CurrentStatRequest} returns this
*/
proto.proto.CurrentStatRequest.prototype.setParamsSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.proto.DtParametersSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DtParametersSet}
 */
proto.proto.CurrentStatRequest.prototype.addParamsSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.proto.DtParametersSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CurrentStatRequest} returns this
 */
proto.proto.CurrentStatRequest.prototype.clearParamsSetList = function() {
  return this.setParamsSetList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.UpdateDetectParamsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateDetectParamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateDetectParamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateDetectParamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateDetectParamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtCameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    dtParamsSetList: jspb.Message.toObjectList(msg.getDtParamsSetList(),
    proto.proto.DtParametersSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateDetectParamsRequest}
 */
proto.proto.UpdateDetectParamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateDetectParamsRequest;
  return proto.proto.UpdateDetectParamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateDetectParamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateDetectParamsRequest}
 */
proto.proto.UpdateDetectParamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDtCameraId(value);
      break;
    case 2:
      var value = new proto.proto.DtParametersSet;
      reader.readMessage(value,proto.proto.DtParametersSet.deserializeBinaryFromReader);
      msg.addDtParamsSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateDetectParamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateDetectParamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateDetectParamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateDetectParamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDtParamsSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.DtParametersSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 dt_camera_id = 1;
 * @return {number}
 */
proto.proto.UpdateDetectParamsRequest.prototype.getDtCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateDetectParamsRequest} returns this
 */
proto.proto.UpdateDetectParamsRequest.prototype.setDtCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated DtParametersSet dt_params_set = 2;
 * @return {!Array<!proto.proto.DtParametersSet>}
 */
proto.proto.UpdateDetectParamsRequest.prototype.getDtParamsSetList = function() {
  return /** @type{!Array<!proto.proto.DtParametersSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DtParametersSet, 2));
};


/**
 * @param {!Array<!proto.proto.DtParametersSet>} value
 * @return {!proto.proto.UpdateDetectParamsRequest} returns this
*/
proto.proto.UpdateDetectParamsRequest.prototype.setDtParamsSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.DtParametersSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DtParametersSet}
 */
proto.proto.UpdateDetectParamsRequest.prototype.addDtParamsSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.DtParametersSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.UpdateDetectParamsRequest} returns this
 */
proto.proto.UpdateDetectParamsRequest.prototype.clearDtParamsSetList = function() {
  return this.setDtParamsSetList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateCameraParamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateCameraParamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateCameraParamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCameraParamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateCameraParamsResponse}
 */
proto.proto.UpdateCameraParamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateCameraParamsResponse;
  return proto.proto.UpdateCameraParamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateCameraParamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateCameraParamsResponse}
 */
proto.proto.UpdateCameraParamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateCameraParamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateCameraParamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateCameraParamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCameraParamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.proto.UpdateCameraParamsResponse.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.UpdateCameraParamsResponse} returns this
 */
proto.proto.UpdateCameraParamsResponse.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CurrentStatResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CurrentStatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CurrentStatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CurrentStatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CurrentStatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statList: jspb.Message.toObjectList(msg.getStatList(),
    proto.proto.Stat.toObject, includeInstance),
    detection: (f = msg.getDetection()) && proto.proto.StatDetection.toObject(includeInstance, f),
    shotImageKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shotImageUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CurrentStatResponse}
 */
proto.proto.CurrentStatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CurrentStatResponse;
  return proto.proto.CurrentStatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CurrentStatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CurrentStatResponse}
 */
proto.proto.CurrentStatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.proto.Stat;
      reader.readMessage(value,proto.proto.Stat.deserializeBinaryFromReader);
      msg.addStat(value);
      break;
    case 3:
      var value = new proto.proto.StatDetection;
      reader.readMessage(value,proto.proto.StatDetection.deserializeBinaryFromReader);
      msg.setDetection(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShotImageKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShotImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CurrentStatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CurrentStatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CurrentStatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CurrentStatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.proto.Stat.serializeBinaryToWriter
    );
  }
  f = message.getDetection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.StatDetection.serializeBinaryToWriter
    );
  }
  f = message.getShotImageKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShotImageUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated Stat stat = 2;
 * @return {!Array<!proto.proto.Stat>}
 */
proto.proto.CurrentStatResponse.prototype.getStatList = function() {
  return /** @type{!Array<!proto.proto.Stat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Stat, 2));
};


/**
 * @param {!Array<!proto.proto.Stat>} value
 * @return {!proto.proto.CurrentStatResponse} returns this
*/
proto.proto.CurrentStatResponse.prototype.setStatList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.proto.Stat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Stat}
 */
proto.proto.CurrentStatResponse.prototype.addStat = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.proto.Stat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CurrentStatResponse} returns this
 */
proto.proto.CurrentStatResponse.prototype.clearStatList = function() {
  return this.setStatList([]);
};


/**
 * optional StatDetection detection = 3;
 * @return {?proto.proto.StatDetection}
 */
proto.proto.CurrentStatResponse.prototype.getDetection = function() {
  return /** @type{?proto.proto.StatDetection} */ (
    jspb.Message.getWrapperField(this, proto.proto.StatDetection, 3));
};


/**
 * @param {?proto.proto.StatDetection|undefined} value
 * @return {!proto.proto.CurrentStatResponse} returns this
*/
proto.proto.CurrentStatResponse.prototype.setDetection = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CurrentStatResponse} returns this
 */
proto.proto.CurrentStatResponse.prototype.clearDetection = function() {
  return this.setDetection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CurrentStatResponse.prototype.hasDetection = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string shot_image_key = 4;
 * @return {string}
 */
proto.proto.CurrentStatResponse.prototype.getShotImageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CurrentStatResponse} returns this
 */
proto.proto.CurrentStatResponse.prototype.setShotImageKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string shot_image_url = 5;
 * @return {string}
 */
proto.proto.CurrentStatResponse.prototype.getShotImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CurrentStatResponse} returns this
 */
proto.proto.CurrentStatResponse.prototype.setShotImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DetectionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DetectionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DetectionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    detectionText: jspb.Message.getFieldWithDefault(msg, 1, ""),
    detectionImage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DetectionResponse}
 */
proto.proto.DetectionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DetectionResponse;
  return proto.proto.DetectionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DetectionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DetectionResponse}
 */
proto.proto.DetectionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectionText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectionImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DetectionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DetectionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DetectionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DetectionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDetectionText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDetectionImage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string detection_text = 1;
 * @return {string}
 */
proto.proto.DetectionResponse.prototype.getDetectionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionResponse} returns this
 */
proto.proto.DetectionResponse.prototype.setDetectionText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string detection_image = 2;
 * @return {string}
 */
proto.proto.DetectionResponse.prototype.getDetectionImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.DetectionResponse} returns this
 */
proto.proto.DetectionResponse.prototype.setDetectionImage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraParamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraParamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraParamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraParamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraParamsRequest}
 */
proto.proto.CameraParamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraParamsRequest;
  return proto.proto.CameraParamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraParamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraParamsRequest}
 */
proto.proto.CameraParamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraParamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraParamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraParamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraParamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.CameraParamsRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraParamsRequest} returns this
 */
proto.proto.CameraParamsRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CameraParamsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraParamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraParamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraParamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraParamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramsSetList: jspb.Message.toObjectList(msg.getParamsSetList(),
    proto.proto.DtParametersSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraParamsResponse}
 */
proto.proto.CameraParamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraParamsResponse;
  return proto.proto.CameraParamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraParamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraParamsResponse}
 */
proto.proto.CameraParamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.DtParametersSet;
      reader.readMessage(value,proto.proto.DtParametersSet.deserializeBinaryFromReader);
      msg.addParamsSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraParamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraParamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraParamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraParamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamsSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.DtParametersSet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DtParametersSet params_set = 1;
 * @return {!Array<!proto.proto.DtParametersSet>}
 */
proto.proto.CameraParamsResponse.prototype.getParamsSetList = function() {
  return /** @type{!Array<!proto.proto.DtParametersSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.DtParametersSet, 1));
};


/**
 * @param {!Array<!proto.proto.DtParametersSet>} value
 * @return {!proto.proto.CameraParamsResponse} returns this
*/
proto.proto.CameraParamsResponse.prototype.setParamsSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.DtParametersSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.DtParametersSet}
 */
proto.proto.CameraParamsResponse.prototype.addParamsSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.DtParametersSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CameraParamsResponse} returns this
 */
proto.proto.CameraParamsResponse.prototype.clearParamsSetList = function() {
  return this.setParamsSetList([]);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;

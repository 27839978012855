import StatisticList from '../views/statistic/StatisticList.vue'
import DetectionWatch from'../views/statistic/DetectionWatch.vue'
import ManualDetection from "../views/statistic/manualDetection.vue";


const routes = [
    {
        path: '/statistic',
        name: 'StatisticList',
        component: StatisticList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Статистика', to: { name: 'StatisticList' }}
            ]
        }
    },
    {
        path: '/statistic/:id',
        name: 'DetectionWatch',
        component: DetectionWatch,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Статистика', to: { name: 'StatisticList' }},
                { text: 'Камера' }
            ]
        }
    }
    ,
    {
        path: '/manual-detection',
        name: 'ManualDetection',
        component: ManualDetection,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Ручная детекция', to: { name: 'ManualDetection' }},
            ]
        }
    }

]

export default {
    routes
}
<template>
  <q-card
      style="width: 720px; max-width: 80vw; height: 280px; max-height: 80vh"
      class="q-pa-md"
  >
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5"
        >Добавить пользователя компании</q-item-label
        >
      </q-card-section>
    </q-item>

    <q-separator />

    <q-form
        @submit="saveCompanyUser"
        greedy
    >
      <q-card-section>
        <q-select
            v-model="selectedUser"
            :options="getUsersListItems"
            filled
            emit-value
            map-options
            label="Пользователи"
            error-message="К этой компании уже привязан выбранный пользователь"
            :error="this.isTied"
        ></q-select>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn v-if="selectedUser !== null" color="positive" type="submit"> Сохранить </q-btn>
        <q-btn v-else disable color="positive">Сохранить</q-btn>
        <q-btn color="primary" @click="closeCompanyUserLink"> Отмена </q-btn>
      </q-card-actions>
    </q-form>
    <q-separator />
  </q-card>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CompanyUserLink',

  props: {
    companyId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedUser: null as any,
      isTied: false
    }
  },

  computed: {

    //Формирование списка пользователей
    getUsersListItems() {
      const items = store.getters.getUsersListItems

      let rows: any = []
      if (items !== undefined) {
        for (let user of items) {
          rows.push({
            value: user.getUserId(),
            label: user.getFirstName() + ' ' + user.getLastName() + ' ' + user.getMiddleName()
          })
        }
      }

      return rows
    },

  },

  methods: {

    //Фильтр пользователей
    getUsersFilters(){
      return [
        {
          field_name: 'user_id',
          operand: '>',
          value: 0
        }
      ]
    },

    //Загрузка списка пользователей
    loadUsersListItems() {
      store
          .dispatch('getUsersListItems', {
            filter: this.getUsersFilters(),
          })
          .then(
              (resolve) => {
                console.log('getUsersListItems resolve', resolve)
              },
              (reject) => {
                console.log('getUsersListItems reject', reject)
              }
          )
    },

    //Сохранение пользователя в компании
    saveCompanyUser() {
      let data = {
        user_id: this.selectedUser,
        company_id: this.companyId,
        action: 'link'
      }
      store.dispatch('linkCompanyUser', data).then(
          (resolve) => {
            this.isTied = false
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            this.isTied = true
            console.log('reject', reject)
          }
      )
      return true
    },

    //Закрытие модалки связи компании и пользователя
    closeCompanyUserLink() {
      this.$emit('hideModal')
    },
  },

  created(){
    this.loadUsersListItems()
  }

})
</script>

<style lang="scss">



</style>

import {
    CameraServicePromiseClient
} from "../../proto/pb/camera_grpc_web_pb";
import {
    CreateCameraRequest,
    DeleteCameraRequest,
    ListCameraRequest,
    UpdateCameraRequest,
    Camera,
    CameraFilter,
    CameraFilterItem,
    CameraPagination,
    CameraSort,
    ItemCameraRequest,
    PictureUpdateRequest,
    CameraStat,
    CamerasTypesRequest
} from "../../proto/pb/camera_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class CamerasApi {
    items: Array<Camera> = [];
    client: CameraServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new CameraServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new CameraFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new CameraFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new CameraSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'camera_name';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new CameraPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getCamerasListItems(filter: any, sort: any, pagination: any){

        const request = new ListCameraRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listCamera(request, getAuthToken())
    }

    // Получение элемента
    getCamerasListItem(filter: any){

        const request = new ListCameraRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort({}));
        request.setPagination(this.createPagination({}));

        return this.client.listCamera(request, getAuthToken())
    }

    // Получение снимка
    getItemCamera(data: any){

        const request = new ItemCameraRequest();
        request.setCameraId(data.id);

        return this.client.itemCamera(request, getAuthToken())
    }

    // Обновить ссылку на изображение
    updatePicture(data: any){

        const request = new PictureUpdateRequest();
        request.setCameraId(data.id);
        request.setCameraPictureKey(data.key)
        request.setCameraPictureUrl(data.url)

        return this.client.itemCamera(request, getAuthToken())
    }

    // Создание элемента
    createCamerasItem(data: any){

        const request = new CreateCameraRequest();
        request.setCameraName(data.name)
        request.setCameraModelId(data.model_id)
        request.setCameraIp(data.ip)
        request.setCameraMac(data.mac)
        request.setCameraUrl(data.url)
        request.setCameraServiceId(data.service_id)
        request.setCameraCompanyId(data.company_id)
        request.setCameraLogin(data.login)
        request.setCameraPassword(data.password)
        request.setCameraSurveyPeriod(data.survey_period)
        request.setCameraLatitude(data.latitude)
        request.setCameraLongitude(data.longitude)
        request.setCameraStatPeriod(data.stat_period)
        request.setCameraType(data.type)

        return this.client.createCamera(request, getAuthToken())
    }

    // Редактировать элемент
    updateCamerasItem(data: any){

        const request = new UpdateCameraRequest();
        request.setCameraId(data.id)
        request.setCameraName(data.name)
        request.setCameraModelId(data.model_id)
        request.setCameraLogin(data.login)
        request.setCameraPassword(data.password)
        request.setCameraSurveyPeriod(data.survey_period)
        request.setCameraIp(data.ip)
        request.setCameraMac(data.mac)
        request.setCameraUrl(data.url)
        request.setCameraServiceId(data.service_id)
        request.setCameraCompanyId(data.company_id)
        request.setCameraLatitude(data.latitude)
        request.setCameraLongitude(data.longitude)
        request.setCameraSector(data.sector)
        request.setCameraStatPeriod(data.stat_period)
        request.setCameraType(data.type)

        return this.client.updateCamera(request, getAuthToken())
    }

    // Удаление элемента
    deleteCamerasItem(data: any){

        const request = new DeleteCameraRequest();
        request.setCameraId(data.id);

        return this.client.deleteCamera(request, getAuthToken())
    }

    cameraSync(data: any){
        const request = new ItemCameraRequest()
        request.setCameraId(data.id);

        return this.client.cameraStatAndImgSync(request, getAuthToken())
    }

    camerasTypes(){
        const request = new CamerasTypesRequest()
        
        return this.client.getCamerasTypes(request, getAuthToken())
    }
}

import ModelList from '../views/models/ModelList.vue'
import ModelAdd from '../views/models/ModelAdd.vue'
import ModelEdit from '../views/models/ModelEdit.vue'

const routes = [
    {
        path: '/models',
        name: 'ModelList',
        component: ModelList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Модели камер', to: { name: 'ModelList' }}
            ]
        }
    },
    {
        path: '/models/add',
        name: 'ModelAdd',
        component: ModelAdd,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Модели камер', to: { name: 'ModelList' }},
                { text: 'Добавление'}
            ]
        }
    },
    {
        path: '/models/edit/:id',
        name: 'ModelEdit',
        component: ModelEdit,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Модели камер', to: { name: 'ModelList' }},
                { text: 'Изменение'}
            ]
        }
    }
]

export default {
    routes
}
<template>

  <div class="statistics">
    <div class="statistics__body">
      <div class="statistics__body-screen">
        <img v-if="loader" src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="loader">
        <canvas width="900" height="500" ref="camera_canvas" id='canvas'></canvas>
      </div>
    </div>
    <q-table
        :columns="columns"
        :rows="getZoneListItems"
    >
      <template v-slot:body-cell-type="props">
        <q-td :props="props">
          <q-list>
            <q-item v-if="props.value">
              <q-item-section>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px"
                     viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#6BBE66" d="M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"/></g></svg>
              </q-item-section>
            </q-item>
            <q-item v-else>
              <q-item-section>
                <svg width="18px" height="18px" id="Layer_1" data-name="Layer 1"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                  <defs></defs>
                  <title>cancel</title>
                  <path style="fill:#c00706;fill-rule:evenodd;"
                        d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"/>
                  <path style="fill:#fff;fill-rule:evenodd;"
                        d="M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"/>
                </svg>
              </q-item-section>
            </q-item>
          </q-list>
        </q-td>
      </template>
      <template v-slot:no-data>
        <div class="col-12 text-center">Нет данных</div>
      </template>
    </q-table>
  </div>

</template>

<script lang="ts">

import {defineComponent} from "vue";
import store from "@/store";

export default defineComponent({
  name: 'CameraStatistics',

  data() {
    return {
      item: {
        id: 0,
        picture_url: ''
      },
      columns: [
        {
          name: 'type',
          label: 'Зона',
          field: 'type',
          align: 'left',
          style: 'width: 18px;padding: 0 0 0 3px;',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'zone',
          label: '',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'placeAllowed',
          label: 'Разрешено',
          field: 'placeAllowed',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'busy',
          label: 'Занято',
          field: 'busy',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'free',
          label: 'Свободно',
          field: 'free',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'date',
          label: 'Время',
          field: 'date',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }
      ] as any,
      zoneType: [false] as any,
      points: [[]] as any,
      loader: true,
      zonePagination: {
        sortBy: 'zone_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
    }
  },

  computed: {
    //Формирование списка зон для таблицы
    getZoneListItems() {
      const items = store.getters.getZoneListItems
      const statistic = store.getters.getLatestCameraStatListItems

      let points = []

      if (items !== undefined) {
        for (let zone of items) {
          if(statistic !== undefined){
            for(let stat of statistic){
              if(stat.getStatZoneId() === zone.getZoneId() || zone.getZoneIsAllowed() === false){
                points.push({
                  id: zone.getZoneId(),
                  type: zone.getZoneIsAllowed(),
                  name: zone.getZoneName(),
                  placeAllowed: zone.getZonePlacesAllowed(),
                  busy: stat.getStatOccupiedCount(),
                  free: Number(zone.getZonePlacesAllowed()) - Number(stat.getStatOccupiedCount()) <= 0 ? 0 : Number(zone.getZonePlacesAllowed()) - Number(stat.getStatOccupiedCount()),
                  points: zone.getZoneSpot().split('},{').length,
                  schema: zone.getZoneSchema(),
                  date: stat.getStatShotCreated()
                })
              }
            }
          }

        }
      }

      const sortedPoints = points.reduce((o: any, i: any) => {
        if (!o.find((v: any) => v.id == i.id)) {
          o.push(i);
        }
        return o;
      }, []);

      return sortedPoints
    },

    //Формирование списка зон для отрисовки на кадре
    getZonePoint(){
      const items = store.getters.getZoneListItems
      let points: any = []
      if (items !== undefined) {
        for (let zone of items) {
          points.push({
            points: zone.getZoneSpot(),
            type: zone.getZoneIsAllowed(),
          })
        }
      }
      return points
    },

  },

  methods: {

    //Загрузка данных камеры
    loadData() {
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCameraId(),
                  picture_url: resolve.getCameraPictureUrl()
                }
                if(this.item.picture_url === ''){
                  this.drawError()
                }
                else{
                  this.drawing()
                }
                this.loader = false
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрузка списка зон
    loadZoneListItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.zonePagination.page = 1
        this.zonePagination.rowsNumber = 0
      }
      store
          .dispatch('getZoneListItems', {
            filter: [
              {
                field_name: 'zone_camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
            sort: {
              name: this.zonePagination.sortBy,
              exp: this.zonePagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.zonePagination.page,
              limit: this.zonePagination.rowsPerPage,
              pages: 0,
              cnt: this.zonePagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getZoneListItems resolve', resolve)
                this.zonePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getZoneListItems reject', reject)
              }
          )
    },

    //Загрузка статистики по камере
    loadLatestCameraStatListItems(){
      store
          .dispatch('getLatestCameraStatListItems', {id: this.$route.params.id}).then(
          (resolve) => {
            console.log('getLatestCameraStatListItems', resolve)
          },
          (reject) => {
            console.log('getLatestCameraStatListItems', reject)
          }
      )
    },

    //Отображение изображения кадра с зонами
    drawing() {

      setTimeout(() => {
        let zoneList = this.getZonePoint
        let points = [] as any
        let regexX = /"x":\d{1,3}/gm;
        let regexY = /"y":\d{1,3}/gm;
        zoneList.forEach( ( zone: any ) => {
          points.push(zone.points.split('},{'))
          this.zoneType.push(zone.type)
        })
        points.forEach(( pointList: any ) => {
          let setSpot = [] as any
          pointList.forEach( (point: any) => {
            let spotObj = {
              x: 0,
              y: 0
            }
            let spotX = point.match(regexX)
            let spotY = point.match(regexY)
            if(spotX !== null){
              spotX.forEach( (spot : any) => {
                spotObj.x = Number(spot.replace('"x":',''))
              })
            }
            if(spotY !== null){
              spotY.forEach( (spot : any) => {
                spotObj.y = Number(spot.replace('"y":',''))
              })
            }
            setSpot.push(spotObj)
          })
          this.points.push(setSpot)

        })
      }, 100)

      setTimeout( () => {

        let canvas: any = document.getElementById("canvas");
        canvas.width = 900;
        canvas.height = 500;
        let ctx = canvas.getContext("2d");
        let cameraImage = new Image();
        cameraImage.src = this.item.picture_url;
        let zoneType: any = this.zoneType
        let points: any = this.points
        cameraImage.onload = function() {
          ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);

          points.forEach((point: any,idx: any) => {
            if(zoneType[idx]){
              ctx.fillStyle = 'rgba(0,232,39,0.3)';
              ctx.strokeStyle = 'rgba(0,232,39,0.3)';
            }else{
              ctx.fillStyle = 'rgba(232,0,0,0.3)';
              ctx.strokeStyle = 'rgba(232,0,0,0.3)';
            }
            let closed = true;
            ctx.beginPath();
            for (const p of point) {
              ctx.lineTo(p.x, p.y)
            }
            closed && ctx.closePath();
            ctx.stroke();
            closed && ctx.fill();


            for (const p of point) {
              ctx.beginPath();
              ctx.moveTo(p.x + 10, p.y);
              ctx.fill();
              ctx.stroke();
            }

          })
        }

      },150)

    },

    //Отображение изображения ошибки
    drawError(){
      let cameraImage = new Image();
      cameraImage.src = 'https://mediapure.ru/wp-content/uploads/2017/12/error-1021x580.jpg';
      let canvas: any = document.getElementById("canvas");
      canvas.width = 900;
      canvas.height = 500;
      let ctx = canvas.getContext("2d");
      cameraImage.onload = function() {
        ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
      }
    }

  },

  created() {
    this.loadLatestCameraStatListItems()
    this.loadZoneListItems(true)
    this.loadData()
  },
})

</script>

<style lang="scss">

  .statistics{
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto 40px auto;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    &__body{
      margin: 0 0 20px 0;
      &-screen{
        position: relative;
        img{
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 30px;
          height: 30px;
        }
      }
      canvas{
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
      }
    }
    p{
      padding: 0;
      font-size: 18px;
      margin: 0 0 25px 0;
    }
    .q-table__container{
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    }
  }

</style>
  <template>

  <q-card style="width: 1024px; max-width: 80vw" class="q-pa-md">
    <q-item>
      <q-card-section class="delete-title">
        <q-item-label class="text-h5">
          <p>Удаление камеры</p>
          <p>«{{ item.name }}»</p>
        </q-item-label>
      </q-card-section>
    </q-item>

    <q-separator />

    <div v-if="!isCamerasListBusy">
      <q-card-section class="delete-text">
        <p>Вы действительно хотите удалить камеру ?</p>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn color="negative" @click="actualDelete"> Удалить </q-btn>
        <q-btn color="primary" @click="closeDelete"> Отмена </q-btn>
      </q-card-actions>
    </div>
  </q-card>

</template>

<script lang="ts">

import store from "@/store";
import { defineComponent } from 'vue'



export default defineComponent({
  name: 'CameraDelete',

  props: {
    cameraId: {
      type: Number,
      default: 0,
    },
    cameraName:{
      type: String,
      default: '',
    }
  },

  data() {
    return {
      item: {
        id: this.cameraId,
        name: this.cameraName,
      },
    }
  },

  computed: {
    isCamerasListBusy() {
      return store.getters.getIsCamerasListBusy
    },
  },

  methods: {
    //Удаление камеры
    actualDelete() {
      store.dispatch('deleteCamerasListItem', { id: this.cameraId }).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
    },
    //Закрытие модалки
    closeDelete() {
      this.$emit('hideModal')
    },

  },


})
</script>

<style lang="scss">

.delete-title{
  padding: 20px 0 0 0 !important;
}

.delete-text{
  p{
    margin: 0;
  }
}

</style>
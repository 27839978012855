import {
    ZoneServicePromiseClient
} from "../../proto/pb/zone_grpc_web_pb";
import {
    CreateZoneRequest,
    DeleteZoneRequest,
    ListZoneRequest,
    UpdateZoneRequest,
    Zone,
    ZoneFilter,
    ZoneFilterItem,
    ZonePagination,
    ZoneSort,
} from "../../proto/pb/zone_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class ZoneApi {
    items: Array<Zone> = [];
    client: ZoneServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new ZoneServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new ZoneFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new ZoneFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new ZoneSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'zone_name';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new ZonePagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getZoneListItems(filter: any, sort: any, pagination: any){

        const request = new ListZoneRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listZone(request, getAuthToken())
    }

    // Получение элемента
    getZoneListItem(filter: any){

        const request = new ListZoneRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort({}));
        request.setPagination(this.createPagination({}));

        return this.client.listZone(request, getAuthToken())
    }

    // Создание элемента
    createZoneItem(data: any){

        const request = new CreateZoneRequest()
        request.setZoneName(data.name)
        request.setZoneCameraId(data.camera_id)
        request.setZoneIsAllowed(data.allowed)
        request.setZonePlacesAllowed(data.place_allowed)
        request.setZoneObjectPadding(data.object_padding)
        request.setZoneSpot(data.spot)
        request.setZonePicture(data.picture)
        request.setZoneSchema(data.schema)
        request.setZonePicture(data.picture)

        return this.client.createZone(request, getAuthToken())
    }

    // Редактировать элемент
    updateZoneItem(data: any){

        const request = new UpdateZoneRequest()
        request.setZoneCameraId(data.camera_id)
        request.setZoneId(data.id)
        request.setZoneSpot(data.spot)
        request.setZoneName(data.name)
        request.setZoneIsAllowed(data.allowed)
        request.setZonePlacesAllowed(data.place_allowed)
        request.setZoneObjectPadding(data.object_padding)
        request.setZoneSchema(data.schema)
        request.setZonePicture(data.picture)
        request.setZoneObjectMergeKoef(data.object_merge)
        request.setZoneAllowDetectVacant(data.detect_vacant)

        return this.client.updateZone(request, getAuthToken())
    }

    // Удаление элемента
    deleteZoneItem(data: any){

        const request = new DeleteZoneRequest();
        request.setZoneId(data.id);

        return this.client.deleteZone(request, getAuthToken())
    }
}

import UsersList from '../views/users/UsersList.vue'
import UserWatch from '../views/users/UserWatch.vue'

const routes = [
    {
        path: '/users/manage',
        name: 'UsersList',
        component: UsersList,
        meta: {
            breadCrumb: [
              { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
              { text: 'Системные пользователи' },
            ]
          }
    },
    {
        path: '/users/watch/:id',
        name: 'UserWatch',
        component: UserWatch,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Системные пользователи', to: { name: 'UsersList' } },
                { text: 'Пользователь' },
            ]
        }
    },
]

export default {
    routes
}
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AuthApi } from '../services/api/AuthApi';

// Создаем объект
const authApi = new AuthApi();

// Создаем состояние
class State {
    isAuthSuccess: boolean = false;
    accessToken: unknown;
    refreshToken: unknown
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    getIsAuthSuccess(state): unknown {
        return state.isAuthSuccess;
    },

    getAccessToken(state): unknown {
        return state.accessToken;
    },

    getRefreshToken(state): unknown {
        return state.refreshToken;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsAuthSuccess(state: State, payload: boolean) {
        state.isAuthSuccess = payload;
    },

    setAccessToken(state: State, payload: string) {
        state.accessToken = payload;
    },
    setRefreshToken(state: State, payload: string) {
        state.refreshToken = payload;
    },
}

// Создаем действия
const actions = <ActionTree<any, any>>{
    doUserAuth({ commit }, data: any) {

        return new Promise((resolve, reject) => {

        // Проверка логина и пароля
            authApi.checkUserCreds(data)
                .then(
                (response:any) => {
                    console.log(response);
                    if (response.accessToken) {
                        commit('setAccessToken', response.accessToken);
                        commit('setRefreshToken', response.refreshToken);
                        commit('setIsAuthSuccess', true);
                    } else {
                        commit('setAccessToken', '');
                        commit('setRefreshToken', '');
                        commit('setIsAuthSuccess', false);
                    }
                    resolve(response);
                },
                (error) => {
                    commit('setAccessToken', '');
                    commit('setRefreshToken', '');
                    commit('setIsAuthSuccess', false);
                    reject(error);
                }
            );
        });
    },

    removeToken({ commit }) {
        commit('setAccessToken', '');
        commit('setRefreshToken', '');
        commit('setIsAuthSuccess', false);
    },

    checkRefreshToken({ commit }, data: any){
        return new Promise((resolve, reject) => {
            // Создаем элемент
            authApi.checkRefreshToken(data)
                .then(
                    (response:any) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
};

// Создаем модуль
const AuthModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};
  
export default AuthModule;
  
<template>

  <div class="zone-add">
    <div class="zone-add__form">
      <q-card
          style="width: 100%;height: auto; display: flex"
          class="q-pa-md"
      >
        <div style="width: 35%" v-if="!isZoneListBusy">
          <q-form
            @submit="saveZone('save')"
            greedy
          >
            <q-card-section>
              <q-input
                  v-model="cameraName"
                  label="Камера"
                  filled
                  lazy-rules
                  :disable="true"
              />
            </q-card-section>
            <q-card-section>
              <q-input
                  v-model="item.name"
                  filled
                  label="Название"
                  :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
              ></q-input>
            </q-card-section>
            <q-card-section>
              <q-select
                  v-model="item.allowed"
                  :options="alloweds"
                  filled
                  emit-value
                  label="Тип"
                  :rules="[
              (val) => (val !== '') || 'Поле должно быть заполнено',
            ]"
              ></q-select>
            </q-card-section>
            <q-card-section>
              <q-input
                  v-model="item.place_allowed"
                  filled
                  label="Количество мест"
                  type="number"
                  :rules="[
              (val) => (val && val <= 20000) || 'Поле должно быть заполнено и максимальное значение не должно превыщать 20000',
            ]"
              />
            </q-card-section>
            <q-card-section>
              <q-input
                  v-model="item.object_padding"
                  filled
                  label="Отступ от объекта"
                  type="number"
              />
            </q-card-section>
            <q-card-section>
              <div class="text-body1">
                Коэффициент перекрытия объектов детекции: {{ item.object_merge }}
              </div>
              <q-slider
                  name="objectMerge"
                  v-model="item.object_merge"
                  switch-label-side
                  label
                  :min="0.1"
                  :max="1"
                  :step="0.1"
              ></q-slider>
            </q-card-section>
            <q-card-section>
              <q-checkbox 
                v-model="item.detect_vacant" 
                label="Функция поиска свободных мест" 
                />
            </q-card-section>
            <q-card-section>
              <q-input class="text-area"
                  v-model="item.spot"
                  label="Точки"
                  type="textarea"
                  filled
                  lazy-rules
                  readonly
                  :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
              />
            </q-card-section>
            <q-card-actions class="form-navigation">
              <q-btn color="positive" type="submit"> Сохранить </q-btn>
              <q-btn color="negative" @click="actualDelete">Удалить зону</q-btn>
              <q-btn color="negative" @click="closeZoneEdit"> Отмена </q-btn>
            </q-card-actions>
          </q-form>

        </div>
        <div class="zone-add__form__left">
          <div class="zone-add__form__left__camera">
            <canvas class="zone-camera" ref="camera_canvas" id='canvas'></canvas>
            <div class="zone-add__form__left__camera--navigation">
              <q-btn color="primary" id="clear">Очистить</q-btn>
            </div>
          </div>

          <div class="map-wrapper">
            <div class="map-container">
              <div :key="mapKey" id="map"></div>
            </div>
            <q-btn color="primary" @click="clearOverview" id="clearOverview">Очистить</q-btn>
          </div>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import store from "@/store";
/*import { uuid } from 'vue-uuid'*/
import { loadYmap } from 'vue-yandex-maps'


export default {

  name: "ZoneEdit",
  props: {
    zoneId: {
      type: Number,
      default: 0,
    },
  },

  data(){
    return{
      cameraId: '',
      cameraName: '',
      pictureUrl: '',
      pictureUrlBackup: '',
      item: {
        camera_id: 1,
        id: 0,
        name: '',
        spot: [],
        allowed: '',
        place_allowed: null,
        object_merge: 0,
        detect_vacant: false
      },
      alloweds: [
        {
          label: 'Разрешено',
          value: 'Разрешено',
          allowed: true
        },
        {
          label: 'Запрещено',
          value: 'Запрещено',
          allowed: false,
        }
      ],
      points: '',
      canvas: null,
      coordinates: [],
      zoneCoordinates: [],
      mapKey: 0
    }
  },

  computed: {

    isZoneListBusy() {
      return store.getters.getIsZoneListBusy
    },

  },

  methods: {

    checkCameraFrame(){
      const image = new Image();
      image.src = this.item.picture_url;
      image.onerror = () => {
        this.item.picture_url = this.item.picture_url_backup;
      };
      this.drawing()
    },

    //Закрыть изменение зоны
    closeZoneEdit(){
      this.$router.push('/cameras/watch/' + this.$route.params.id)
    },

    //Сохранить зону
    saveZone(type) {

      let data = {
        camera_id: this.$route.params.id,
        id: this.item.id,
        name: this.item.name,
        spot: type === 'clearSave' ? this.item.spot : '[' + this.item.spot.join() + ']',
        object_padding: this.item.object_padding,
        allowed: this.item.allowed === 'Разрешено',
        place_allowed: this.item.place_allowed,
        picture: '',
        schema: type === 'clearSave' ?'[[' + String([this.coordinates[0],this.coordinates[1]]) + ']]': JSON.stringify(this.zoneCoordinates),
        object_merge: this.item.object_merge,
        detect_vacant: this.item.detect_vacant
      }

      store.dispatch('updateZoneItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            if(type === 'save'){
              this.$router.push('/cameras/watch/' + this.$route.params.id)
            }
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
    },

    //Загрузка данных зоны
    loadData() {
      store
          .dispatch('getZoneListItem', {
            filter: [
              {
                field_name: 'zone_id',
                operand: '=',
                value: this.$route.params.selectedZoneId,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  camera_id: 0,
                  id: resolve.getZoneId(),
                  name: resolve.getZoneName(),
                  spot: resolve.getZoneSpot(),
                  object_padding: resolve.getZoneObjectPadding(),
                  allowed: resolve.getZoneIsAllowed(),
                  place_allowed: parseInt(resolve.getZonePlacesAllowed()),
                  object_merge: resolve.getZoneObjectMergeKoef().toFixed(1),
                  detect_vacant: resolve.getZoneAllowDetectVacant()
                }
                this.zoneCoordinates = JSON.parse(resolve.getZoneSchema())
                this.item.allowed ? this.item.allowed = 'Разрешено' : this.item.allowed = 'Запрещено'
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрузка данных камеры
    loadCameraData(){
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.cameraName = resolve.getCameraName()
                this.pictureUrl = resolve.getCameraPictureUrl()
                this.pictureUrlBackup = resolve.getCameraPictureUrlBackup()
                this.coordinates.push(resolve.getCameraLatitude())
                this.coordinates.push(resolve.getCameraLongitude())
                this.checkCameraFrame()
                this.map()
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Отрисовка кадра и рисование зон
    drawing() {

      let activePoint,
          cursor,
          dragging = false,
          canvas = document.getElementById("canvas"),
          ctx = canvas.getContext("2d"),
          cameraImage = new Image();

      cameraImage.src = this.pictureUrl;
      canvas.width = 900;
      canvas.height = 500;
      const mouse = {
        x: 0,
        y: 0,
        button: false,
        lx: 0,
        ly: 0,
        update: true
      };

      const TEXT_OFFSET = 5,
            TEXT_COLOR = "#000",
            TEXT_SIZE = 16,
            FONT = "arial",
            TEXT_ANGLE_OFFSET = 25,
            DEG = "°";

      function drawLineText(p1, p2, text, textOffset = TEXT_OFFSET, textColor = TEXT_COLOR, textSize = TEXT_SIZE, font = FONT) {
        let x = p1.x,
            y = p1.y;
        let nx = p2.x - x,
            ny = p2.y - y,
            len = (nx * nx + ny * ny) ** 0.5;
        nx /= len;
        ny /= len;
        ctx.font = textSize + "px " + font;
        ctx.textAlign = "center";
        ctx.fillStyle = textColor;
        if (nx < 0) {
          ctx.textBaseline = "top";
          x = p2.x;
          y = p2.y;
          nx = -nx;
          ny = -ny;
          textOffset = -textOffset;
        } else {
          ctx.textBaseline = "bottom"
        }
        len /= 2;
        ctx.setTransform(nx, ny, -ny, nx, x, y);
        ctx.fillText(text, len, -textOffset);
      }

      function drawAngleText(p1, p2, p3, textAngleOffset = TEXT_ANGLE_OFFSET, textColor = TEXT_COLOR, textSize = TEXT_SIZE, font = FONT) {
        let ang,
            x = p2.x,
            y = p2.y;
        let nx1 = p1.x - x,
            ny1 = p1.y - y,
            len1 = (nx1 * nx1 + ny1 * ny1) ** 0.5;
        let nx2 = p3.x - x,
            ny2 = p3.y - y,
            len2 = (nx2 * nx2 + ny2 * ny2) ** 0.5;
        nx1 /= len1;
        ny1 /= len1;
        nx2 /= len2;
        ny2 /= len2;
        const cross = nx1 * ny2 - ny1 * nx2;
        const dot = nx1 * nx2 + ny1 * ny2;
        if (dot < 0) {
          ang = cross < 0 ? -Math.PI - Math.asin(cross) : Math.PI - Math.asin(cross);
        } else {
          ang = Math.asin(cross);
        }
        const angDeg = Math.abs(ang * (180 / Math.PI)).toFixed(0) + DEG;
        ctx.font = textSize + "px " + font;
        ctx.fillStyle = textColor;
        ctx.textBaseline = "middle";
        const centerAngle = Math.atan2(ny1, nx1) + ang / 2;
        const nx = Math.cos(centerAngle);
        const ny = Math.sin(centerAngle);
        if (nx < 0) {
          ctx.textAlign = "right";
          ctx.setTransform(-nx, -ny, ny, -nx, x, y);
          textAngleOffset = -textAngleOffset;
        } else {
          ctx.textAlign = "left";
          ctx.setTransform(nx, ny, -ny, nx, x, y);
        }
        ctx.fillText(angDeg, textAngleOffset, 0);
      }

      requestAnimationFrame(update)
      function mouseEvents(e) {
        const bounds = canvas.getBoundingClientRect();
        mouse.x = e.pageX - bounds.left - scrollX;
        mouse.y = e.pageY - bounds.top - scrollY;
        mouse.button = e.type === "mousedown" ? true : e.type === "mouseup" ? false : mouse.button;
        mouse.update = true;
      }
      ["mousedown", "mouseup", "mousemove"].forEach(name => {
        canvas.addEventListener(name, mouseEvents)
      });


      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgba(29,14,253,0.3)';


      let currentSpot = [];

      setTimeout(() => {
        let setSpot = [];
        let itemSpot = this.item.spot;
        currentSpot = itemSpot.split('},{')
        let regexX = /"x":\d{1,3}/gm;
        let regexY = /"y":\d{1,3}/gm ;
        currentSpot.forEach( (spot) => {
          let spotObj = {
            x: 0,
            y: 0
          }
          let spotX = spot.match(regexX)
          if(spotX !== null){
            spotX.forEach( (spot) => {
              spotObj.x = Number(spot.replace('"x":',''))
            })
          }
          let spotY = spot.match(regexY)
          if(spotY !== null){
            spotY.forEach( (spot) => {
              spotObj.y = Number(spot.replace('"y":',''))
            })
          }

          setSpot.push(spotObj)
        })
        polygon.points = setSpot
        mouse.update = true
      }, 500)

      const point = (x, y) => ({x, y});
      const poly = () => ({
        points: [],

        closed: true,
        addPoint(p) {
          let points = this.points
          points.push(point(p.x, p.y))
        },

        removePoint(){
          let points = this.points
          const bounds = canvas.getBoundingClientRect();
          canvas.addEventListener('dblclick', (e) => {
            mouse.x = e.pageX - bounds.left - scrollX;
            mouse.y = e.pageY - bounds.top - scrollY;
            points.forEach( (point,idx) => {
                if( (Math.abs(mouse.x - point.x) <= 5) && (Math.abs(mouse.y - point.y) <= 5) ){
                points.splice(idx,1)
              }
            })
          })
        },

        draw() {
          ctx.drawImage(cameraImage,0 ,0 , canvas.width , canvas.height);
          ctx.lineWidth = 2;
          ctx.strokeStyle = 'rgba(29,14,253,0.3)';
          ctx.fillStyle = 'rgba(29,14,253,0.3)';
          ctx.beginPath();
          for (const p of this.points) {
            let point = p
            ctx.lineTo(point.x, point.y)
          }
          this.closed && ctx.closePath();
          ctx.stroke();
          this.closed && ctx.fill();

          ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
          ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
          for (const p of this.points) {
            let point = p
            ctx.beginPath();
            ctx.moveTo(point.x + 10, point.y);
            ctx.arc(point.x, point.y, 10, 0, Math.PI * 2);
            ctx.fill();
            ctx.stroke();
          }
          this.points.length > 1 && this.drawLengthText();
          this.points.length > 2 && this.drawAngleText();

        },

        drawLengthText() {
          const len = this.points.length;
          let p1,
              i = 0;
          p1 = this.points[i];
          while (i < len -(this.closed ? 0 : 1)) {
            const p2 = this.points[((i++) + 1) % len];
            const lineLength = ((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2) ** 0.5
            drawLineText(p1, p2, lineLength.toFixed(0) + "px");
            if (len < 3) { break }
            p1 = p2;
          }
          ctx.setTransform(1, 0, 0, 1, 0, 0);
        },

        drawAngleText() {
          const len = this.points.length;
          let p1, p2, i = this.closed ? 0 : 1;
          p1 = this.points[(i + len - 1) % len];
          p2 = this.points[i];
          while (i < len -(this.closed ? 0 : 1)) {
            const p3 = this.points[((i++) + 1) % len];
            drawAngleText(p1, p2, p3);
            p1 = p2;
            p2 = p3;
          }
          ctx.setTransform(1, 0, 0, 1, 0, 0);
        },

        closest(pos, dist = 8) {
          let i = 0,
              index = -1;
          dist *= dist;
          for (const p of this.points) {
            let point = p
            let x = pos.x - point.x;
            let y = pos.y - point.y;
            let d2 = x * x + y * y;
            if (d2 < dist) {
              dist = d2;
              index = i;
            }
            i++;
          }
          if (index > -1) { return this.points[index] }
        },

      });

      const polygon = poly();
      function drawCircle(pos, color = "black", size = 8) {
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.arc(pos.x, pos.y, size, 0, Math.PI * 2);
        ctx.stroke();
      }

      let selectedPoints = [];

      function update() {
        if (mouse.update) {
          cursor = "crosshair";
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          if (!dragging) {
            activePoint = polygon.closest(mouse)
          }
          if (activePoint === undefined && mouse.button) {
            polygon.addPoint(mouse);

            mouse.button = false;
          } else if (activePoint) {
            if (mouse.button) {
              if (dragging) {
                activePoint.x += mouse.x - mouse.lx;
                activePoint.y += mouse.y - mouse.ly;
              } else {
                if (!polygon.closed && polygon.points.length > 2 && activePoint === polygon.points[0]) {
                  polygon.closed = true;
                }
                dragging = true
              }
            } else {
              dragging = false
            }
          }
          polygon.draw();
          polygon.removePoint();
          if (activePoint) {
            drawCircle(activePoint);
            cursor = "move";
          }
          mouse.lx = mouse.x;
          mouse.ly = mouse.y;
          canvas.style.cursor = cursor;
          mouse.update = false;
          document.querySelector('#clear').addEventListener('click', () => {
            polygon.points.length = 0;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            mouse.update = true
          })
        }
        requestAnimationFrame(update)
        selectedPoints = polygon.points
      }

      const addPointsToInput = () =>{
        let currentPoints = selectedPoints
        this.item.spot = []
        currentPoints.forEach((point) => {
          let spot = this.item.spot
          spot.push(JSON.stringify(point))
        })
      }
      ["click", "dblclick"].forEach(name => {
        document.addEventListener(name, addPointsToInput)
      });

    },

    //Удаление зоны
    actualDelete() {
      store.dispatch('deleteZoneListItem', { id: this.item.id }).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$router.push('/cameras/watch/' + this.$route.params.id)
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
    },

    //Яндекс карты
    async map(){
      await loadYmap({apiKey: 'e98d34f8-157a-43e9-9dc5-46d8dd7e4f59'},{  debug: true });
      setTimeout(() => {
        let coordinates = this.coordinates
        this.zoneCoordinates = this.zoneCoordinates.length === 0 ? [] : this.zoneCoordinates
        const init = () => {
          let map = new window.ymaps.Map('map', {
            center: coordinates,
            zoom: 18
          },{
            restrictMapArea: true
          });

          let cameraMark = new window.ymaps.Placemark(map.getCenter(), {
            hintContent: 'Расположение камеры',
          }, {
            iconLayout: 'default#image',
            iconImageHref: require('@/assets/img/camera-icon.png'),
            iconImageSize: [60, 60],
            iconImageOffset: [-30, -30]
          })

          let zone = new window.ymaps.Polygon([this.zoneCoordinates], {}, {
            editorDrawingCursor: "crosshair",
            fillColor: '#1D0EFD4C',
            strokeColor: '#1D0EFD4C',
            strokeWidth: 3
          });

          map.geoObjects
              .add(cameraMark)
              .add(zone)
          map.controls.remove('default')
          let stateMonitor = new window.ymaps.Monitor(zone.editor.state);
          stateMonitor.add("drawing", function (newValue) {
            zone.options.set("strokeColor", newValue ? '#1D0EFD4C' : '#1D0EFD4C');
          });

          zone.editor.startDrawing();

          zone.events.add('geometrychange', () => {
            this.zoneCoordinates = zone.geometry._coordPath._coordinates[0]
          })

        }

        window.ymaps.ready(init)
      },50)
    },

    //Очистка кадра
    clearOverview(){
      this.zoneCoordinates = []
      this.saveZone('clearSave')
      setTimeout(() => {
        this.loadData()
        this.mapKey++
        this.map()
      },150)
    }

  },

  mounted() {
    this.loadCameraData()
    this.loadData()
  },


}
</script>


<style lang="scss">

.zone-add{
  &__form{
    .q-card{
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    }
    .form-navigation{
      display: flex;
      justify-content: space-between;
    }
    &__left{
      width: 65%;
      display: flex;
      flex-direction: column;
      &__camera{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: column;
        width: 100%;
        height: unset !important;
        .zone-camera{
          border-radius: 10px;
          box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
          display: block;
          height: 500px;
          width: 900px;
          margin: 16px 0 0 0;
        }
        &--navigation{
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin: 20px 0 0 0;
          button{
            display: flex;
            margin: 0;
            padding: 12px 24px !important;
            border-radius: 10px;
            &:first-child{
              background: #3742fa !important;
            }
          }
        }
      }
      .map-wrapper{
        display: flex;
        flex-direction: column;
        .map-container{
          box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
          border-radius: 10px;
          margin: 20px 0 0 auto;
          width: 900px;
          height: 500px;
          overflow: hidden;
          #map {
            width: 900px;
            height: 500px;
            padding: 0;
            .ymaps-2-1-79-copyrights-pane{
              display: none;
            }
          }
        }
        button{
          width: fit-content;
          display: flex;
          margin: 20px 0 0 auto;
          padding: 12px 24px !important;
          border-radius: 10px;
          background: #3742fa !important;
        }
      }

    }



    .text-area{
      pointer-events: none;
      textarea{
        height: 100px;
        overflow-x: hidden;
        overflow-y: scroll;
        resize: none;
        padding: 5px 0;
        pointer-events: auto;
        line-height: 150%;
      }
    }
  }
}

.q-pa-md{
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}

</style>
import MessageList from '../views/message/MessageList.vue'


const routes = [
    {
        path: '/message',
        name: 'MessageList',
        component: MessageList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Сообщения', to: { name: 'MessageList' }}
            ]
        }
    }
]

export default {
    routes
}
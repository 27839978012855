<template>
  <q-card
      style="width: 720px; max-width: 80vw; height: 280px; max-height: 80vh"
      class="q-pa-md"
  >
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5"
        >Добавить компанию пользователю</q-item-label
        >
      </q-card-section>
    </q-item>

    <q-separator />

      <q-form
          @submit="saveCompanyUser"
          greedy
      >
        <q-card-section>
          <q-select
              v-model="selectedCompany"
              :options="getCompanyListItems"
              filled
              emit-value
              map-options
              label="Компании"
              error-message="Этот пользователь уже привязан к данной компании"
              :error="this.isTied"
          ></q-select>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn v-if="selectedCompany !== null" color="positive" type="submit"> Сохранить </q-btn>
          <q-btn v-else disable color="positive">Сохранить</q-btn>
          <q-btn color="primary" @click="closeCompanyUserAdd"> Отмена </q-btn>
        </q-card-actions>
      </q-form>
      <q-separator />
  </q-card>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserCompanyAdd',

  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedCompany: null as any,
      isTied: false
    }
  },

  computed: {

    //Формирование списка компаний
    getCompanyListItems() {
      const items = store.getters.getCompanyListItems

      let rows: any = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            value: model.getCompanyId(),
            label: model.getCompanyName()
          })
        }
      }

      return rows
    },

  },

  methods: {

    //Фильтр компаний
    getCompanyFilters(){
      return [
        {
          field_name: 'company_id',
          operand: '>',
          value: 0
        }
      ]
    },

    //Загрузска списка компаний
    loadCompanyListItems() {
      store
          .dispatch('getCompanyListItems', {
            filter: this.getCompanyFilters(),
          })
          .then(
              (resolve) => {
                console.log('getCompanyListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCompanyListItems reject', reject)
              }
          )
    },

    //Сохранить пользователя в компании
    saveCompanyUser() {
      let data = {
        user_id: this.userId,
        company_id: this.selectedCompany,
        action: 'link'
      }
      store.dispatch('linkCompanyUser', data).then(
          (resolve) => {
            this.isTied = false
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            this.isTied = true
            console.log('reject', reject)
          }
      )
      return true
    },

    //Закрыть добавление пользователя в компанию
    closeCompanyUserAdd() {
      this.$emit('hideModal')
    },
  },

  created(){
    this.loadCompanyListItems()
  }

})
</script>

<style lang="scss">



</style>

import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { ZoneApi } from '../services/api/ZoneApi';
import Loading from 'quasar/src/plugins/Loading.js';

// Создаем объект
const zoneApi = new ZoneApi();

// Создаем состояние
class State {
    isZoneListBusy: boolean = false;
    zoneListItems: unknown [];
    zoneListItem: unknown;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isZoneListBusy(state): boolean {
        return state.isZoneListBusy;
    },

    getZoneListItems(state): unknown[] {
        return state.zoneListItems;
    },

    getZoneListItem(state): unknown {
        return state.zoneListItem;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsZoneListBusy(state, payload: boolean) {
        state.isZoneListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setZoneListItems(state, payload: unknown[]) {
        state.zoneListItems = payload
    },

    setZoneListItem(state, payload: unknown) {
        state.zoneListItem = payload
    }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setZoneListItem({ commit }, data: any) {
        commit('setZoneListItem', data);
    },

    // Список элементов
    getZoneListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsZoneListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                zoneApi.getZoneListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setZoneListItems', items);
                            commit('setIsZoneListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setZoneListItems', []);
                            commit('setIsZoneListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Создать элемент
    createZoneItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsZoneListBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            zoneApi.createZoneItem(data)
                .then(
                    (response:any) => {
                        commit('setIsZoneListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsZoneListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Редактировать элемент
    updateZoneItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsZoneListBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            zoneApi.updateZoneItem(data)
                .then(
                    (response:any) => {
                        commit('setIsZoneListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsZoneListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteZoneListItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsZoneListBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            zoneApi.deleteZoneItem(data)
                .then(
                    (response:any) => {
                        commit('setIsZoneListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsZoneListBusy', false);
                        reject(error);
                    }
                );
        });
    },
    // Получить элемент
    getZoneListItem({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsZoneListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            zoneApi.getZoneListItem(filter)
                .then(
                    (response:any) => {
                        commit('setZoneListItem', response.getItemsList()[0]);
                        commit('setIsZoneListBusy', false);
                        resolve(response.getItemsList()[0]);
                    },
                    (error) => {
                        commit('setIsZoneListBusy', false);
                        reject(error);
                    }
                );
        });
    },
};

const ZoneListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default ZoneListModule;
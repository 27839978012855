/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.4
// source: stat.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./stat_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.StatServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.StatServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CameraParamsRequest,
 *   !proto.proto.CameraParamsResponse>}
 */
const methodDescriptor_StatService_GetCameraParams = new grpc.web.MethodDescriptor(
  '/proto.StatService/GetCameraParams',
  grpc.web.MethodType.UNARY,
  proto.proto.CameraParamsRequest,
  proto.proto.CameraParamsResponse,
  /**
   * @param {!proto.proto.CameraParamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CameraParamsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CameraParamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CameraParamsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CameraParamsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.getCameraParams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/GetCameraParams',
      request,
      metadata || {},
      methodDescriptor_StatService_GetCameraParams,
      callback);
};


/**
 * @param {!proto.proto.CameraParamsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CameraParamsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.getCameraParams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/GetCameraParams',
      request,
      metadata || {},
      methodDescriptor_StatService_GetCameraParams);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateStatRequest,
 *   !proto.proto.CreateStatResponse>}
 */
const methodDescriptor_StatService_CreateStat = new grpc.web.MethodDescriptor(
  '/proto.StatService/CreateStat',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateStatRequest,
  proto.proto.CreateStatResponse,
  /**
   * @param {!proto.proto.CreateStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateStatResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.createStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/CreateStat',
      request,
      metadata || {},
      methodDescriptor_StatService_CreateStat,
      callback);
};


/**
 * @param {!proto.proto.CreateStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateStatResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.createStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/CreateStat',
      request,
      metadata || {},
      methodDescriptor_StatService_CreateStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CurrentStatRequest,
 *   !proto.proto.CurrentStatResponse>}
 */
const methodDescriptor_StatService_CurrentStat = new grpc.web.MethodDescriptor(
  '/proto.StatService/CurrentStat',
  grpc.web.MethodType.UNARY,
  proto.proto.CurrentStatRequest,
  proto.proto.CurrentStatResponse,
  /**
   * @param {!proto.proto.CurrentStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CurrentStatResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CurrentStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CurrentStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CurrentStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.currentStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/CurrentStat',
      request,
      metadata || {},
      methodDescriptor_StatService_CurrentStat,
      callback);
};


/**
 * @param {!proto.proto.CurrentStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CurrentStatResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.currentStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/CurrentStat',
      request,
      metadata || {},
      methodDescriptor_StatService_CurrentStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DetectionBatchRequest,
 *   !proto.proto.BatchDetectionResponse>}
 */
const methodDescriptor_StatService_BatchDetection = new grpc.web.MethodDescriptor(
  '/proto.StatService/BatchDetection',
  grpc.web.MethodType.UNARY,
  proto.proto.DetectionBatchRequest,
  proto.proto.BatchDetectionResponse,
  /**
   * @param {!proto.proto.DetectionBatchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.BatchDetectionResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DetectionBatchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.BatchDetectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.BatchDetectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.batchDetection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/BatchDetection',
      request,
      metadata || {},
      methodDescriptor_StatService_BatchDetection,
      callback);
};


/**
 * @param {!proto.proto.DetectionBatchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.BatchDetectionResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.batchDetection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/BatchDetection',
      request,
      metadata || {},
      methodDescriptor_StatService_BatchDetection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ItemStatRequest,
 *   !proto.proto.ItemStatResponse>}
 */
const methodDescriptor_StatService_ItemStat = new grpc.web.MethodDescriptor(
  '/proto.StatService/ItemStat',
  grpc.web.MethodType.UNARY,
  proto.proto.ItemStatRequest,
  proto.proto.ItemStatResponse,
  /**
   * @param {!proto.proto.ItemStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ItemStatResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ItemStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ItemStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ItemStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.itemStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/ItemStat',
      request,
      metadata || {},
      methodDescriptor_StatService_ItemStat,
      callback);
};


/**
 * @param {!proto.proto.ItemStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ItemStatResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.itemStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/ItemStat',
      request,
      metadata || {},
      methodDescriptor_StatService_ItemStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LatestCameraStatRequest,
 *   !proto.proto.LatestCameraStatResponse>}
 */
const methodDescriptor_StatService_LatestCameraStat = new grpc.web.MethodDescriptor(
  '/proto.StatService/LatestCameraStat',
  grpc.web.MethodType.UNARY,
  proto.proto.LatestCameraStatRequest,
  proto.proto.LatestCameraStatResponse,
  /**
   * @param {!proto.proto.LatestCameraStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.LatestCameraStatResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LatestCameraStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.LatestCameraStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.LatestCameraStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.latestCameraStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/LatestCameraStat',
      request,
      metadata || {},
      methodDescriptor_StatService_LatestCameraStat,
      callback);
};


/**
 * @param {!proto.proto.LatestCameraStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.LatestCameraStatResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.latestCameraStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/LatestCameraStat',
      request,
      metadata || {},
      methodDescriptor_StatService_LatestCameraStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListStatRequest,
 *   !proto.proto.ListStatResponse>}
 */
const methodDescriptor_StatService_ListStat = new grpc.web.MethodDescriptor(
  '/proto.StatService/ListStat',
  grpc.web.MethodType.UNARY,
  proto.proto.ListStatRequest,
  proto.proto.ListStatResponse,
  /**
   * @param {!proto.proto.ListStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListStatResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.listStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/ListStat',
      request,
      metadata || {},
      methodDescriptor_StatService_ListStat,
      callback);
};


/**
 * @param {!proto.proto.ListStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListStatResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.listStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/ListStat',
      request,
      metadata || {},
      methodDescriptor_StatService_ListStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.RenewStatRequest,
 *   !proto.proto.RenewStatResponse>}
 */
const methodDescriptor_StatService_RenewStat = new grpc.web.MethodDescriptor(
  '/proto.StatService/RenewStat',
  grpc.web.MethodType.UNARY,
  proto.proto.RenewStatRequest,
  proto.proto.RenewStatResponse,
  /**
   * @param {!proto.proto.RenewStatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.RenewStatResponse.deserializeBinary
);


/**
 * @param {!proto.proto.RenewStatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.RenewStatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.RenewStatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.renewStat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/RenewStat',
      request,
      metadata || {},
      methodDescriptor_StatService_RenewStat,
      callback);
};


/**
 * @param {!proto.proto.RenewStatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.RenewStatResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.renewStat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/RenewStat',
      request,
      metadata || {},
      methodDescriptor_StatService_RenewStat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateDetectParamsRequest,
 *   !proto.proto.UpdateCameraParamsResponse>}
 */
const methodDescriptor_StatService_UpdateCameraParams = new grpc.web.MethodDescriptor(
  '/proto.StatService/UpdateCameraParams',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateDetectParamsRequest,
  proto.proto.UpdateCameraParamsResponse,
  /**
   * @param {!proto.proto.UpdateDetectParamsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateCameraParamsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateDetectParamsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateCameraParamsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateCameraParamsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.StatServiceClient.prototype.updateCameraParams =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.StatService/UpdateCameraParams',
      request,
      metadata || {},
      methodDescriptor_StatService_UpdateCameraParams,
      callback);
};


/**
 * @param {!proto.proto.UpdateDetectParamsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateCameraParamsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.StatServicePromiseClient.prototype.updateCameraParams =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.StatService/UpdateCameraParams',
      request,
      metadata || {},
      methodDescriptor_StatService_UpdateCameraParams);
};


module.exports = proto.proto;


<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router'

const router = useRouter()
const userRole = localStorage.getItem('userRole')

onMounted(() => {
  window.stop()
})
</script>

<template>
  <div class="main-page">
    <div class="main-page__list">
      <q-btn color="primary" label="Камеры" @click="router.push('/cameras')"/>
      <q-btn color="primary" label="Компании" @click="router.push('/companies')"/>
      <q-btn color="primary" label="Сервисы" @click="router.push('/services')"/>
      <q-btn color="primary" label="Модели" @click="router.push('/models')"/>
      <q-btn color="primary" label="Пользователи" @click="router.push('/users/manage')"/>
      <q-btn color="primary" label="Сообщения" @click="router.push('/message')"/>
      <q-btn color="primary" label="Статистика" @click="router.push('/statistic')"/>
      <q-btn color="primary" label="Изменения" @click="router.push('/changes')"/>
      <q-btn v-if="userRole === 'super'" color="primary" label="Ручная детекция" @click="router.push('/manual-detection')"/>
    </div>
  </div>
</template>

<style lang="scss">

.main-page{
  max-width: 1440px;
  margin: 10px auto;
  &__list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -20px;
    .q-btn{
      padding: 100px 0;
      width: calc(33% - 36px);;
      margin: 0 20px;
      font-size: 24px;
      background-color: #3742fa !important;
      border-radius: 15px;
      span{
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-transform: none;
      }
      &:nth-child(n+4){
        margin: 40px 20px 0 20px;
      }
    }
  }
}

</style>
<template>

  <div class="watch">
    <div class="watch__left">
      <div class="watch__profile">
        <div class="watch__profile__header">
          <h3>Профиль</h3>
        </div>
        <div class="watch__profile__body">
          <ul>
            <li>
              <h3>ID камера:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.id }}</p>
            </li>
            <li class="status">
              <h3>Статус:</h3>
              <q-spinner v-if="isCameraLoading" size="16px"></q-spinner>
              <span v-if="!isCameraLoading && item.status === 'активна'" class="success">{{ item.status }}</span>
              <span v-else-if="!isCameraLoading && item.status === 'нет связи' || item.status === 'нет статуса'" class="error">{{ item.status }}</span>
              <span v-else-if="!isCameraLoading && item.status.includes('задержка')" class="pending">{{ item.status }}</span>
            </li>
            <li>
              <h3>UUID:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.uuid }}</p>
            </li>
            <li>
              <h3>Название:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.name }}</p>
            </li>
            <li>
              <h3>Координаты:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.address }}</p>
            </li>
            <li>
              <h3>Адрес:</h3>
              <q-spinner
                  v-if="addressLoader"
                  size="16px"
              ></q-spinner>
              <p v-if="!addressLoader">{{ address }}</p>
            </li>
            <li>
              <h3>Период опроса:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.survey_period }}</p>
            </li>
            <li>
              <h3>Период обновления статистики:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.stat_period }}</p>
            </li>
            <li>
              <h3>Дата обновления статистики:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ detectionTime }}</p>
            </li>
            <li>
              <h3>Добавлена:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="!isCameraLoading">{{ item.created }}</p>
            </li>
            <li>
              <h3>Компания:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="item.company_id !== 1 && !isCameraLoading" class="button" @click="this.$router.push('/companies/watch/' + this.item.company_id)">{{ item.company }}</p>
              <p v-else-if="!isCameraLoading">{{ item.company }}</p>
            </li>
            <li>
              <h3>Сервисы:</h3>
              <q-spinner
                  v-if="isCameraLoading"
                  size="16px"
              ></q-spinner>
              <p v-if="item.service_id !== 1 && !isCameraLoading" class="button" @click="this.$router.push('/services?service_id=' + this.item.company_id)">{{ item.service }}</p>
              <p v-else-if="!isCameraLoading">{{ item.service }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="watch__last-request">
        <div class="watch__last-request__header">
          <h3>Последние запросы</h3>
        </div>
        <div class="watch__last-request__body">
          <q-table style="box-shadow: none"
                   :columns="changeColumn"
                   :rows="getChangeListItems"
                   row-key="id"
          >
            <template v-slot:body-cell-type="props">
              <q-td :props="props">
                <p class="type">{{ props.row.type }}</p>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
      <div class="watch__last-message">
        <div class="watch__last-message__header">
          <h3>Последние сообщения</h3>
        </div>
        <div class="watch__last-message__body">
          <q-table style="box-shadow: none"
                   :columns="messageColumn"
                   :rows="getMessageListItems"
                   row-key="id"
                   @request="onRequestMessage"
                   v-model:pagination="messagePagination"
          >
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <div class="watch__right">
      <div class="watch__last-frame">
        <div class="watch__last-frame__header">
          <div class="watch__last-frame__header-description">
            <p>Кадр:</p>
            <p v-if="!isCameraOffline">{{ frameDate }}</p>
          </div>
          <q-btn color="white" text-color="black" v-if="!isCameraOffline" @click="cameraSync">Синхронизация</q-btn>
          <q-btn color="white" text-color="black" v-if="isCameraOffline || isCameraLoading" disable>
            Синхронизация
          </q-btn>
          <q-btn color="white" text-color="black" v-if="!isCameraOffline && userRole === 'super'" @click="watchDetection">Детекция</q-btn>
          <q-btn color="white" text-color="black" v-if="isCameraLoading && userRole === 'super'" disable>
            Детекция
          </q-btn>
          <q-btn color="white" text-color="black" v-if="!isCameraOffline" @click="watchStatistics">Статистика</q-btn>
          <q-btn color="white" text-color="black" v-if="isCameraOffline || isCameraLoading" disable>
            Статистика
          </q-btn>
        </div>
        <div class="watch__last-frame__body">
          <div class="watch__last-frame__body-screen">
            <img v-if="loader" src="https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif" alt="loader">
            <canvas width="900" height="500" ref="camera_canvas" id='canvas'></canvas>
          </div>
          <q-toggle
              v-if="!isCameraOffline"
              v-model="zoneSwitch"
              label="Отображение зон"
              @click="zonesDisplay"
          />
          <q-toggle
              v-if="isCameraOffline"
              disable
              label="Отображение зон"
              v-model="zoneSwitch"
          />
        </div>
      </div>
      <div class="watch__map">
        <div class="map-wrapper">
          <div :key="mapKey" id="map"></div>
        </div>
        <div class="watch__navigation">
          <q-btn v-show="!changeCameraOverview" color="primary" id="editOverview">Редактировать обзор камеры</q-btn>
          <q-btn v-show="changeCameraOverview" color="primary" id="saveOverview">Сохранить</q-btn>
          <q-btn v-show="!changeCameraOverview" @click="clearOverview" color="primary" class="clear" id="clearOverview">Очистить</q-btn>
          <q-btn v-show="changeCameraOverview" disable color="primary" class="clear">Очистить</q-btn>
        </div>
      </div>
      <div class="watch__parking-zone">
        <div class="watch__parking-zone__header">
          <h3>Зоны парковки</h3>
          <q-btn v-if="!isCameraOffline" color="white" text-color="black" @click="addZone">Добавить</q-btn>
          <q-btn color="white" text-color="black" v-if="isCameraOffline || isCameraLoading" disabled>Добавить</q-btn>
        </div>
        <div class="watch__parking-zone__body">
          <q-table
              :columns="zoneColumn"
              :rows="getZoneListItems"
              row-key="id"
          >
            <template v-slot:body-cell-type="props">
              <q-td :props="props">
                <q-list>
                  <q-item v-if="props.value">
                    <q-item-section>
                      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px"
                           viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#6BBE66" d="M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"/></g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item v-else>
                    <q-item-section>
                      <svg width="18px" height="18px" id="Layer_1" data-name="Layer 1"
                           xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                        <defs></defs>
                        <title>cancel</title>
                        <path style="fill:#c00706;fill-rule:evenodd;"
                              d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"/>
                        <path style="fill:#fff;fill-rule:evenodd;"
                              d="M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"/>
                      </svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item clickable v-if="!isCameraOffline" @click="actionRow('edit', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg"
                           xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path>
                          <path d="m195.656 33.271-52.882 52.882"
                                style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1"
                                transform="translate(-77.923 40.646)"></path></g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item v-if="isCameraLoading" disable>
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg"
                           xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path>
                          <path d="m195.656 33.271-52.882 52.882"
                                style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1"
                                transform="translate(-77.923 40.646)"></path></g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item v-if="!isCameraOffline" clickable @click="actionRow('watch', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                              d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z"
                              stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg>
                    </q-item-section>
                  </q-item>
                  <q-item v-if="isCameraLoading" disable>
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                              d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z"
                              stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        </g>
                      </svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <q-dialog
        v-model="syncError"
        persistent
    >
      <CameraSyncError
          :isConnectionError="connectionError"
      />
    </q-dialog>
  </div>

</template>

<script>

import store from '@/store'
import { loadYmap } from "vue-yandex-maps";
import CameraSyncError from './CameraSyncError.vue'

export default {
  name: 'CameraWatch',

  components: {
    CameraSyncError
  },

  transition: {
    name: 'parent-page',
    mode: 'in-out',
    css: false,
  },

  data() {
    return {
      item: {
        id: 0,
        uuid: '',
        name: '',
        status: '',
        survey_period: '',
        ip: '',
        mac: '',
        url: '',
        created: '',
        model: '',
        model_id: '',
        company: '',
        company_id: '',
        service: '',
        service_id: '',
        picture_url: '',
        picture_url_backup: '',
        address: '',
        sector: '',
        latitude: '',
        longitude: '',
        login: '',
        password: '',
        stat_period: '',
      },
      detectionTime: '',
      frameDate: '',
      zoneColumn: [
        {
          name: 'type',
          label: 'Тип',
          field: 'type',
          align: 'left',
          style: 'width: 18px;padding: 0 0 0 3px;',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'placeAllowed',
          label: 'Мест',
          field: 'placeAllowed',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'busy',
          label: 'Занято',
          field: 'busy',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'free',
          label: 'Свободно',
          field: 'free',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'points',
          label: 'Точек',
          field: 'points',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: 'Действие',
          align: 'center',
          field: 'id',
          headerStyle: 'width: 30px;font-weight: 600;font-size: 14px;',
          style: 'width: 18px',
        }
      ],
      changeColumn: [
        {
          name: 'type',
          label: 'Статус',
          field: 'type',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'events',
          label: 'Действие',
          field: 'events',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'date',
          label: 'Дата',
          field: 'date',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }
      ],
      messageColumn: [
        {
          name: 'message',
          label: 'Сообщение',
          field: 'message',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
      ],
      selectedZoneId: null,
      zonePagination: {
        sortBy: 'zone_name',
        descending: false,
        page: 1,
        rowsNumber: 0,
      },
      searchResult: [],
      points: [[]],
      zoneType: [false],
      zoneSwitch: true,
      loader: true,
      isCameraLoading: true,
      isCameraOffline: true,
      coordinates: [],
      changeCameraOverview: false,
      mapKey: 0,
      address: '',
      addressLoader: true,
      userRole: localStorage.getItem('userRole'),
      syncError: false,
      connectionError: false,
      messagePagination: {
        sortBy: 'message_id',
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      test: 0
    }
  },

  computed: {

    //Формирование списка изменений
    getChangeListItems() {
      const items = store.getters.getChangeListItems
      let rows = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getChangeId(),
            type: 'success',
            events: item.getChangeEvent(),
            date: item.getChangeCreated()
          })
        }
      }
      return rows
    },

    //Формирование списка зон для таблицы
    getZoneListItems() {
      const items = store.getters.getZoneListItems

      const statistic = store.getters.getLatestCameraStatListItems

      let points = []

      if (items !== undefined) {
        for (let zone of items) {
          if(statistic !== undefined){
            for(let stat of statistic){
              if(stat.getStatZoneId() === zone.getZoneId() || zone.getZoneIsAllowed() === false){
                points.push({
                  id: zone.getZoneId(),
                  type: zone.getZoneIsAllowed(),
                  name: zone.getZoneName(),
                  placeAllowed: zone.getZonePlacesAllowed(),
                  busy: stat.getStatOccupiedCount(),
                  free: (Number(zone.getZonePlacesAllowed()) - Number(stat.getStatOccupiedCount())) <= 0 ? 0 : Number(zone.getZonePlacesAllowed()) - Number(stat.getStatOccupiedCount()),
                  points: zone.getZoneSpot().split('},{').length,
                  schema: zone.getZoneSchema()
                })
              }
            }
          }
        }
      }

      const sortedPoints = points.reduce((o, i) => {
        if (!o.find(v => v.id == i.id)) {
          o.push(i);
        }
        return o;
      }, []);

      return sortedPoints
    },

    //Формирование списка зон для отрисовки
    getZonePoint() {
      const items = store.getters.getZoneListItems
      let points = []
      if (items !== undefined) {
        for (let zone of items) {
          points.push({
            points: zone.getZoneSpot(),
            type: zone.getZoneIsAllowed(),
          })
        }
      }
      return points
    },

    //Формирование списка сообщений с камеры
    getMessageListItems() {

      const messages = store.getters.getMessageListItems

      let rows = []
      if (messages !== undefined) {
        for (let message of messages) {
          rows.push({
            id: message.getMessageId(),
            camera_id: message.getMessageCameraId(),
            message: message.getMessageText()
          })
        }
      }
      return rows
    },

  },

  methods: {

    checkCameraFrame(){
      const image = new Image();
      image.src = this.item.picture_url;
      image.onerror = () => {
        this.item.picture_url = this.item.picture_url_backup;
      };
    },

    //Просмотр списка статистики
    watchStatistics() {
      this.$router.push('/cameras/watch/statistic/' + this.$route.params.id)
      window.stop()
    },

    //Просмотр детекции
    watchDetection(){
      this.$router.push('/cameras/watch/detections/' + this.$route.params.id)
      window.stop()
    },

    //Просмотр информации о зоне
    watchZone() {
      this.$router.push('/cameras/watch/' + this.$route.params.id + '/zone/' + this.selectedZoneId)
      window.stop()
    },

    //Добавить зону
    addZone() {
      this.$router.push('/cameras/watch/' + this.$route.params.id + '/zone-add')
      window.stop()
    },

    //Изменить зону
    editZone() {
      this.$router.push('/cameras/watch/' + this.$route.params.id + '/zone-edit/' + this.selectedZoneId)
      window.stop()
    },

    //Функционал кнопок в таблице
    actionRow(method, id) {
      this.selectedZoneId = id
      method === 'edit' ? this.editZone() :
          method === 'watch' ? this.watchZone() : console.log('Неизвестный метод')
    },

    //Загрузка данных камеры
    loadData() {
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCameraId(),
                  login: resolve.getCameraLogin(),
                  status: resolve.getCameraStatus(),
                  password: resolve.getCameraPassword(),
                  uuid: resolve.getCameraUuid(),
                  name: resolve.getCameraName(),
                  survey_period: resolve.getCameraSurveyPeriod(),
                  ip: resolve.getCameraIp(),
                  mac: resolve.getCameraMac(),
                  url: resolve.getCameraUrl(),
                  created: resolve.getCameraCreated(),
                  model: resolve.getCameraModel(),
                  model_id: resolve.getCameraModelId(),
                  company: resolve.getCameraCompanyName(),
                  company_id: resolve.getCameraCompanyId(),
                  service: resolve.getCameraServiceName(),
                  service_id: resolve.getCameraServiceId(),
                  picture_url: resolve.getCameraPictureUrl(),
                  picture_url_backup: resolve.getCameraPictureUrlBackup(),
                  address: resolve.getCameraLatitude() + ' ' + resolve.getCameraLongitude(),
                  latitude: resolve.getCameraLatitude(),
                  longitude: resolve.getCameraLongitude(),
                  sector: resolve.getCameraSector(),
                  stat_period: resolve.getCameraStatPeriod()
                }
                this.coordinates.push(resolve.getCameraLatitude())
                this.coordinates.push(resolve.getCameraLongitude())
                if (!this.item.sector) {
                  this.item.sector = resolve.getCameraLatitude() + ',' + resolve.getCameraLongitude()
                }
                this.isCameraLoading = false
                this.isCameraOffline = false
                this.checkCameraFrame()
                setTimeout(()=> {
                  this.mapKey++
                  this.map()
                  this.drawing()
                },500)
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
      this.loadStatisticListItems()
      this.loadZoneListItems(true)
      this.loadChangeListItems()
    },

    onRequestMessage(props) {
      this.messagePagination = props.pagination
      this.loadMessageListItems()
    },

    //Загрузка списка сообщений
    loadMessageListItems() {
      store
          .dispatch('getMessageListItems', {
            filter: [
              {
                field_name: 'message_camera_id',
                operand: '=',
                value: this.$route.params.id
              }
            ],
            sort: {
              name: this.messagePagination.sortBy,
              exp: this.messagePagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.messagePagination.page,
              limit: this.messagePagination.rowsPerPage,
              pages: 0,
              cnt: this.messagePagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getMessageListItems resolve', resolve)
                this.messagePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getMessageListItems reject', reject)
              }
          )
    },

    //Загрузка списка изменений
    loadChangeListItems() {
      store
          .dispatch('getChangeListItems', {
            filter: [
              {
                field_name: 'change_object_id',
                operand: '=',
                value: this.$route.params.id
              }
            ]
          })
          .then(
              (resolve) => {
                console.log('getChangeListItems resolve', resolve)
              },
              (reject) => {
                console.log('getChangeListItems reject', reject)
              }
          )
    },

    //Запрос на получение крайнего кадра с камеры
    loadLastFrame() {
      store
          .dispatch('getItemCamera', {id: this.$route.params.id}).then(
          (resolve) => {
            this.loadData()
            this.loadLatestCameraStatListItems()
            this.loadMessageListItems()
            console.log('loadLastFrame', resolve)
          },
          (reject) => {
            this.connectionError = true
            this.syncError = true
            this.drawError()
            this.loadMessageListItems()
            this.loader = false
            this.isCameraOffline = true
            console.log('loadLastFrame', reject)
          }
      )
    },

    //Загрузка крайней статиктики по камере
    loadLatestCameraStatListItems(){
      store
          .dispatch('getLatestCameraStatListItems', {id: this.$route.params.id}).then(
          (resolve) => {
            console.log('getLatestCameraStatListItems', resolve)
            resolve.items.forEach(item => {
              this.detectionTime = item.getStatShotCreated()
              this.frameDate = item.getStatShotCreated()
            })
          },
          (reject) => {
            console.log('getLatestCameraStatListItems', reject)
          }
      )
    },

    //Загрузка списка зон
    loadZoneListItems(isResetPagination) {
      if (isResetPagination) {
        this.zonePagination.page = 1
        this.zonePagination.rowsNumber = 0
      }
      store
          .dispatch('getZoneListItems', {
            filter: [
              {
                field_name: 'zone_camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
            sort: {
              name: this.zonePagination.sortBy,
              exp: this.zonePagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.zonePagination.page,
              pages: 0,
              cnt: this.zonePagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getZoneListItems resolve', resolve)
                this.zonePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getZoneListItems reject', reject)
              }
          )
    },

    //Загрузка всего списка статистики
    loadStatisticListItems() {
      store
          .dispatch('getStatListItems', {
            filter: [
              {
                field_name: 'stat_camera_id',
                operand: '=',
                value: this.$route.params.id
              }
            ],
          })
          .then(
              (resolve) => {
                console.log('getStatListItems resolve', resolve)
              },
              (reject) => {
                console.log('getStatListItems reject', reject)
              }
          )
    },

    //Отображение изображения кадра с зонами
    drawing() {

      setTimeout(() => {
        let zoneList = this.getZonePoint
        let points = []
        let regexX = /"x":\d{1,3}/gm;
        let regexY = /"y":\d{1,3}/gm;
        zoneList.forEach(zone => {
          points.push(zone.points.split('},{'))
          this.zoneType.push(zone.type)
        })
        this.points = [[]]
        points.forEach(pointList => {
          let setSpot = []
          pointList.forEach(point => {
            let spotObj = {
              x: 0,
              y: 0
            }
            let spotX = point.match(regexX)
            let spotY = point.match(regexY)
            if (spotX !== null) {
              spotX.forEach(spot => {
                spotObj.x = Number(spot.replace('"x":', ''))
              })
            }
            if (spotY !== null) {
              spotY.forEach(spot => {
                spotObj.y = Number(spot.replace('"y":', ''))
              })
            }
            setSpot.push(spotObj)
          })
          this.points.push(setSpot)
        })
      }, 100)

      setTimeout(() => {

        let canvas = document.getElementById("canvas");
        canvas.width = 900;
        canvas.height = 500;
        let ctx = canvas.getContext("2d");
        let cameraImage = new Image();
        cameraImage.src = this.item.picture_url
        let zoneType = this.zoneType
        let points = this.points
        cameraImage.onload = function () {
          ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
          points.forEach((point, idx) => {
            if (zoneType[idx]) {
              ctx.fillStyle = 'rgba(0,232,39,0.3)';
              ctx.strokeStyle = 'rgba(0,232,39,0.3)';
            } else {
              ctx.fillStyle = 'rgba(232,0,0,0.3)';
              ctx.strokeStyle = 'rgba(232,0,0,0.3)';
            }
            let closed = true;
            ctx.beginPath();
            for (const p of point) {
              ctx.lineTo(p.x, p.y)
            }
            closed && ctx.closePath();
            ctx.stroke();
            closed && ctx.fill();


            for (const p of point) {
              ctx.beginPath();
              ctx.moveTo(p.x + 10, p.y);
              ctx.fill();
              ctx.stroke();
            }

          })
        }
        this.loader = false

      }, 150)

    },

    //Отображение/скрытие зон
    zonesDisplay() {
      if (!this.zoneSwitch) {
        this.zoneSwitch = false
        this.points = [[]]
        let canvas = document.getElementById("canvas");
        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        let cameraImage = new Image();
        cameraImage.src = this.item.picture_url
        cameraImage.onload = function () {
          ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
        }
      } else {
        this.drawing()
      }
    },

    //Отображение изображения ошибки
    drawError() {
      let cameraImage = new Image();
      cameraImage.src = 'https://mediapure.ru/wp-content/uploads/2017/12/error-1021x580.jpg';
      let canvas = document.getElementById("canvas");
      canvas.width = 900;
      canvas.height = 500;
      let ctx = canvas.getContext("2d");
      cameraImage.onload = function () {
        ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
      }
    },

    clearCamera(){
      let canvas = document.getElementById("canvas");
      canvas.width = 900;
      canvas.height = 500;
      let ctx = canvas.getContext('2d');
      ctx.fillStyle = '#ffffff'; // белый цвет
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    },

    //Сохранение сектора просмотра камеры
    saveCamera(sector) {
      sector.forEach( unit => {
        unit[0] ? unit[0] = Number(unit[0]).toFixed(6) : ''
        unit[1] ? unit[1] = Number(unit[1]).toFixed(6) : ''
      })

      let coordinates = String(sector)

      let data = {
        id: this.item.id,
        name: this.item.name,
        model_id: this.item.model_id,
        login: this.item.login,
        password: '',
        survey_period: this.item.survey_period,
        ip: this.item.ip,
        mac: this.item.mac,
        url: this.item.url,
        service_id: this.item.service_id,
        company_id: this.item.company_id,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        sector: coordinates
      }

      store.dispatch('updateCamerasItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
      return true
    },

    //Яндекс карты
    async map() {

      await loadYmap({apiKey: 'e98d34f8-157a-43e9-9dc5-46d8dd7e4f59'}, {debug: true});

      setTimeout(() => {
        let mapCoordinates = this.coordinates,
            zones = this.getZoneListItems,
            sector = [],
            cameraCoordinates = [this.item.latitude,this.item.longitude],
            names = [],
            // eslint-disable-next-line no-unused-vars
            overviewCoordinates = [],
            cameraOverviewStatus = false
        const editOverview = document.querySelector('#editOverview'),
            saveOverview = document.querySelector('#saveOverview')
        for (let i = 0; i < this.item.sector.split(',').length; i += 2) {
          const coordinate = [...[Number(this.item.sector.split(',')[i])], ...[Number(this.item.sector.split(',')[i + 1])]]
          sector.push(coordinate)
        }

        const init = () => {

          let map = new window.ymaps.Map('map', {
            center: mapCoordinates,
            zoom: 18
          }, {
            restrictMapArea: true
          });

          window.ymaps.geocode(cameraCoordinates).then(function (res) {
            res.geoObjects.each(function (obj) {
              names.push(obj.properties.get('name'));
            })
          })

          let cameraMark = new window.ymaps.Placemark(map.getCenter(), {
            hintContent: 'Расположение камеры',
          }, {
            iconLayout: 'default#image',
            iconImageHref: require('@/assets/img/camera-icon.png'),
            iconImageSize: [60, 60],
            iconImageOffset: [-30, -30]
          })

          zones.forEach(zone => {
            let squareLayout = window.ymaps.templateLayoutFactory.createClass('<div class="map-parking-zone"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M19.1768 3.97115C19.0898 3.97115 19.0004 3.98357 18.9112 4.00822L17.9019 4.28627L16.8538 1.73315C16.5569 1.0098 15.6792 0.421387 14.8973 0.421387H5.08856C4.30662 0.421387 3.42894 1.0098 3.13209 1.73315L2.08537 4.28279L1.08873 4.00822C0.999617 3.98365 0.910165 3.97115 0.823179 3.97115C0.346244 3.97115 0 4.33551 0 4.83761V5.43274C0 6.01885 0.476765 6.49562 1.06288 6.49562H1.17699L1.00787 6.90768C0.732027 7.57976 0.507631 8.71738 0.507631 9.44379V14.5159C0.507631 15.1021 0.984482 15.5788 1.57051 15.5788H2.95872C3.54483 15.5788 4.0216 15.1021 4.0216 14.5159V13.2492H15.9645V14.5159C15.9645 15.1021 16.4412 15.5788 17.0273 15.5788H18.4155C19.0016 15.5788 19.4783 15.1021 19.4783 14.5159V9.44379C19.4783 8.71746 19.2539 7.57976 18.978 6.90768L18.8089 6.49562H18.9371C19.5232 6.49562 20 6.01885 20 5.43274V4.83761C20 4.33551 19.6538 3.97115 19.1768 3.97115ZM2.84758 5.91826L4.43297 2.05644C4.6106 1.6238 5.13856 1.26982 5.60622 1.26982H14.3798C14.8475 1.26982 15.3755 1.6238 15.553 2.05644L17.1384 5.91826C17.316 6.3509 17.0787 6.70488 16.611 6.70488H3.37503C2.90736 6.70488 2.67004 6.3509 2.84758 5.91826ZM6.3868 10.8029C6.3868 11.0368 6.19548 11.2281 5.96165 11.2281H2.94987C2.71604 11.2281 2.52472 11.0368 2.52472 10.8029V9.35749C2.52472 9.12365 2.71604 8.93234 2.94987 8.93234H5.96165C6.19548 8.93234 6.3868 9.12365 6.3868 9.35749V10.8029ZM17.4328 10.8029C17.4328 11.0368 17.2414 11.2281 17.0076 11.2281H13.9959C13.7621 11.2281 13.5708 11.0368 13.5708 10.8029V9.35749C13.5708 9.12365 13.7621 8.93234 13.9959 8.93234H17.0076C17.2414 8.93234 17.4328 9.12365 17.4328 9.35749V10.8029Z" fill="black"/>\n' +
                '</svg>\n$[properties.iconContent]</div>');

            const coordinate = JSON.parse(zone.schema)
            let parking = new window.ymaps.Polygon([coordinate], {}, {
              editorDrawingCursor: "crosshair",
              fillColor: zone.type ? '#00E8274C' : '#E800004C',
              strokeColor: zone.type ? '#00E8274C' : '#E800004C',
              strokeWidth: 3
            });

            let coordinateX = 0,
                coordinateY = 0
            coordinate.map((item) => coordinateX += item[0])
            coordinate.map((item) => coordinateY += item[1])
            let coordinateCenter = [[coordinateX / coordinate.length], [coordinateY / coordinate.length]]
            let text = new window.ymaps.Placemark(coordinateCenter, {
              iconContent: zone.free
            }, {
              iconLayout: squareLayout,
              // Описываем фигуру активной области "Прямоугольник".
              iconShape: {
                type: 'Rectangle',
                coordinates: [
                  [-5, -5], [5, 5]
                ]
              }
            })

            zone.type ? map.geoObjects.add(parking).add(text) : map.geoObjects.add(parking)

          })

          let cameraOverview = new window.ymaps.Polygon([sector], {}, {
            editorDrawingCursor: "crosshair",
            fillColor: '#B85750',
            strokeColor: '#B85750',
            strokeWidth: 3,
            opacity: 0.4
          })

          map.geoObjects
              .add(cameraMark)
              .add(cameraOverview)

          map.controls
              .remove('default')

          let stateMonitor = new window.ymaps.Monitor(cameraOverview.editor.state);

          stateMonitor.add("drawing", function (newValue) {
            cameraOverview.options.set("strokeColor", newValue ? '#B85750' : '#B85750');
          });

          editOverview.addEventListener('click', () => {

            cameraOverviewStatus = true
            cameraOverview.geometry._coordPath._coordinates[0] = sector
            cameraOverview.editor.startDrawing()

          })

          saveOverview.addEventListener('click', () => {
            cameraOverviewStatus = false
            cameraOverview.editor.stopEditing()
          })

          cameraOverview.events.add('geometrychange', () => {
            overviewCoordinates = []
            overviewCoordinates = cameraOverview.geometry._coordPath._coordinates[0]
          })

        }

        saveOverview.addEventListener('click', () => {
          this.saveCamera(overviewCoordinates)
        })

        document.addEventListener('click', () => {
          this.changeCameraOverview = cameraOverviewStatus
        })

        window.ymaps.ready(init)
        setTimeout(() => {
          this.addressLoader = false
          this.address = String(names[0])
        },400)
      }, 50)

    },

    //Очистить сектор просмотра камеры
    clearOverview(){
      this.saveCamera([])
      setTimeout(() => {
        this.loadData()
        this.mapKey++
        this.map()
      },50)
    },

    cameraSync() {
      this.clearCamera()
      this.isCameraLoading = true
      this.isCameraOffline = true
      this.loader = true
      store
          .dispatch('cameraSync', {id: this.$route.params.id}).then(
          (resolve) => {
            console.log('cameraSync resolve', resolve)
            this.loadLastFrame()
          },
          (reject) => {
            console.log('cameraSync reject', reject)
            this.syncError = true
            this.loadLastFrame()
          }
      )
    },

  },

  mounted() {
    this.loadLastFrame()
  },


}
</script>

<style lang="scss">

.watch {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &__left {
    width: calc(35% - 10px);
  }

  &__right {
    width: calc(60% - 10px);
    min-width: 930px;
  }

  &__last-request, &__last-message, &__parking-zone {
    margin: 30px 0 0 0;
  }

  &__profile, &__last-request, &__parking-zone, &__last-frame, &__last-message, &__map {
    height: fit-content;
    width: 100%;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);

    &__header {
      background: #3742fa;
      border-bottom: 1px solid #b3b3b3;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 20px;
      border-radius: 10px 10px 0 0;
      height: 77px;

      h3 {
        font-weight: 500;
      }

      h3, p {
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 100%;
        color: #fff;
      }
    }
  }

  &__profile {
    &__body {
      ul {
        padding: 18px 20px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        li {
          display: flex;
          align-items: flex-start;
          list-style: none;

          &:not(:last-child), &:not(:first-child) {
            padding: 15px 0;
          }

          h3 {
            width: max-content;
            text-align: right;
            flex-shrink: 0;
          }

          h3, p {
            font-size: 16px;
            font-weight: 700;
            padding: 0;
            margin: 0;
            line-height: 100%;
          }

          p {
            font-weight: 400;
            padding: 0 0 0 15px;
          }

          .q-spinner{
            margin: 0 0 0 15px;
          }

          .button {
            color: #027BE3;
            cursor: pointer;
          }

          .success, .error {
            background: #21BA45;
            color: #fff;
            padding: 2px 5px 3px 5px;
            margin: -3px 0 0 15px;
            border-radius: 6px;
          }

          .error {
            background: #c00706;
          }
        }
      }
    }
  }

  &__last-request {
    .type {
      display: flex;
      width: fit-content;
      margin: 0;
      background: #21BA45;
      padding: 2px 5px 3px 5px;
      line-height: 100%;
      color: #fff;
      border-radius: 6px;
    }

    &__body {
      .q-table__container {
        box-shadow: unset;
        border-radius: 0 0 10px 10px;
      }
    }
  }

  &__last-message {
    &__body {
      ul {
        margin: 0;
        list-style: none;
        padding: 18px 20px;
      }

      p {
        margin: 0;
        padding: 36px 20px;
        font-size: 24px;
        text-align: center;
      }

      .q-table {
        thead {
          display: none;
        }
      }
    }
  }

  &__last-frame {
    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &-description{
        display: flex;
        margin: 0 auto 0 0;
      }
      p {
        &:first-child {
          padding: 0 5px 0 0;
        }
      }

      button {
        margin: 0 0 0 10px;
      }
    }

    &__body {
      padding: 15px;
      display: flex;
      flex-direction: column;

      &-screen {
        position: relative;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
        }
      }

      .q-toggle {
        margin: 10px 0 0 auto;
      }
    }
  }

  &__map {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin: 20px 0 0 0;

    .q-btn {
      background: #3742fa !important;
    }

    .map-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 900px;
      height: 500px;
    }

    #map {
      overflow: hidden;
      width: 900px;
      height: 500px;
      padding: 0;

      .ymaps-2-1-79-copyrights-pane {
        display: none;
      }
    }

    .map-parking-zone {
      display: flex;

      svg {
        margin: 0 3px 0 0;
        flex-shrink: 0;
      }
    }
  }

  &__navigation{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{
      margin: 0;
      &.clear{
        margin: 0 0 0 16px;
      }
    }
  }

  &__parking-zone {
    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      button {
        margin: 0 0 0 auto;
      }
    }

    &__body {
      .q-table__container {
        box-shadow: unset;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {

  .watch {
    &__left {
      width: calc(35% - 25px);
    }

    &__right {
      width: calc(60% - 25px);
    }
  }

}

</style>
import ServicesList from '../views/services/ServicesList.vue'
import ServiceAdd from '../views/services/ServiceAdd.vue';
import ServiceEdit from '../views/services/ServiceEdit.vue';

const routes = [
    {
        path: '/services',
        name: 'ServicesList',
        component: ServicesList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Сервисы', to: { name: 'ServicesList' }}
            ]
        }
    },
    {
        path: '/services/add',
        name: 'ServiceAdd',
        component: ServiceAdd,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Сервисы', to: { name: 'ServicesList' }},
                { text: 'Добавление'}
            ]
        }
    },
    {
        path: '/services/edit/:id',
        name: 'ServiceEdit',
        component: ServiceEdit,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Сервисы', to: { name: 'ServicesList' }},
                { text: 'Изменить'}
            ]
        }
    },
]

export default {
    routes
}
/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.4
// source: change.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./change_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ChangeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ChangeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateChangeRequest,
 *   !proto.proto.CreateChangeResponse>}
 */
const methodDescriptor_ChangeService_CreateChange = new grpc.web.MethodDescriptor(
  '/proto.ChangeService/CreateChange',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateChangeRequest,
  proto.proto.CreateChangeResponse,
  /**
   * @param {!proto.proto.CreateChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateChangeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateChangeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateChangeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ChangeServiceClient.prototype.createChange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ChangeService/CreateChange',
      request,
      metadata || {},
      methodDescriptor_ChangeService_CreateChange,
      callback);
};


/**
 * @param {!proto.proto.CreateChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateChangeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ChangeServicePromiseClient.prototype.createChange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ChangeService/CreateChange',
      request,
      metadata || {},
      methodDescriptor_ChangeService_CreateChange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListChangeRequest,
 *   !proto.proto.ListChangeResponse>}
 */
const methodDescriptor_ChangeService_ListChange = new grpc.web.MethodDescriptor(
  '/proto.ChangeService/ListChange',
  grpc.web.MethodType.UNARY,
  proto.proto.ListChangeRequest,
  proto.proto.ListChangeResponse,
  /**
   * @param {!proto.proto.ListChangeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListChangeResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListChangeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListChangeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ChangeServiceClient.prototype.listChange =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ChangeService/ListChange',
      request,
      metadata || {},
      methodDescriptor_ChangeService_ListChange,
      callback);
};


/**
 * @param {!proto.proto.ListChangeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListChangeResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ChangeServicePromiseClient.prototype.listChange =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ChangeService/ListChange',
      request,
      metadata || {},
      methodDescriptor_ChangeService_ListChange);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListChangeEventsRequest,
 *   !proto.proto.ListChangeEventsResponse>}
 */
const methodDescriptor_ChangeService_ListChangeEvents = new grpc.web.MethodDescriptor(
  '/proto.ChangeService/ListChangeEvents',
  grpc.web.MethodType.UNARY,
  proto.proto.ListChangeEventsRequest,
  proto.proto.ListChangeEventsResponse,
  /**
   * @param {!proto.proto.ListChangeEventsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListChangeEventsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListChangeEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListChangeEventsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListChangeEventsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ChangeServiceClient.prototype.listChangeEvents =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ChangeService/ListChangeEvents',
      request,
      metadata || {},
      methodDescriptor_ChangeService_ListChangeEvents,
      callback);
};


/**
 * @param {!proto.proto.ListChangeEventsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListChangeEventsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ChangeServicePromiseClient.prototype.listChangeEvents =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ChangeService/ListChangeEvents',
      request,
      metadata || {},
      methodDescriptor_ChangeService_ListChangeEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListChangeObjectsRequest,
 *   !proto.proto.ListChangeObjectsResponse>}
 */
const methodDescriptor_ChangeService_ListChangeObjects = new grpc.web.MethodDescriptor(
  '/proto.ChangeService/ListChangeObjects',
  grpc.web.MethodType.UNARY,
  proto.proto.ListChangeObjectsRequest,
  proto.proto.ListChangeObjectsResponse,
  /**
   * @param {!proto.proto.ListChangeObjectsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListChangeObjectsResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListChangeObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListChangeObjectsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListChangeObjectsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ChangeServiceClient.prototype.listChangeObjects =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ChangeService/ListChangeObjects',
      request,
      metadata || {},
      methodDescriptor_ChangeService_ListChangeObjects,
      callback);
};


/**
 * @param {!proto.proto.ListChangeObjectsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListChangeObjectsResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ChangeServicePromiseClient.prototype.listChangeObjects =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ChangeService/ListChangeObjects',
      request,
      metadata || {},
      methodDescriptor_ChangeService_ListChangeObjects);
};


module.exports = proto.proto;


import CompanyList from '../views/company/CompanyList.vue'
import CompanyAdd from '../views/company/CompanyAdd.vue'
import CompanyEdit from '../views/company/CompanyEdit.vue'
import CompanyWatch from '../views/company/CompanyWatch.vue'


const routes = [
    {
        path: '/companies',
        name: 'CompanyList',
        component: CompanyList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Компании', to: { name: 'CompanyList' }}
            ]
        }
    },
    {
        path: '/companies/add',
        name: 'CompanyAdd',
        component: CompanyAdd,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Компании', to: { name: 'CompanyList' }},
                { text: 'Добавление'}
            ]
        }
    },
    {
        path: '/companies/edit/:id',
        name: 'CompanyEdit',
        component: CompanyEdit,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Компании', to: { name: 'CompanyList' }},
                { text: 'Изменить'}
            ]
        }
    },
    {
        path: '/companies/watch/:id',
        name: 'CompanyWatch',
        component: CompanyWatch,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Компании', to: { name: 'CompanyList' }},
                { text: 'Компания'  }
            ]
        }
    }
]

export default {
    routes
}
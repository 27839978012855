<template>
  <div class="q-pa-md row wrap justify-center items-center content-center">
    <div class="text-h6 col-12 text-center q-pa-md">Вход в систему</div>

    <q-card class="q-pa-md col-xs-12 col-sm-6 col-lg-4">
      <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md">
        <q-input
          filled
          v-model="login"
          label="Логин"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Неправильный логин']"
        />

        <q-input
          v-model="passw"
          filled
          :type="isPwd ? 'password' : 'text'"
          label="Пароль"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <div id="errMessage" class="text-negative">{{ errMessage }}</div>

        <div
          class="full-width row wrap justify-center items-center content-center"
        >
          <q-btn
            label="Войти"
            type="submit"
            color="primary"
            no-caps
            unelevated
          />
          <q-btn
            label="Сброс"
            type="reset"
            color="primary"
            flat
            class="q-ml-sm"
            no-caps
            unelevated
          />
        </div>
      </q-form>
    </q-card>
  </div>
</template>

<script lang="ts">
import store from '@/store'
import { ref } from 'vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserAuth',

  data() {
    return {}
  },

  setup() {
    const login = ref('')
    const passw = ref('')
    let isPwd = ref(true)
    const errMessage = ref('')

    return {
      login,
      passw,
      isPwd,
      errMessage,

      onSubmit() {
        store
          .dispatch('doUserAuth', {
            login: login.value,
            passw: passw.value,
          })
          .then(
            (resolve) => {
              let accessToken = store.getters.getAccessToken,
                  refreshToken = store.getters.getRefreshToken

              console.log('resolve', resolve)

              if (accessToken.length > 0) {
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
                localStorage.setItem('userId', resolve.userId)
                localStorage.setItem('userRole', resolve.userRole)
                let link = location.href
                window.location.href = new URL(link).origin;

              } else {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')
                localStorage.removeItem('userId')
                localStorage.removeItem('userRole')
                errMessage.value = 'Неправильные имя пользователя или пароль'
                passw.value = ''
              }
            },
            (reject) => {
              console.log('reject', reject)
              localStorage.removeItem('accessToken')
              localStorage.removeItem('refreshToken')
              localStorage.removeItem('userId')
              localStorage.removeItem('userRole')
              errMessage.value = 'Неправильные имя пользователя или пароль'
              passw.value = ''
            }
          )
      },

      onReset() {
        login.value = ''
        passw.value = ''
        errMessage.value = ''
      },
    }
  },
})
</script>

<script setup lang="ts">

import { defineProps } from 'vue';
import { useRouter, useRoute } from 'vue-router'
const props = defineProps(['isConnectionError'])
const router = useRouter()
const route = useRoute()
const userRole = localStorage.getItem('userRole')

const watchDetection = () => {
  window.stop()
  router.push('/cameras/watch/detections/' + route.params.id)
}


</script>

<template>
  <q-card class="sync-error">
    <q-card-section>
      <h2>Что-то пошло не так...</h2>
      <p>Возникла ошибка при попытке подключения к камере, пожалуйста повторите попытку позже</p>
      <q-btn v-if="props.isConnectionError" @click="router.push('/cameras')">Назад</q-btn>
      <q-btn v-if="userRole === 'super' || userRole === 'admin'" @click="watchDetection">Настройка детекции</q-btn>
      <q-btn v-else-if="!props.isConnectionError && userRole !== 'super'" v-close-popup>Закрыть</q-btn>
    </q-card-section>
  </q-card>
</template>

<style scoped lang="scss">

.sync-error{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  h2{
    font-size: 28px;
    line-height: 100%;
    font-weight: 600;
    color: #000;
    padding: 0;
    text-align: center;
    margin: 0 0 32px 0;
  }
  p{
    text-align: center;
    color: #000;
    font-size: 18px;
    line-height: 100%;
    padding: 0;
    margin: 0;
  }
  .q-btn{
    color: #000;
    margin: 32px auto 16px auto;
    border: 1px solid #fff;
    width: fit-content;
    border-radius: 30px;
    font-size: 16px;
    line-height: 100%;
    padding: 8px 32px;
    display: flex;
    justify-content: center;
  }
}

</style>
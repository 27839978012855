/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v4.23.2
// source: service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ServiceServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ServiceServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateServiceRequest,
 *   !proto.proto.CreateServiceResponse>}
 */
const methodDescriptor_ServiceService_CreateService = new grpc.web.MethodDescriptor(
  '/proto.ServiceService/CreateService',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateServiceRequest,
  proto.proto.CreateServiceResponse,
  /**
   * @param {!proto.proto.CreateServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateServiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ServiceServiceClient.prototype.createService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ServiceService/CreateService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_CreateService,
      callback);
};


/**
 * @param {!proto.proto.CreateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateServiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ServiceServicePromiseClient.prototype.createService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ServiceService/CreateService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_CreateService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateServiceRequest,
 *   !proto.proto.UpdateServiceResponse>}
 */
const methodDescriptor_ServiceService_UpdateService = new grpc.web.MethodDescriptor(
  '/proto.ServiceService/UpdateService',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateServiceRequest,
  proto.proto.UpdateServiceResponse,
  /**
   * @param {!proto.proto.UpdateServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateServiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ServiceServiceClient.prototype.updateService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ServiceService/UpdateService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_UpdateService,
      callback);
};


/**
 * @param {!proto.proto.UpdateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateServiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ServiceServicePromiseClient.prototype.updateService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ServiceService/UpdateService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_UpdateService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteServiceRequest,
 *   !proto.proto.DeleteServiceResponse>}
 */
const methodDescriptor_ServiceService_DeleteService = new grpc.web.MethodDescriptor(
  '/proto.ServiceService/DeleteService',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteServiceRequest,
  proto.proto.DeleteServiceResponse,
  /**
   * @param {!proto.proto.DeleteServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteServiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ServiceServiceClient.prototype.deleteService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ServiceService/DeleteService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_DeleteService,
      callback);
};


/**
 * @param {!proto.proto.DeleteServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteServiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ServiceServicePromiseClient.prototype.deleteService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ServiceService/DeleteService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_DeleteService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListServiceRequest,
 *   !proto.proto.ListServiceResponse>}
 */
const methodDescriptor_ServiceService_ListService = new grpc.web.MethodDescriptor(
  '/proto.ServiceService/ListService',
  grpc.web.MethodType.UNARY,
  proto.proto.ListServiceRequest,
  proto.proto.ListServiceResponse,
  /**
   * @param {!proto.proto.ListServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListServiceResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListServiceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListServiceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ServiceServiceClient.prototype.listService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ServiceService/ListService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_ListService,
      callback);
};


/**
 * @param {!proto.proto.ListServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListServiceResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ServiceServicePromiseClient.prototype.listService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ServiceService/ListService',
      request,
      metadata || {},
      methodDescriptor_ServiceService_ListService);
};


module.exports = proto.proto;


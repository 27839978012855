import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { MessageApi } from '../services/api/MessageApi';
import Loading from 'quasar/src/plugins/Loading.js';

// Создаем объект
const messageApi = new MessageApi();

// Создаем состояние
class State {
    isMessageListBusy: boolean = false;
    messageListItems: unknown [];
    messageListItem: unknown;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isMessageListBusy(state): boolean {
        return state.isMessageListBusy;
    },

    getMessageListItems(state): unknown[] {
        return state.messageListItems;
    },

    getMessageListItem(state): unknown {
        return state.messageListItem;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsMessageListBusy(state, payload: boolean) {
        state.isMessageListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setMessageListItems(state, payload: unknown[]) {
        state.messageListItems = payload
    },

    setMessageListItem(state, payload: unknown) {
        state.messageListItem = payload
    }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setMessageListItem({ commit }, data: any) {
        commit('setMessageListItem', data);
    },

    // Список элементов
    getMessageListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsMessageListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                messageApi.getMessageListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setMessageListItems', items);
                            commit('setIsMessageListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setMessageListItems', []);
                            commit('setIsMessageListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

};


const MessageModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default MessageModule;



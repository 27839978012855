import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { StatApi } from '../services/api/StatisticApi';
import Loading from 'quasar/src/plugins/Loading.js';

// Создаем объект
const statApi = new StatApi();

// Создаем состояние
class State {
    isStatListBusy: boolean = false;
    statListItems: unknown [];
    statListItem: unknown;
    latestCameraStatListItems: unknown [];
    currentCameraStatListItems: unknown [];
    currentCameraParams: unknown [];
    updateCameraParams: unknown [];
    sendManualDetection: unknown [];
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isStatListBusy(state): boolean {
        return state.isStatListBusy;
    },

    getStatListItems(state): unknown[] {
        return state.statListItems;
    },

    getStatListItem(state): unknown {
        return state.statListItem;
    },

    getLatestCameraStatListItems(state): unknown [] {
        return state.latestCameraStatListItems;
    },

    getCurrentCameraStatListItems(state): unknown []{
        return state.currentCameraStatListItems;
    },

    getCurrentCameraParams(state): unknown []{
        return state.currentCameraParams;
    },

    getUpdateCameraParams(state): unknown []{
        return state.updateCameraParams;
    },

    getSendManualDetection(state): unknown[]{
        return state.sendManualDetection
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsStatListBusy(state, payload: boolean) {
        state.isStatListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setStatListItems(state, payload: unknown[]) {
        state.statListItems = payload
    },

    setStatListItem(state, payload: unknown) {
        state.statListItem = payload
    },

    setLatestCameraStatListItems(state, payload: unknown[]) {
        state.latestCameraStatListItems = payload
    },

    setCurrentCameraStatListItems(state, payload: unknown[]) {
        state.currentCameraStatListItems = payload
    },

    setCurrentCameraParams(state, payload: unknown[]) {
        state.currentCameraParams = payload
    },

    setUpdateCameraParams(state, payload: unknown[]) {
        state.updateCameraParams = payload
    },
    setSendManualDetection(state, payload: unknown[]) {
        state.sendManualDetection = payload
    },

}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setStatListItem({ commit }, data: any) {
        commit('setStatListItem', data);
    },

    // Список элементов
    getStatListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsStatListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                statApi.getStatListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setStatListItems', items);
                            commit('setIsStatListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setStatListItems', []);
                            commit('setIsStatListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Список последних статистик
    getLatestCameraStatListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsStatListBusy', true);

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                statApi.getLatestCameraStatListItems(data)
                    .then(
                        (response:any) => {
                            const items = response.getItemsList();
                            commit('setLatestCameraStatListItems', items);
                            commit('setIsStatListBusy', false);
                            resolve({
                                items: items
                            });
                        },
                        (error) => {
                            commit('setIsStatListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Получить элемент
    getStatListItem({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsStatListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            statApi.getStatListItem(filter)
                .then(
                    (response:any) => {
                        commit('setStatListItem', response.getItemsList()[0]);
                        commit('setIsStatListBusy', false);
                        resolve(response.getItemsList()[0]);
                    },
                    (error) => {
                        commit('setIsStatListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Список последних статистик
    getCurrentCameraStatListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsStatListBusy', true);

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                statApi.getCurrentCameraStatListItems(data)
                    .then(
                        (response:any) => {
                            commit('setCurrentCameraStatListItems', response);
                            commit('setIsStatListBusy', false);
                            resolve(response)
                        },
                        (error) => {
                            commit('setIsStatListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Параметры статистики камеры
    getCurrentCameraParams({ commit }, data: any) {

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                statApi.getCurrentCameraParams(data)
                    .then(
                        (response:any) => {
                            commit('setCurrentCameraParams', response);
                            resolve(response)
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            }
        );
    },

    // Сохранить параметры детекции камеры
    updateCameraParams({commit},data: any){

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                statApi.updateCameraParams(data)
                    .then(
                        (response:any) => {
                            commit('setUpdateCameraParams', response);
                            resolve(response)
                        },
                        (error) => {
                            reject(error);
                        }
                    );
            }
        );
    },

    // Отправка на ручную детекцию
    sendManualDetection({commit},data: any){

        // Устанавливаем событие загрузки
        commit('setIsStatListBusy', true);

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                statApi.sendManualDetection(data)
                    .then(
                        (response:any) => {
                            commit('setSendManualDetection', response);
                            commit('setIsStatListBusy', false);
                            resolve(response)
                        },
                        (error) => {
                            commit('setIsStatListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    }

};

const StatModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default StatModule;


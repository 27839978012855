<template>

  <div class="change-list">
    <q-card style="box-shadow: none">
      <div class="row">
        <div class="col-12">
          <div class="pre-header">
            <div>
              <h3 style="margin: 0;">Изменения: {{ getChangeListItems.length }}</h3>
            </div>
          </div>
          <q-table
              flat
              :columns="columns"
              :rows="getChangeListItems"
              row-key="id"
              :loading="isChangeListBusy"
              @request="onRequestChange"
          >
            <template v-slot:top>
              <q-input
                  dense
                  v-model="selectedId"
                  filled
                  label="ID"
              >
              </q-input>
              <q-select
                  v-model="selectedType"
                  :options="eventList"
                  filled
                  label="Item Type"
              ></q-select>
              <q-input
                  dense
                  v-model="selectedItemId"
                  filled
                  label="Item ID"
              ></q-input>
              <q-select
                  v-model="selectedEvent"
                  :options="typeList"
                  filled
                  emit-value
                  label="Event"
              ></q-select>
              <q-select
                  v-model="selectedAuthor"
                  :options="getUsersNameListItems"
                  filled
                  emit-value
                  map-options
                  label="Пользователь"
              ></q-select>
              <q-btn class="button" @click="clearSelect">Очистить</q-btn>
              <q-btn class="button" @click="search">Отфильтровать</q-btn>
            </template>
            <template v-slot:body-cell-id="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-type="props">
              <q-td class="item">
                <div class="options-block" v-if="props.row.item_type || props.row.item_id">
                  <p>{{ props.row.item_type }}</p>/<p>{{ props.row.item_id }}</p>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-events="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-authorDate="props">
              <q-td :props="props">
                <div class="options-block" v-if="props.row.author">
                  <p>{{ props.row.author }}</p>
                </div>
                <div class="options-block" v-if="props.row.date">
                  <p>{{ props.row.date }}</p>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-change="props">
              <q-td :props="props">
                <div v-for="item in props.row.changes" :key="item" class="options-block changes">
                  <span v-if="item.key" class="key">{{ item.key }} :</span>
                  <span v-if="item.old" class="old">{{ item.old }}</span>
                  <span v-if="item.old" class="text">update to</span>
                  <span v-if="item.new" class="new">{{ item.new }}</span>
                </div>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </q-card>
  </div>


</template>

<script lang="ts">

import { defineComponent } from 'vue'
import store from "@/store";

export default defineComponent({
  name: "ChangeList",

  data() {
    return {
      item: {
        id: '',
        item_type: '',
        item_id: '',
        events: '',
        author: '',
        date: '',
        newChanges: '',
        oldChanges: '',
        changesKey: [] as any,
      },
      columns: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          style: 'width: 50px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'type',
          label: 'item type / Item id',
          field: 'type',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'events',
          label: 'Event',
          field: 'events',
          align: 'left',
          style: 'width: 250px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'authorDate',
          label: 'Author / Date Time',
          field: 'authorDate',
          align: 'left',
          style: 'width: 250px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'change',
          label: 'Changes',
          field: 'changes',
          align: 'left',
          style: 'max-width: 500px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }
      ] as any,
      changePagination: {
        sortBy: 'change_id',
        descending: false,
        page: 1,
        rowsNumber: 0,
      },
      userPagination: {
        sortBy: 'user_id',
        descending: false,
        page: 1,
        rowsNumber: 0,
      },

      selectedId: null as any,
      selectedType: '',
      selectedItemId: null as any,
      selectedEvent: '',
      selectedAuthor: null as any,
      typeList: [] as any,
      eventList: [] as any
    }
  },

  computed: {

    //Формирование списка пользователей для таблицы
    getUsersListItems() {
      const items = store.getters.getUsersListItems
      let rows: any = []
      if (items !== undefined) {
        for (let user of items) {
          rows.push({
            id: user.getUserId(),
            label: user.getLastName() + ' ' + user.getFirstName()
          })
        }
      }
      return rows
    },

    //Формирование списка пользователей для фильтра
    getUsersNameListItems() {
      const items = store.getters.getUsersListItems

      let rows: any = []
      if (items !== undefined) {
        for (let user of items) {
          rows.push({
            value: user.getUserId(),
            label: user.getLastName() + ' ' + user.getFirstName()
          })
        }
      }
      return rows
    },

    isChangeListBusy() {
      return store.getters.getIsChangeListBusy
    },

    //Формирование списка изменений
    getChangeListItems() {
      const items = store.getters.getChangeListItems
      const users = store.getters.getUsersListItems
      let rows: any = []
      if (items !== undefined && users !== undefined) {
        for (let item of items) {
          let newChanges = item.getChangeNew() !== '' ? Object(Object.values(JSON.parse(item.getChangeNew()))) : ''

          let oldChanges = item.getChangeOld() !== '' ? Object(Object.values(JSON.parse(item.getChangeOld()))) : ''

          let changesKey = item.getChangeNew() !== '' ? Object(Object.keys(JSON.parse(item.getChangeNew()))) : ''

          let changeFormatted = [] as any

          for (let i = 0; i <= newChanges.length; i++) {
            changeFormatted.push({
              key: changesKey[i],
              old: oldChanges[i],
              new: newChanges[i]
            })
          }
          users.forEach((user: any) => {
            if(user.getUserId() === item.getChangeUserId()){
              rows.push({
                id: item.getChangeId(),
                item_type: item.getChangeObject(),
                item_id: item.getChangeObjectId(),
                events: item.getChangeEvent(),
                author: user.getFirstName() + ' ' + user.getLastName(),
                date: item.getChangeCreated(),
                changes: changeFormatted
              })
            }
          })
        }
      }

      return rows
    },

  },

  methods: {

    //Поиск
    search() {
      this.loadChangeListItems(true)
    },

    //Очистить поля поиска
    clearSelect() {
      this.selectedId = null
      this.selectedType = ''
      this.selectedItemId = null
      this.selectedEvent = ''
      this.selectedAuthor = ''
      this.loadChangeListItems(true)
    },

    //Фильтры для изменений
    getChangeFilters(){
      let filter = []
      if(this.selectedId > 0){
        filter.push({
          field_name: 'change_id',
          operand: '=',
          value: this.selectedId
        })
      }
      if(this.selectedType.length > 0){
        filter.push({
          field_name: 'change_object',
          operand: '=',
          value: this.selectedType
        })
      }
      if(this.selectedItemId > 0){
        filter.push({
          field_name: 'change_object_id',
          operand: '=',
          value: this.selectedItemId
        })
      }
      if(this.selectedEvent.length > 0){
        filter.push({
          field_name: 'change_event',
          operand: '=',
          value: this.selectedEvent
        })
      }
      if(this.selectedAuthor > 0){
        filter.push({
          field_name: 'change_user_id',
          operand: '=',
          value: this.selectedAuthor
        })
      }
      return filter
    },

    //Загрузить список изменений
    loadChangeListItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.changePagination.page = 1
        this.changePagination.rowsNumber = 0
      }
      store
          .dispatch('getChangeListItems', {
            filter: this.getChangeFilters(),
            sort: {
              name: this.changePagination.sortBy,
              exp: this.changePagination.descending ? 'ASC' : 'DESC',
            },
            pagination: {
              page: this.changePagination.page,
              pages: 0,
              cnt: this.changePagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getChangeListItems resolve', resolve)
              },
              (reject) => {
                console.log('getChangeListItems reject', reject)
              }
          )
    },

    //Загрузить список пользователей
    loadUsersListMenuItems() {
      store
          .dispatch('getUsersListMenuItems', {
            filter: {
              field_name: 'user_id',
              operand: '>',
              value: 0
            },
            sort: {
              name: this.userPagination.sortBy,
              exp: this.userPagination.descending ? 'ASC' : 'DESC',
            },
            pagination: {
              page: this.userPagination.page,
              pages: 0,
              cnt: this.userPagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getUsersListMenuItems resolve', resolve)
              },
              (reject) => {
                console.log('getUsersListMenuItems reject', reject)
              }
          )
    },

    changeTypes(){
      store
        .dispatch('changeTypes', {})
        .then(
          (resolve) => {
            console.log('changeTypes resolve', resolve)
            resolve.getItemList().forEach((res: any) => {
              this.typeList.push(res.getEventName())
            })
          },
          (reject) => {
            console.log('changeTypes reject', reject)
          }
        )
    },

    changeEvents(){
      store
        .dispatch('changeEvents', {})
        .then(
          (resolve) => {
            console.log('changeEvents resolve', resolve)
            resolve.getItemList().forEach((res: any) => {
              this.eventList.push(res.getObjectName())
            })
          },
          (reject) => {
            console.log('changeEvents reject', reject)
          }
        )
    },

    //Запрос к таблице с изменений
    onRequestChange(props: any) {
      console.log('onRequestChange props', props)
      this.changePagination = props.pagination
      this.loadChangeListItems(false)
    },

    //Инициализация функций загрузки данных
    loadData() {
      this.changeEvents()
      this.changeTypes()
      this.loadChangeListItems(true)
      this.loadUsersListMenuItems()
    },
  },

  mounted() {
    this.loadData()
  },
})

</script>

<style lang="scss">

.change-list {
  max-width: 1440px;
  margin: 0 auto;

  .pre-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
    }
    .q-btn{
      padding: 12px 24px 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }

  .q-table__top {
    padding: 20px !important;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    border-radius: 10px !important;
    .q-field{
      max-width: 215px;
      &__native{
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__control{
        border-radius: 5px;
      }
    }
    .q-field__native {
      min-width: 150px;
    }
    .q-field__inner {
      margin: 0 15px 0 0;
    }

    button{
      margin: 0 0 0 auto;
      height: 50px;
      width: 170px;
      border-radius: 10px;
      background: #3742fa !important;
      color: #fff;
      font-weight: 600;
      &:not(:last-child){
        width: fit-content;
      }
      &:last-child{
        margin: 0 0 0 20px;
      }
    }
  }

  .q-table__middle{
    margin: 35px 0 0 0;
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .q-td{
      vertical-align: middle;
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  .options-block {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 10px 0;
    }

    p, h3 {
      padding: 0 3px;
      margin: 0;
      font-size: 14px;
      line-height: 100%;
    }
  }

  .filter {
    margin: 0 0 0 25px;
    padding: 9px 15px;
    font-size: 15px;
  }

  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal {
    height: 56px;

  }

  .q-field--dense .q-field__label {
    font-size: 16px !important;
    top: 18px !important;
  }

  .q-table__card {
    box-shadow: unset !important;
  }
  .item{
    width: 300px !important;
  }
  .changes {
    display: flex;
    flex-wrap: wrap;
    span {
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
    .key{
      font-weight: 700;
      padding: 0 5px 0 0;
    }
    .old{
      color: #e70b08;
      padding: 0 5px 0 0;
    }
    .text{
      padding: 0 5px 0 0;
    }
    .new{
      color: #21BA45;
    }
  }

}

</style>
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"720px","max-width":"80vw","height":"280px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode("Добавить пользователя компании")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_form, {
        onSubmit: _ctx.saveCompanyUser,
        greedy: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_select, {
                modelValue: _ctx.selectedUser,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedUser) = $event)),
                options: _ctx.getUsersListItems,
                filled: "",
                "emit-value": "",
                "map-options": "",
                label: "Пользователи",
                "error-message": "К этой компании уже привязан выбранный пользователь",
                error: this.isTied
              }, null, 8, ["modelValue", "options", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              (_ctx.selectedUser !== null)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    color: "positive",
                    type: "submit"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Сохранить ")
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_q_btn, {
                    key: 1,
                    disable: "",
                    color: "positive"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Сохранить")
                    ]),
                    _: 1
                  })),
              _createVNode(_component_q_btn, {
                color: "primary",
                onClick: _ctx.closeCompanyUserLink
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Отмена ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSubmit"]),
      _createVNode(_component_q_separator)
    ]),
    _: 1
  }))
}
<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable no-unused-vars -->
<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const breadCrumbs = computed(() => {
  if (typeof route.meta.breadCrumb === 'function') {
    return route.meta.breadCrumb.call(this, route)
  }
  const currentPath = route.path.split('/')
  const breadCrumb: any = route.meta.breadCrumb
  if(!isNaN(+currentPath[currentPath.length - 1]) && breadCrumb && breadCrumb[breadCrumb.length - 1].text !== 'Главная'){
    breadCrumb[breadCrumb.length - 1].text = breadCrumb[breadCrumb.length - 1].text.replace(/[0-9№]/g, '') + ' №' + +currentPath[currentPath.length - 1]
  }
  return breadCrumb

})
</script>
<template>
  <q-breadcrumbs class="row q-px-md q-pt-md text-grey-10 q-breadcrumbs" active-color="grey-7">
    <template v-slot:separator>
      <q-icon size="1.5em" name="chevron_right" color="grey-5" />
    </template>
    <q-breadcrumbs-el
        v-for="item in breadCrumbs"
        :key="item"
        :to="item.to"
        :label="item.text"
        :icon="item.icon"
        replace
    />
  </q-breadcrumbs>
</template>

<style lang="scss">
.q-breadcrumbs {
  height: 38px;
  max-width: 1440px;
  margin: 40px auto 20px auto !important;
  padding: 0 !important;
  &__el{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    &-icon--with-label{
      margin-top: -3px;
    }
  }
}
</style>
/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v4.23.2
// source: company.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./company_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CompanyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CompanyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateCompanyRequest,
 *   !proto.proto.CreateCompanyResponse>}
 */
const methodDescriptor_CompanyService_CreateCompany = new grpc.web.MethodDescriptor(
  '/proto.CompanyService/CreateCompany',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateCompanyRequest,
  proto.proto.CreateCompanyResponse,
  /**
   * @param {!proto.proto.CreateCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CompanyServiceClient.prototype.createCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CompanyService/CreateCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_CreateCompany,
      callback);
};


/**
 * @param {!proto.proto.CreateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CompanyServicePromiseClient.prototype.createCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CompanyService/CreateCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_CreateCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateCompanyRequest,
 *   !proto.proto.UpdateCompanyResponse>}
 */
const methodDescriptor_CompanyService_UpdateCompany = new grpc.web.MethodDescriptor(
  '/proto.CompanyService/UpdateCompany',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateCompanyRequest,
  proto.proto.UpdateCompanyResponse,
  /**
   * @param {!proto.proto.UpdateCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CompanyServiceClient.prototype.updateCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CompanyService/UpdateCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_UpdateCompany,
      callback);
};


/**
 * @param {!proto.proto.UpdateCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CompanyServicePromiseClient.prototype.updateCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CompanyService/UpdateCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_UpdateCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteCompanyRequest,
 *   !proto.proto.DeleteCompanyResponse>}
 */
const methodDescriptor_CompanyService_DeleteCompany = new grpc.web.MethodDescriptor(
  '/proto.CompanyService/DeleteCompany',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteCompanyRequest,
  proto.proto.DeleteCompanyResponse,
  /**
   * @param {!proto.proto.DeleteCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CompanyServiceClient.prototype.deleteCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CompanyService/DeleteCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_DeleteCompany,
      callback);
};


/**
 * @param {!proto.proto.DeleteCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CompanyServicePromiseClient.prototype.deleteCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CompanyService/DeleteCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_DeleteCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCompanyRequest,
 *   !proto.proto.ListCompanyResponse>}
 */
const methodDescriptor_CompanyService_ListCompany = new grpc.web.MethodDescriptor(
  '/proto.CompanyService/ListCompany',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCompanyRequest,
  proto.proto.ListCompanyResponse,
  /**
   * @param {!proto.proto.ListCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CompanyServiceClient.prototype.listCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CompanyService/ListCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_ListCompany,
      callback);
};


/**
 * @param {!proto.proto.ListCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CompanyServicePromiseClient.prototype.listCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CompanyService/ListCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_ListCompany);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.LinkCompanyUserRequest,
 *   !proto.proto.LinkCompanyUserResponse>}
 */
const methodDescriptor_CompanyService_LinkCompanyUser = new grpc.web.MethodDescriptor(
  '/proto.CompanyService/LinkCompanyUser',
  grpc.web.MethodType.UNARY,
  proto.proto.LinkCompanyUserRequest,
  proto.proto.LinkCompanyUserResponse,
  /**
   * @param {!proto.proto.LinkCompanyUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.LinkCompanyUserResponse.deserializeBinary
);


/**
 * @param {!proto.proto.LinkCompanyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.LinkCompanyUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.LinkCompanyUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CompanyServiceClient.prototype.linkCompanyUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CompanyService/LinkCompanyUser',
      request,
      metadata || {},
      methodDescriptor_CompanyService_LinkCompanyUser,
      callback);
};


/**
 * @param {!proto.proto.LinkCompanyUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.LinkCompanyUserResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CompanyServicePromiseClient.prototype.linkCompanyUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CompanyService/LinkCompanyUser',
      request,
      metadata || {},
      methodDescriptor_CompanyService_LinkCompanyUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListUsersInCompanyRequest,
 *   !proto.proto.ListUsersInCompanyResponse>}
 */
const methodDescriptor_CompanyService_ListUsersInCompany = new grpc.web.MethodDescriptor(
  '/proto.CompanyService/ListUsersInCompany',
  grpc.web.MethodType.UNARY,
  proto.proto.ListUsersInCompanyRequest,
  proto.proto.ListUsersInCompanyResponse,
  /**
   * @param {!proto.proto.ListUsersInCompanyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListUsersInCompanyResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListUsersInCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListUsersInCompanyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListUsersInCompanyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CompanyServiceClient.prototype.listUsersInCompany =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CompanyService/ListUsersInCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_ListUsersInCompany,
      callback);
};


/**
 * @param {!proto.proto.ListUsersInCompanyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListUsersInCompanyResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CompanyServicePromiseClient.prototype.listUsersInCompany =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CompanyService/ListUsersInCompany',
      request,
      metadata || {},
      methodDescriptor_CompanyService_ListUsersInCompany);
};


module.exports = proto.proto;


<template>
  <div class="container">
    <div class="zone-statistic">
      <div class="zone-statistic__header">
        Инфо
      </div>
      <div class="zone-statistic__body">
        <ul>
          <li>
            <h3>Камера</h3>
            <p>{{ cameraName }}</p>
          </li>
          <li>
            <h3>Тип</h3>
            <div v-if="item.type">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#6BBE66" d="M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"/></g></svg>
              <p>Разрешенная</p>
            </div>
            <div v-else>
              <svg width="18px" height="18px" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88"><defs></defs><title>cancel</title><path style="fill:#c00706;fill-rule:evenodd;"  d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"/><path style="fill:#fff;fill-rule:evenodd;" d="M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"/></svg>
              <p>Запрешенная</p>
            </div>
          </li>
          <li>
            <h3>UUID</h3>
            <p>{{ item.uuid }}</p>
          </li>
          <li>
            <h3>Название</h3>
            <p>{{ item.name }}</p>
          </li>
          <li>
            <h3>Количество мест</h3>
            <p>{{ item.placeAllowed }}</p>
          </li>
          <li>
            <h3>Создано</h3>
            <p>{{ item.created }}</p>
          </li>
          <li>
            <h3>Точки</h3>
            <p>{{ item.points }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import store from "@/store";

export default defineComponent({
  name: 'zoneStatistic',

  data(){
    return{
      cameraId: '',
      cameraName: '',
      item: {
        id: 0,
        type: '',
        uuid: '',
        name: '',
        placeAllowed: '',
        created: '',
        points: '',
      },
      column:[
        {
          name: 'zone_type',
          label: '',
          field: 'zone_type',
          align: 'left',
          headerStyle: 'width: 0;padding: 0;',
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
        },
        {
          name: 'places',
          label: 'Разрешено',
          field: 'places',
          align: 'left',
        },
        {
          name: 'busy',
          label: 'Занято',
          field: 'busy',
          align: 'left',
        },
        {
          name: 'time',
          label: 'Время',
          field: 'time',
          align: 'left',
        }
      ] as any,
    }
  },

  methods: {

    //Загрузка данных зоны
    loadData() {
      store
          .dispatch('getZoneListItem', {
            filter: [
              {
                field_name: 'zone_id',
                operand: '=',
                value: this.$route.params.selectedZoneId,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getZoneId(),
                  type: resolve.getZoneIsAllowed(),
                  uuid: resolve.getZoneUuid(),
                  name: resolve.getZoneName(),
                  placeAllowed: resolve.getZonePlacesAllowed(),
                  created: resolve.getZoneCreated(),
                  points: resolve.getZoneSpot().split('},{').length,
                }
                this.cameraId = resolve.getZoneCameraId()
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрущка данных с камеры
    loadCameraData(){
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.$route.params.id,

              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.cameraName = resolve.getCameraName()
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    }

  },

  created() {
    this.loadData()
    this.loadCameraData()
  }
})

</script>

<style lang="scss">

.container{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.zone-statistic{
  border-radius: 5px;
  width: 100%;
  border: 1px solid #b3b3b3;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  &__header{
    font-size: 18px;
    padding: 18px 20px;
    border-bottom: 1px solid #b3b3b3;
    line-height: 100%;
    background: #f6f6f6;
  }
  &__body{
    padding: 20px;
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      li{
        display: flex;
        align-items: flex-start;
        list-style: none;
        &:not(:last-child),&:not(:first-child){
          padding: 12px 0;
        }
        h3{
          width: 150px;
          text-align: right;
          flex-shrink: 0;
        }
        h3,p{
          font-size: 16px;
          font-weight: 700;
          padding: 0;
          margin: 0;
          line-height: 100% ;
        }
        div{
          display: flex;
          align-items: center;
          padding: 0 0 0 15px;
          svg{
            margin: 0 5px 0 0;
          }
          p{
            padding: 0;
          }
        }
        p{
          font-weight: 400;
          padding: 0 0 0 15px;
          max-width: 310px;

        }
      }
    }
  }
}

</style>
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "service-add" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-pa-md" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Добавить сервисы ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (!_ctx.isServiceListBusy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_q_form, {
                onSubmit: _ctx.saveService,
                greedy: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_input, {
                        modelValue: _ctx.item.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event)),
                        label: "Название",
                        filled: "",
                        "lazy-rules": "",
                        rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_input, {
                        modelValue: _ctx.item.comment,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.comment) = $event)),
                        label: "Примечание",
                        filled: "",
                        "lazy-rules": "",
                        rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_card_actions, { style: {"display":"flex","justify-content":"space-between","padding":"16px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        color: "positive",
                        type: "submit"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_btn, {
                        color: "negative",
                        onClick: _ctx.closeServiceAdd
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Отмена")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
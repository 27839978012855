/* eslint-disable */
// source: zone.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.CreateZoneRequest', null, global);
goog.exportSymbol('proto.proto.CreateZoneResponse', null, global);
goog.exportSymbol('proto.proto.DeleteZoneRequest', null, global);
goog.exportSymbol('proto.proto.DeleteZoneResponse', null, global);
goog.exportSymbol('proto.proto.ItemZoneRequest', null, global);
goog.exportSymbol('proto.proto.ListZoneRequest', null, global);
goog.exportSymbol('proto.proto.ListZoneResponse', null, global);
goog.exportSymbol('proto.proto.UpdateZoneRequest', null, global);
goog.exportSymbol('proto.proto.UpdateZoneResponse', null, global);
goog.exportSymbol('proto.proto.Zone', null, global);
goog.exportSymbol('proto.proto.ZoneFilter', null, global);
goog.exportSymbol('proto.proto.ZoneFilterItem', null, global);
goog.exportSymbol('proto.proto.ZonePagination', null, global);
goog.exportSymbol('proto.proto.ZoneSort', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Zone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Zone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Zone.displayName = 'proto.proto.Zone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ZoneFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ZoneFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ZoneFilterItem.displayName = 'proto.proto.ZoneFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ZoneFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ZoneFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.ZoneFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ZoneFilter.displayName = 'proto.proto.ZoneFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ZoneSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ZoneSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ZoneSort.displayName = 'proto.proto.ZoneSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ZonePagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ZonePagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ZonePagination.displayName = 'proto.proto.ZonePagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListZoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListZoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListZoneRequest.displayName = 'proto.proto.ListZoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListZoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListZoneResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListZoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListZoneResponse.displayName = 'proto.proto.ListZoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemZoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemZoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemZoneRequest.displayName = 'proto.proto.ItemZoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateZoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateZoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateZoneRequest.displayName = 'proto.proto.CreateZoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateZoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateZoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateZoneResponse.displayName = 'proto.proto.CreateZoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateZoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateZoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateZoneRequest.displayName = 'proto.proto.UpdateZoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateZoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateZoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateZoneResponse.displayName = 'proto.proto.UpdateZoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteZoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteZoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteZoneRequest.displayName = 'proto.proto.DeleteZoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteZoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteZoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteZoneResponse.displayName = 'proto.proto.DeleteZoneResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Zone.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Zone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Zone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Zone.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneCameraId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    zoneName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    zoneIsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    zonePlacesAllowed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    zoneSpot: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zoneSpotPoints: jspb.Message.getFieldWithDefault(msg, 7, 0),
    zonePicture: jspb.Message.getFieldWithDefault(msg, 8, ""),
    zoneUuid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    zoneCreated: jspb.Message.getFieldWithDefault(msg, 10, ""),
    zoneSchema: jspb.Message.getFieldWithDefault(msg, 11, ""),
    zoneObjectPadding: jspb.Message.getFieldWithDefault(msg, 12, 0),
    zoneObjectMergeKoef: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    zoneAllowDetectVacant: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Zone}
 */
proto.proto.Zone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Zone;
  return proto.proto.Zone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Zone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Zone}
 */
proto.proto.Zone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneCameraId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZoneIsAllowed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZonePlacesAllowed(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneSpot(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneSpotPoints(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setZonePicture(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneUuid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneCreated(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneSchema(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneObjectPadding(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZoneObjectMergeKoef(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZoneAllowDetectVacant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Zone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Zone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Zone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Zone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getZoneCameraId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getZoneName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getZoneIsAllowed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getZonePlacesAllowed();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getZoneSpot();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZoneSpotPoints();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getZonePicture();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getZoneUuid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getZoneCreated();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getZoneSchema();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getZoneObjectPadding();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getZoneObjectMergeKoef();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getZoneAllowDetectVacant();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.Zone.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 zone_camera_id = 2;
 * @return {number}
 */
proto.proto.Zone.prototype.getZoneCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string zone_name = 3;
 * @return {string}
 */
proto.proto.Zone.prototype.getZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool zone_is_allowed = 4;
 * @return {boolean}
 */
proto.proto.Zone.prototype.getZoneIsAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneIsAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 zone_places_allowed = 5;
 * @return {number}
 */
proto.proto.Zone.prototype.getZonePlacesAllowed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZonePlacesAllowed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string zone_spot = 6;
 * @return {string}
 */
proto.proto.Zone.prototype.getZoneSpot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneSpot = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 zone_spot_points = 7;
 * @return {number}
 */
proto.proto.Zone.prototype.getZoneSpotPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneSpotPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string zone_picture = 8;
 * @return {string}
 */
proto.proto.Zone.prototype.getZonePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZonePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string zone_uuid = 9;
 * @return {string}
 */
proto.proto.Zone.prototype.getZoneUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string zone_created = 10;
 * @return {string}
 */
proto.proto.Zone.prototype.getZoneCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string zone_schema = 11;
 * @return {string}
 */
proto.proto.Zone.prototype.getZoneSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 zone_object_padding = 12;
 * @return {number}
 */
proto.proto.Zone.prototype.getZoneObjectPadding = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneObjectPadding = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional float zone_object_merge_koef = 13;
 * @return {number}
 */
proto.proto.Zone.prototype.getZoneObjectMergeKoef = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneObjectMergeKoef = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional bool zone_allow_detect_vacant = 14;
 * @return {boolean}
 */
proto.proto.Zone.prototype.getZoneAllowDetectVacant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Zone} returns this
 */
proto.proto.Zone.prototype.setZoneAllowDetectVacant = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ZoneFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ZoneFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ZoneFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZoneFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.ZoneFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ZoneFilterItem}
 */
proto.proto.ZoneFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ZoneFilterItem;
  return proto.proto.ZoneFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ZoneFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ZoneFilterItem}
 */
proto.proto.ZoneFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.ZoneFilter;
      reader.readMessage(value,proto.proto.ZoneFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ZoneFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ZoneFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ZoneFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZoneFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.ZoneFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.ZoneFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ZoneFilterItem} returns this
 */
proto.proto.ZoneFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.ZoneFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ZoneFilterItem} returns this
 */
proto.proto.ZoneFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.ZoneFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ZoneFilterItem} returns this
 */
proto.proto.ZoneFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ZoneFilter or = 4;
 * @return {?proto.proto.ZoneFilter}
 */
proto.proto.ZoneFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.ZoneFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.ZoneFilter, 4));
};


/**
 * @param {?proto.proto.ZoneFilter|undefined} value
 * @return {!proto.proto.ZoneFilterItem} returns this
*/
proto.proto.ZoneFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ZoneFilterItem} returns this
 */
proto.proto.ZoneFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ZoneFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ZoneFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ZoneFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ZoneFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ZoneFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZoneFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.ZoneFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ZoneFilter}
 */
proto.proto.ZoneFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ZoneFilter;
  return proto.proto.ZoneFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ZoneFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ZoneFilter}
 */
proto.proto.ZoneFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ZoneFilterItem;
      reader.readMessage(value,proto.proto.ZoneFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ZoneFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ZoneFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ZoneFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZoneFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.ZoneFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ZoneFilterItem items = 1;
 * @return {!Array<!proto.proto.ZoneFilterItem>}
 */
proto.proto.ZoneFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.ZoneFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.ZoneFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.ZoneFilterItem>} value
 * @return {!proto.proto.ZoneFilter} returns this
*/
proto.proto.ZoneFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.ZoneFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.ZoneFilterItem}
 */
proto.proto.ZoneFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.ZoneFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ZoneFilter} returns this
 */
proto.proto.ZoneFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ZoneSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ZoneSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ZoneSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZoneSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ZoneSort}
 */
proto.proto.ZoneSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ZoneSort;
  return proto.proto.ZoneSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ZoneSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ZoneSort}
 */
proto.proto.ZoneSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ZoneSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ZoneSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ZoneSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZoneSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.ZoneSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ZoneSort} returns this
 */
proto.proto.ZoneSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.ZoneSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ZoneSort} returns this
 */
proto.proto.ZoneSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ZonePagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ZonePagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ZonePagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZonePagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ZonePagination}
 */
proto.proto.ZonePagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ZonePagination;
  return proto.proto.ZonePagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ZonePagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ZonePagination}
 */
proto.proto.ZonePagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ZonePagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ZonePagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ZonePagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ZonePagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.ZonePagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ZonePagination} returns this
 */
proto.proto.ZonePagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.ZonePagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ZonePagination} returns this
 */
proto.proto.ZonePagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.ZonePagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ZonePagination} returns this
 */
proto.proto.ZonePagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.ZonePagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ZonePagination} returns this
 */
proto.proto.ZonePagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListZoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListZoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListZoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListZoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.ZoneFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.ZoneSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.ZonePagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListZoneRequest}
 */
proto.proto.ListZoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListZoneRequest;
  return proto.proto.ListZoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListZoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListZoneRequest}
 */
proto.proto.ListZoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.ZoneFilter;
      reader.readMessage(value,proto.proto.ZoneFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.ZoneSort;
      reader.readMessage(value,proto.proto.ZoneSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.ZonePagination;
      reader.readMessage(value,proto.proto.ZonePagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListZoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListZoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListZoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListZoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.ZoneFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ZoneSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.ZonePagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional ZoneFilter filter = 1;
 * @return {?proto.proto.ZoneFilter}
 */
proto.proto.ListZoneRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.ZoneFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.ZoneFilter, 1));
};


/**
 * @param {?proto.proto.ZoneFilter|undefined} value
 * @return {!proto.proto.ListZoneRequest} returns this
*/
proto.proto.ListZoneRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListZoneRequest} returns this
 */
proto.proto.ListZoneRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListZoneRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ZoneSort sort = 2;
 * @return {?proto.proto.ZoneSort}
 */
proto.proto.ListZoneRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.ZoneSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.ZoneSort, 2));
};


/**
 * @param {?proto.proto.ZoneSort|undefined} value
 * @return {!proto.proto.ListZoneRequest} returns this
*/
proto.proto.ListZoneRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListZoneRequest} returns this
 */
proto.proto.ListZoneRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListZoneRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ZonePagination pagination = 3;
 * @return {?proto.proto.ZonePagination}
 */
proto.proto.ListZoneRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.ZonePagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.ZonePagination, 3));
};


/**
 * @param {?proto.proto.ZonePagination|undefined} value
 * @return {!proto.proto.ListZoneRequest} returns this
*/
proto.proto.ListZoneRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListZoneRequest} returns this
 */
proto.proto.ListZoneRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListZoneRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListZoneResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListZoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListZoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListZoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListZoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.Zone.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListZoneRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListZoneResponse}
 */
proto.proto.ListZoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListZoneResponse;
  return proto.proto.ListZoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListZoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListZoneResponse}
 */
proto.proto.ListZoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Zone;
      reader.readMessage(value,proto.proto.Zone.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListZoneRequest;
      reader.readMessage(value,proto.proto.ListZoneRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListZoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListZoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListZoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListZoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Zone.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListZoneRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Zone items = 1;
 * @return {!Array<!proto.proto.Zone>}
 */
proto.proto.ListZoneResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.Zone>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Zone, 1));
};


/**
 * @param {!Array<!proto.proto.Zone>} value
 * @return {!proto.proto.ListZoneResponse} returns this
*/
proto.proto.ListZoneResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Zone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Zone}
 */
proto.proto.ListZoneResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Zone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListZoneResponse} returns this
 */
proto.proto.ListZoneResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListZoneRequest params = 2;
 * @return {?proto.proto.ListZoneRequest}
 */
proto.proto.ListZoneResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListZoneRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListZoneRequest, 2));
};


/**
 * @param {?proto.proto.ListZoneRequest|undefined} value
 * @return {!proto.proto.ListZoneResponse} returns this
*/
proto.proto.ListZoneResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListZoneResponse} returns this
 */
proto.proto.ListZoneResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListZoneResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemZoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemZoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemZoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemZoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemZoneRequest}
 */
proto.proto.ItemZoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemZoneRequest;
  return proto.proto.ItemZoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemZoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemZoneRequest}
 */
proto.proto.ItemZoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemZoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemZoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemZoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemZoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.ItemZoneRequest.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ItemZoneRequest} returns this
 */
proto.proto.ItemZoneRequest.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateZoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateZoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateZoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateZoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneCameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    zoneIsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    zonePlacesAllowed: jspb.Message.getFieldWithDefault(msg, 4, 0),
    zoneSpot: jspb.Message.getFieldWithDefault(msg, 5, ""),
    zonePicture: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zoneSchema: jspb.Message.getFieldWithDefault(msg, 7, ""),
    zoneObjectPadding: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateZoneRequest}
 */
proto.proto.CreateZoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateZoneRequest;
  return proto.proto.CreateZoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateZoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateZoneRequest}
 */
proto.proto.CreateZoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneCameraId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZoneIsAllowed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZonePlacesAllowed(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneSpot(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZonePicture(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneSchema(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneObjectPadding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateZoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateZoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateZoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateZoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getZoneName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getZoneIsAllowed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getZonePlacesAllowed();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getZoneSpot();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getZonePicture();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZoneSchema();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getZoneObjectPadding();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional int64 zone_camera_id = 1;
 * @return {number}
 */
proto.proto.CreateZoneRequest.prototype.getZoneCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZoneCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string zone_name = 2;
 * @return {string}
 */
proto.proto.CreateZoneRequest.prototype.getZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool zone_is_allowed = 3;
 * @return {boolean}
 */
proto.proto.CreateZoneRequest.prototype.getZoneIsAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZoneIsAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 zone_places_allowed = 4;
 * @return {number}
 */
proto.proto.CreateZoneRequest.prototype.getZonePlacesAllowed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZonePlacesAllowed = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string zone_spot = 5;
 * @return {string}
 */
proto.proto.CreateZoneRequest.prototype.getZoneSpot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZoneSpot = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string zone_picture = 6;
 * @return {string}
 */
proto.proto.CreateZoneRequest.prototype.getZonePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZonePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zone_schema = 7;
 * @return {string}
 */
proto.proto.CreateZoneRequest.prototype.getZoneSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZoneSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 zone_object_padding = 8;
 * @return {number}
 */
proto.proto.CreateZoneRequest.prototype.getZoneObjectPadding = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateZoneRequest} returns this
 */
proto.proto.CreateZoneRequest.prototype.setZoneObjectPadding = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateZoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateZoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateZoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateZoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateZoneResponse}
 */
proto.proto.CreateZoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateZoneResponse;
  return proto.proto.CreateZoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateZoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateZoneResponse}
 */
proto.proto.CreateZoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateZoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateZoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateZoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateZoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.CreateZoneResponse.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateZoneResponse} returns this
 */
proto.proto.CreateZoneResponse.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateZoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateZoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateZoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateZoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    zoneCameraId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    zoneName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    zoneIsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    zonePlacesAllowed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    zoneSpot: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zonePicture: jspb.Message.getFieldWithDefault(msg, 7, ""),
    zoneSchema: jspb.Message.getFieldWithDefault(msg, 8, ""),
    zoneObjectPadding: jspb.Message.getFieldWithDefault(msg, 9, 0),
    zoneObjectMergeKoef: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    zoneAllowDetectVacant: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateZoneRequest}
 */
proto.proto.UpdateZoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateZoneRequest;
  return proto.proto.UpdateZoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateZoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateZoneRequest}
 */
proto.proto.UpdateZoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneCameraId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZoneIsAllowed(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZonePlacesAllowed(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneSpot(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZonePicture(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setZoneSchema(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneObjectPadding(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setZoneObjectMergeKoef(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setZoneAllowDetectVacant(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateZoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateZoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateZoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateZoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getZoneCameraId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getZoneName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getZoneIsAllowed();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getZonePlacesAllowed();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getZoneSpot();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZonePicture();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getZoneSchema();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getZoneObjectPadding();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getZoneObjectMergeKoef();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getZoneAllowDetectVacant();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 zone_camera_id = 2;
 * @return {number}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string zone_name = 3;
 * @return {string}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool zone_is_allowed = 4;
 * @return {boolean}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneIsAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneIsAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 zone_places_allowed = 5;
 * @return {number}
 */
proto.proto.UpdateZoneRequest.prototype.getZonePlacesAllowed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZonePlacesAllowed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string zone_spot = 6;
 * @return {string}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneSpot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneSpot = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zone_picture = 7;
 * @return {string}
 */
proto.proto.UpdateZoneRequest.prototype.getZonePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZonePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string zone_schema = 8;
 * @return {string}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneSchema = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 zone_object_padding = 9;
 * @return {number}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneObjectPadding = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneObjectPadding = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional float zone_object_merge_koef = 10;
 * @return {number}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneObjectMergeKoef = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneObjectMergeKoef = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional bool zone_allow_detect_vacant = 11;
 * @return {boolean}
 */
proto.proto.UpdateZoneRequest.prototype.getZoneAllowDetectVacant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.UpdateZoneRequest} returns this
 */
proto.proto.UpdateZoneRequest.prototype.setZoneAllowDetectVacant = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateZoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateZoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateZoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateZoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateZoneResponse}
 */
proto.proto.UpdateZoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateZoneResponse;
  return proto.proto.UpdateZoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateZoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateZoneResponse}
 */
proto.proto.UpdateZoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateZoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateZoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateZoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateZoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.UpdateZoneResponse.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateZoneResponse} returns this
 */
proto.proto.UpdateZoneResponse.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteZoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteZoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteZoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteZoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteZoneRequest}
 */
proto.proto.DeleteZoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteZoneRequest;
  return proto.proto.DeleteZoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteZoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteZoneRequest}
 */
proto.proto.DeleteZoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteZoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteZoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteZoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteZoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.DeleteZoneRequest.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteZoneRequest} returns this
 */
proto.proto.DeleteZoneRequest.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteZoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteZoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteZoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteZoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    zoneId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteZoneResponse}
 */
proto.proto.DeleteZoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteZoneResponse;
  return proto.proto.DeleteZoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteZoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteZoneResponse}
 */
proto.proto.DeleteZoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setZoneId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteZoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteZoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteZoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteZoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZoneId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 zone_id = 1;
 * @return {number}
 */
proto.proto.DeleteZoneResponse.prototype.getZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteZoneResponse} returns this
 */
proto.proto.DeleteZoneResponse.prototype.setZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;

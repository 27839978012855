<template>
  <q-card
    style="width: 1024px; max-width: 80vw; height: 640px; max-height: 80vh"
    class="q-pa-md"
  >
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5"
          >Новый системный пользователь</q-item-label
        >
      </q-card-section>
    </q-item>

    <q-separator />

    <div v-if="!isUsersListBusy">
      <q-form
          @submit="saveUser"
          greedy
      >
        <q-card-section>
          <q-input
            v-model="item.last_name"
            label="Фамилия"
            filled
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="item.first_name"
            label="Имя"
            filled
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input v-model="item.middle_name" label="Отчество" filled />
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="item.email"
            label="E-mail"
            type="email"
            filled
            :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              ref="model"
              v-model="item.user_role"
              :options="getUserRolesListItems"
              option-label="label"
              option-value="value"
              filled
              map-options
              emit-value
              label="Роли"
              :rules="[
              (val) => (val && val.length > 0 ) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="item.username"
            label="Логин"
            filled
            lazy-rules
            :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="item.password"
            label="Пароль"
            type="password"
            filled
            :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="positive" type="submit"> Сохранить </q-btn>
          <q-btn color="primary" @click="closeUserAdd"> Отмена </q-btn>
        </q-card-actions>
      </q-form>
      <q-separator />
    </div>
  </q-card>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserAdd',

  data() {
    return {
      item: {
        last_name: '',
        first_name: '',
        middle_name: '',
        email: '',
        user_role: '',
        username: '',
        password: '',
      },
      userRole: localStorage.getItem('userRole')
    }
  },

  computed: {

    isUsersListBusy() {
      return store.getters.getIsUsersListBusy
    },

    //Формирование списка пользовательских ролей
    getUserRolesListItems(){
      const roles = store.getters.getUserRolesListItems

      let rows: any = []
      if (roles !== undefined) {
        for (let role of roles) {
          rows.push({
            label: role.array[0],
            value: role.array[0]
          })
        }
      }
      if(this.userRole !== 'super'){
        rows.forEach((row: any, idx: number) => {
          row.label === 'super' ? rows.splice(idx,1) : ''
        })
      } 
      return rows
    }
  },

  methods: {

    //Сохранить пользователя
    saveUser() {
      let data = {
        last_name: this.item.last_name,
        first_name: this.item.first_name,
        middle_name: this.item.middle_name,
        email: this.item.email,
        user_role: this.item.user_role,
        username: this.item.username,
        password: this.item.password,
      }

      store.dispatch('createUsersItem', data).then(
        (resolve) => {
          console.log('resolve', resolve)
          this.$emit('afterAction')
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
      return true
    },

    //Закрыть добавление пользователя
    closeUserAdd() {
      this.$emit('hideModal')
    },

    //Загрузить список пользовательских ролей
    loadUsersRoleListItems(){
      store
          .dispatch('getUserRolesListItems',{})
          .then(
              (resolve) => {
                console.log('getUserRolesListItems resolve', resolve)
              },
              (reject) => {
                console.log('getUserRolesListItems reject', reject)
              }
          )
    },

  },

  created() {
    this.loadUsersRoleListItems()
  },
})
</script>

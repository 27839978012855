import { createApp } from 'vue'
import Quasar from 'quasar/src/vue-plugin.js';
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import vennInit from 'highcharts/modules/venn'
import variablepieInit from 'highcharts/modules/variable-pie'
import accessibility from 'highcharts/modules/accessibility'
import heatmap from 'highcharts/modules/heatmap'
import exporting from 'highcharts/modules/exporting'
import barInit from 'highcharts/modules/data'
import UUID from 'vue-uuid'
import quasarUserOptions from './quasar-user-options'
import LoginApp from './LoginApp.vue'
import App from './App.vue'
import moduleStore from './store/index'
import router from '@/router'

accessibility(Highcharts)
exporting(Highcharts)
vennInit(Highcharts)
variablepieInit(Highcharts)
barInit(Highcharts)
heatmap(Highcharts)



// Пробуем авторизоваться
const token = localStorage.getItem('accessToken')
const appType = token ? App : LoginApp

const app = createApp(appType)

app
  .use(HighchartsVue as any)
  .use(Quasar, quasarUserOptions)
  .use(router())
  .use(moduleStore)
  .use(UUID)
  .mount('#app')

import ChangeList from '../views/change/ChangeList.vue'

const routes = [
    {
        path: '/changes',
        name: 'ChangeList',
        component: ChangeList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Изменения', to: { name: 'ChangeList' }}
            ]
        }
    }
]

export default {
    routes
}
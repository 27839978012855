import CameraList from '../views/cameras/CameraList.vue'
import CameraWatch from '../views/cameras/CameraWatch.vue';
import CameraStatistics from '../views/cameras/CameraStatistics.vue';
import CameraDetection from "../views/cameras/CameraDetection.vue";


const routes = [
    {
        path: '/cameras',
        name: 'CameraList',
        component: CameraList,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Камеры', to: { name: 'CameraList' }}
            ]
        }
    },
    {
        path: '/cameras/watch/:id',
        name: 'CameraWatch',
        component: CameraWatch,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Камеры', to: { name: 'CameraList' }},
                { text: 'Камера' }
            ]
        }
    },
    {
        path: '/cameras/watch/statistic/:id',
        name: 'CameraStatistic',
        component: CameraStatistics,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Камеры', to: { name: 'CameraList' }},
                { text: 'Камера', to: { name: 'CameraWatch'}},
                { text: 'Статистика' }
            ]
        }
    },
    {
        path: '/cameras/watch/detections/:id',
        name: 'CameraDetection',
        component: CameraDetection,
        meta: {
            breadCrumb: [
                { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
                { text: 'Камеры', to: { name: 'CameraList' }},
                { text: 'Камера', to: { name: 'CameraWatch'}},
                { text: 'Детекция' }
            ]
        }
    }
]

export default {
    routes
}
<template>

  <q-card style="width: 1024px; max-width: 80vw" class="q-pa-md">
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5">
          <p>Удаление модели</p>
          <p>«{{ item.name }}»</p>
        </q-item-label>
      </q-card-section>
    </q-item>

    <q-separator />

    <div v-if="!isModelListBusy">
      <q-card-section>
        <p>Вы действительно хотите удалить камеру ?</p>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn color="negative" @click="actualDelete"> Удалить </q-btn>
        <q-btn color="primary" @click="closeDelete"> Отмена </q-btn>
      </q-card-actions>
    </div>
  </q-card>

</template>

<script lang="ts">

import store from "@/store";
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModelDelete',

  props: {
    modelId: {
      type: Number,
      default: 0,
    }
  },

  data() {
    return {
      item: {
        id: 0,
        name: ''
      },
    }
  },

  computed: {
    isModelListBusy() {
      return store.getters.getIsModelListBusy
    },
  },

  methods: {

    //Удаление модели
    actualDelete() {
      store.dispatch('deleteModelListItem', {id: this.modelId}).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )

    },

    //Закрыть модалку
    closeDelete() {
      this.$emit('hideModal')
    },

    //Загрузка данных о моделе
    loadData() {
      store
          .dispatch('getModelListItem', {
            filter: [
              {
                field_name: 'model_id',
                operand: '=',
                value: this.modelId,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getModelId(),
                  name: resolve.getModelName(),
                }
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },
  },

  created() {
    this.loadData()
  },


})
</script>

<style lang="scss">

</style>
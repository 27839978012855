import {GetterTree, MutationTree, ActionTree} from 'vuex';
import {UsersApi} from '../services/api/UsersApi';
import Loading from 'quasar/src/plugins/Loading.js';

// Создаем объект
const usersApi = new UsersApi();

// Создаем состояние
class State {
    isUsersListBusy: boolean = false;
    usersListItems: unknown [];
    usersListItem: unknown;
    userCompaniesListItems: unknown [];
    isUserCompaniesListBusy: boolean = false;
    userRolesListItems: unknown [];
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isUsersListBusy(state): boolean {
        return state.isUsersListBusy;
    },

    getUsersListItems(state): unknown[] {
        return state.usersListItems;
    },

    getUsersListItem(state): unknown {
        return state.usersListItem;
    },

    getUserCompaniesListItems(state): unknown[] {
        return state.userCompaniesListItems;
    },

    getUserRolesListItems(state): unknown[] {
        return state.userRolesListItems
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsUsersListBusy(state, payload: boolean) {
        state.isUsersListBusy = payload
        if (payload) {
            Loading.show()
        } else {
            Loading.hide()
        }
    },

    setUsersListItems(state, payload: unknown[]) {
        state.usersListItems = payload
    },

    setUsersListItem(state, payload: unknown) {
        state.usersListItem = payload
    },

    setIsUserCompaniesListBusy(state, payload: boolean) {
        state.isUserCompaniesListBusy = payload
        if (payload) {
            Loading.show()
        } else {
            Loading.hide()
        }
    },

    setUserCompaniesListItem(state, payload: unknown[]) {
        state.userCompaniesListItems = payload
    },

    setUserRolesListItems(state, payload: unknown[]){
        state.userRolesListItems = payload
    }

}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setUsersListItem({commit}, data: any) {
        commit('setUsersListItem', data);
    },

    // Список элементов
    getUsersListItems({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsUsersListBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                usersApi.getUsersListItems(filter, sort, pagination)
                    .then(
                        (message: any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setUsersListItems', items);
                            commit('setIsUsersListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setUsersListItems', []);
                            commit('setIsUsersListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Создать элемент
    createUsersItem({commit}, data) {

        // Устанавливаем событие загрузки
        commit('setIsUsersListBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            usersApi.createUsersItem(data)
                .then(
                    (response: any) => {
                        commit('setIsUsersListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsUsersListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Редактировать элемент
    updateUsersItem({commit}, data) {

        // Устанавливаем событие загрузки
        commit('setIsUsersListBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            usersApi.updateUsersItem(data)
                .then(
                    (response: any) => {
                        commit('setIsUsersListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsUsersListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteUsersListItem({commit}, data) {

        // Устанавливаем событие загрузки
        commit('setIsUsersListBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            usersApi.deleteUsersItem(data)
                .then(
                    (response: any) => {
                        commit('setIsUsersListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsUsersListBusy', false);
                        reject(error);
                    }
                );
        });
    },
    // Получить элемент
    getUsersListItem({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsUsersListBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            usersApi.getUsersListItem(filter)
                .then(
                    (response: any) => {
                        commit('setUsersListItem', response.getItemsList()[0]);
                        commit('setIsUsersListBusy', false);
                        resolve(response.getItemsList()[0]);
                    },
                    (error) => {
                        commit('setIsUsersListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    getUserCompaniesListItems({commit}, data: any) {

        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                usersApi.getUserCompaniesListItems(data, sort, pagination)
                    .then(
                        (message: any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setUserCompaniesListItem', items)
                            commit('setIsUserCompaniesListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setUserCompaniesListItem', [])
                            commit('setIsUserCompaniesListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    getUserRolesListItems({commit}){

        return new Promise((resolve, reject) => {

            usersApi.getUserRolesListItems()
                .then(
                    (message: any) => {
                        const items = message.getItemList();
                        commit('setUserRolesListItems', items)
                        resolve(message)
                    },
                    (error) => {
                        commit('setUserRolesListItems', [])
                        reject(error);
                    }
                )
        })

    },

    getUsersListMenuItems({commit}, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsUsersListBusy', true);

        const filter: any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort: any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination: any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                usersApi.getUserListMenu(filter, sort, pagination)
                    .then(
                        (message: any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setUsersListItems', items);
                            commit('setIsUsersListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setUsersListItems', []);
                            commit('setIsUsersListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

};

// Создаем модуль
const UserListModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default UserListModule;
import {
    StatServicePromiseClient
} from "../../proto/pb/stat_grpc_web_pb";
import {
    ListStatRequest,
    Stat,
    StatFilter,
    StatFilterItem,
    StatPagination,
    StatSort,
    LatestCameraStatRequest,
    CurrentStatRequest,
    DtParametersSet,
    CameraParamsRequest,
    UpdateDetectParamsRequest,
    DetectionBatchRequest,
    DetectionBatchParamsItem
} from "../../proto/pb/stat_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';


// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class StatApi {
    items: Array<Stat> = [];
    client: StatServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new StatServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new StatFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new StatFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new StatSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'stat_shot_created';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new StatPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getStatListItems(filter: any, sort: any, pagination: any){

        const request = new ListStatRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));
        request.setMycompanyidlist('');
        request.setInmycompanyonly(false);

        return this.client.listStat(request, getAuthToken())
    }

    // Список последних статистик
    getLatestCameraStatListItems(data: any){

        const request = new LatestCameraStatRequest();
        request.setCameraId(data.id);

        return this.client.latestCameraStat(request, getAuthToken())

    }

    // Получение элемента
    getStatListItem(filter: any){

        const request = new ListStatRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort({}));
        request.setPagination(this.createPagination({}));

        return this.client.listStat(request, getAuthToken())
    }

    // Подробная статистика с камеры
    getCurrentCameraStatListItems(data: any){

        const request = new CurrentStatRequest();
        request.setCameraId(data.item.camera_id);
        request.setLocalImage(data.item.local_image);
        data.item.params_set.forEach((unit: any) => {
            const object = new DtParametersSet()
            object.setDtParamsId(unit.dt_params_id).setDtCameraId(unit.dt_camera_id).setDtMethod(unit.dt_method).setDtSegment(JSON.stringify(unit.dt_segment)).setDtConfidence(unit.dt_confidence).setDtGarbage(unit.dt_garbage).setDtAxis(unit.dt_axis)
            request.addParamsSet(object);
        })

        return this.client.currentStat(request, getAuthToken())
    }

    // Параметры статистики с камеры
    getCurrentCameraParams(data: any){
        const request = new CameraParamsRequest();
        request.setCameraId(data.item)

        return this.client.getCameraParams(request, getAuthToken())
    }

    // Сохранить настройки параметров детекции камеры
    updateCameraParams(data: any){

        const request = new UpdateDetectParamsRequest();
        request.setDtCameraId(data.dt_camera_id);
        data.params_set.forEach((unit: any) => {
            const object = new DtParametersSet()
            object.setDtCameraId(unit.dt_camera_id).setDtMethod(unit.dt_method).setDtSegment(unit.dt_segment).setDtConfidence(unit.dt_confidence).setDtGarbage(unit.dt_garbage).setDtAxis(unit.dt_axis)
            request.addDtParamsSet(object);
        })

        return this.client.updateCameraParams(request, getAuthToken())
    }

    sendManualDetection(data: any){
        const request = new DetectionBatchRequest()

        data.detection.forEach((unit: any) => {
            const object = new DetectionBatchParamsItem()
            object.setBdParams(unit.params).setBdLocalImage(unit.picture)
            request.addDtBatch(object);
        })

        return this.client.batchDetection(request, getAuthToken())

    }

}

import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { ModelApi } from '../services/api/ModelApi';
import Loading from 'quasar/src/plugins/Loading.js';

// Создаем объект
const modelApi = new ModelApi();

// Создаем состояние
class State {
    isModelListBusy: boolean = false;
    ModelListItems: unknown [];
    ModelListItem: unknown;
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
    isModelListBusy(state): boolean {
        return state.isModelListBusy;
    },

    getModelListItems(state): unknown[] {
        return state.ModelListItems;
    },

    getModelListItem(state): unknown {
        return state.ModelListItem;
    }
}

// Создаем мутации
const mutations = <MutationTree<State>>{
    setIsModelListBusy(state, payload: boolean) {
        state.isModelListBusy = payload
        if(payload){
            Loading.show()
        }else{
            Loading.hide()
        }
    },

    setModelListItems(state, payload: unknown[]) {
        state.ModelListItems = payload
    },

    setModelListItem(state, payload: unknown) {
        state.ModelListItem = payload
    }
}

// Создаем действия
const actions = <ActionTree<State, any>>{
    // Устанавливаем элемент
    setModelListItem({ commit }, data: any) {
        commit('setModelListItem', data);
    },

    // Список элементов
    getModelListItems({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsModelListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];
        const sort:any = data !== undefined && data.sort !== undefined ? data.sort : {};
        const pagination:any = data !== undefined && data.pagination !== undefined ? data.pagination : {};

        return new Promise((resolve, reject) => {

                // Получаем список элементов
                modelApi.getModelListItems(filter, sort, pagination)
                    .then(
                        (message:any) => {
                            const items = message.getItemsList();
                            const sort = message.getParams().getSort();
                            const pagination = message.getParams().getPagination();
                            commit('setModelListItems', items);
                            commit('setIsModelListBusy', false);
                            resolve({
                                items: items,
                                sort: sort,
                                pagination: pagination
                            });
                        },
                        (error) => {
                            commit('setModelListItems', []);
                            commit('setIsModelListBusy', false);
                            reject(error);
                        }
                    );
            }
        );
    },

    // Создать элемент
    createModelItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsModelListBusy', true);

        return new Promise((resolve, reject) => {

            // Создаем элемент
            modelApi.createModelItem(data)
                .then(
                    (response:any) => {
                        commit('setIsModelListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsModelListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Редактировать элемент
    updateModelItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsModelListBusy', true);

        return new Promise((resolve, reject) => {

            // Обновляем элемент
            modelApi.updateModelItem(data)
                .then(
                    (response:any) => {
                        commit('setIsModelListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsModelListBusy', false);
                        reject(error);
                    }
                );
        });
    },

    // Удалить элемент
    deleteModelListItem({ commit }, data) {

        // Устанавливаем событие загрузки
        commit('setIsModelListBusy', true);

        return new Promise((resolve, reject) => {

            // Удаляем элемент
            modelApi.deleteModelItem(data)
                .then(
                    (response:any) => {
                        commit('setIsModelListBusy', false);
                        resolve(response);
                    },
                    (error) => {
                        commit('setIsModelListBusy', false);
                        reject(error);
                    }
                );
        });
    },
    // Получить элемент
    getModelListItem({ commit }, data: any) {

        // Устанавливаем событие загрузки
        commit('setIsModelListBusy', true);

        const filter:any = data !== undefined && data.filter !== undefined ? data.filter : [];

        return new Promise((resolve, reject) => {

            // Получаем элемент
            modelApi.getModelListItem(filter)
                .then(
                    (response:any) => {
                        commit('setModelListItem', response.getItemsList()[0]);
                        commit('setIsModelListBusy', false);
                        resolve(response.getItemsList()[0]);
                    },
                    (error) => {
                        commit('setIsModelListBusy', false);
                        reject(error);
                    }
                );
        });
    },
};

const ModelModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters
};

export default ModelModule;

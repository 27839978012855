/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.4
// source: camera.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./camera_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CameraServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.CameraServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateCameraRequest,
 *   !proto.proto.CreateCameraResponse>}
 */
const methodDescriptor_CameraService_CreateCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/CreateCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateCameraRequest,
  proto.proto.CreateCameraResponse,
  /**
   * @param {!proto.proto.CreateCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.createCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/CreateCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_CreateCamera,
      callback);
};


/**
 * @param {!proto.proto.CreateCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.createCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/CreateCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_CreateCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ItemCameraRequest,
 *   !proto.proto.CameraStatAndImgSyncResponse>}
 */
const methodDescriptor_CameraService_CameraStatAndImgSync = new grpc.web.MethodDescriptor(
  '/proto.CameraService/CameraStatAndImgSync',
  grpc.web.MethodType.UNARY,
  proto.proto.ItemCameraRequest,
  proto.proto.CameraStatAndImgSyncResponse,
  /**
   * @param {!proto.proto.ItemCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CameraStatAndImgSyncResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CameraStatAndImgSyncResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CameraStatAndImgSyncResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.cameraStatAndImgSync =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/CameraStatAndImgSync',
      request,
      metadata || {},
      methodDescriptor_CameraService_CameraStatAndImgSync,
      callback);
};


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CameraStatAndImgSyncResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.cameraStatAndImgSync =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/CameraStatAndImgSync',
      request,
      metadata || {},
      methodDescriptor_CameraService_CameraStatAndImgSync);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CamerasTypesRequest,
 *   !proto.proto.CamerasTypesResponse>}
 */
const methodDescriptor_CameraService_GetCamerasTypes = new grpc.web.MethodDescriptor(
  '/proto.CameraService/GetCamerasTypes',
  grpc.web.MethodType.UNARY,
  proto.proto.CamerasTypesRequest,
  proto.proto.CamerasTypesResponse,
  /**
   * @param {!proto.proto.CamerasTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CamerasTypesResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CamerasTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CamerasTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CamerasTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.getCamerasTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/GetCamerasTypes',
      request,
      metadata || {},
      methodDescriptor_CameraService_GetCamerasTypes,
      callback);
};


/**
 * @param {!proto.proto.CamerasTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CamerasTypesResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.getCamerasTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/GetCamerasTypes',
      request,
      metadata || {},
      methodDescriptor_CameraService_GetCamerasTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateCameraRequest,
 *   !proto.proto.UpdateCameraResponse>}
 */
const methodDescriptor_CameraService_UpdateCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/UpdateCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateCameraRequest,
  proto.proto.UpdateCameraResponse,
  /**
   * @param {!proto.proto.UpdateCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.updateCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/UpdateCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_UpdateCamera,
      callback);
};


/**
 * @param {!proto.proto.UpdateCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.updateCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/UpdateCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_UpdateCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteCameraRequest,
 *   !proto.proto.DeleteCameraResponse>}
 */
const methodDescriptor_CameraService_DeleteCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/DeleteCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteCameraRequest,
  proto.proto.DeleteCameraResponse,
  /**
   * @param {!proto.proto.DeleteCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.deleteCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/DeleteCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_DeleteCamera,
      callback);
};


/**
 * @param {!proto.proto.DeleteCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.deleteCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/DeleteCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_DeleteCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListCameraRequest,
 *   !proto.proto.ListCameraResponse>}
 */
const methodDescriptor_CameraService_ListCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/ListCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.ListCameraRequest,
  proto.proto.ListCameraResponse,
  /**
   * @param {!proto.proto.ListCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.listCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/ListCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_ListCamera,
      callback);
};


/**
 * @param {!proto.proto.ListCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.listCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/ListCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_ListCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListNearCameraRequest,
 *   !proto.proto.ListCameraResponse>}
 */
const methodDescriptor_CameraService_ListNearCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/ListNearCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.ListNearCameraRequest,
  proto.proto.ListCameraResponse,
  /**
   * @param {!proto.proto.ListNearCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListNearCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.listNearCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/ListNearCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_ListNearCamera,
      callback);
};


/**
 * @param {!proto.proto.ListNearCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.listNearCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/ListNearCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_ListNearCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ItemCameraRequest,
 *   !proto.proto.ItemCameraResponse>}
 */
const methodDescriptor_CameraService_ItemCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/ItemCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.ItemCameraRequest,
  proto.proto.ItemCameraResponse,
  /**
   * @param {!proto.proto.ItemCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ItemCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ItemCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ItemCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.itemCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/ItemCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_ItemCamera,
      callback);
};


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ItemCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.itemCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/ItemCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_ItemCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ItemCameraRequest,
 *   !proto.proto.ItemCameraResponse>}
 */
const methodDescriptor_CameraService_PictureUpdateCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/PictureUpdateCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.ItemCameraRequest,
  proto.proto.ItemCameraResponse,
  /**
   * @param {!proto.proto.ItemCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ItemCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ItemCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ItemCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.pictureUpdateCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/PictureUpdateCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_PictureUpdateCamera,
      callback);
};


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ItemCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.pictureUpdateCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/PictureUpdateCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_PictureUpdateCamera);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ItemCameraRequest,
 *   !proto.proto.PingCameraResponse>}
 */
const methodDescriptor_CameraService_PingCamera = new grpc.web.MethodDescriptor(
  '/proto.CameraService/PingCamera',
  grpc.web.MethodType.UNARY,
  proto.proto.ItemCameraRequest,
  proto.proto.PingCameraResponse,
  /**
   * @param {!proto.proto.ItemCameraRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.PingCameraResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.PingCameraResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.PingCameraResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.CameraServiceClient.prototype.pingCamera =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.CameraService/PingCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_PingCamera,
      callback);
};


/**
 * @param {!proto.proto.ItemCameraRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.PingCameraResponse>}
 *     Promise that resolves to the response
 */
proto.proto.CameraServicePromiseClient.prototype.pingCamera =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.CameraService/PingCamera',
      request,
      metadata || {},
      methodDescriptor_CameraService_PingCamera);
};


module.exports = proto.proto;


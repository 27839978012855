import {
    AuthServiceClient
} from "../../proto/pb/auth_grpc_web_pb.js";

import {
    LoginRequest,
    LoginResponse,
    RefreshRequest
} from "../../proto/pb/auth_pb";

  export class AuthApi {
    client: AuthServiceClient;
    metadata: any;
    refreshRequest: Promise<LoginResponse> | undefined;
  
    constructor() {
      const host:string = (window as any).VUE_APP_API_URL ?? '';
      this.client = new AuthServiceClient(host, null, null);
      this.metadata = {};
    }

    // Проверка имени и пароля системного пользователя
    checkUserCreds(data: any){
      const request = new LoginRequest();
      request.setUsername(data.login);
      request.setPassword(data.passw);

      return new Promise((resolve, reject) => {
        this.client.login(request, this.metadata, (err, message) => {
          if (err) {
            reject(err);
          } else {
            resolve(message.toObject());
          }
        });
      });
    }

    // Отправка refresh токена
    checkRefreshToken(data: any){
      const request = new RefreshRequest();
      request.setRefreshToken(data.refreshToken)

      return new Promise((resolve,reject) => {
        this.client.refresh(request, this.metadata, (err,message) => {
          if(err){
            reject(err);
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            location.reload()
          } else{
            resolve(message.toObject());
            localStorage.setItem('refreshToken', message.getRefreshToken());
            localStorage.setItem('accessToken', message.getAccessToken());
          }
        })
      })

    }
}
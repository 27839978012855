<template>

  <q-card
      style="width: 1024px; max-width: 80vw; height: 640px; max-height: 80vh"
      class="q-pa-md"
  >
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5"
        >Редактирование данных камеры:
          {{ item.name }}</q-item-label
        >
      </q-card-section>
    </q-item>
    <div>
      <q-form
          @submit="saveCamera"
          greedy
      >
        <q-card-section>
          <q-input
              v-model="item.name"
              label="Название"
              filled
              lazy-rules
              :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              v-model="item.model_id"
              :options="getModelListItems"
              option-label="label"
              option-value="id"
              filled
              map-options
              emit-value
              label="Модели"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.login"
              label="Логин"
              filled
              lazy-rules
              :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              ref="password"
              v-model="item.password"
              label="Пароль"
              type="password"
              filled
          />
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.survey_period"
              label="Период"
              hint="Период необходимо указываеть в миллисекунды"
              filled
              type="number"
              lazy-rules
              :rules="[
              (val) => (val && val >= 15000) || 'Поле должно быть заполнено и минимальное значение должно составлять 15000 мс',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.stat_period"
              label="Период обновления статистики"
              hint="Период обновления статистики необходимо указываеть в миллисекунды"
              filled
              type="number"
              lazy-rules
              :rules="[
              (val) => (val && val >= 300000) || 'Поле должно быть заполнено и минимальное значение должно составлять 300000 мс',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              v-model="item.type"
              :options="typeList"
              option-label="label"
              option-value="value"
              filled
              map-options
              emit-value
              label="Тип подключения к камере"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.ip"
              label="IP"
              filled
              lazy-rules
              :rules="[
              (val) => (val.length > 0 || item.mac.length > 0 || item.url.length > 0) || 'Одно из полей IP/MAC/URL должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.mac"
              label="MAC-адрес"

              filled
              lazy-rules
              :rules="[
              (val) => (val.length > 0 || item.ip.length > 0 || item.url.length > 0) || 'Одно из полей IP/MAC/URL должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-input
              v-model="item.url"
              label="URL"
              filled
              lazy-rules
              :rules="[
              (val) => (val.length > 0 || item.mac.length > 0 || item.ip.length > 0) || 'Одно из полей IP/MAC/URL должно быть заполнено',
            ]"
          />
        </q-card-section>
        <q-card-section>
          <q-select
              v-model="item.service_id"
              :options="getServiceListItems"
              option-label="label"
              option-value="id"
              filled
              map-options
              emit-value
              label="Сервисы"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <q-card-section>
          <q-select
              v-model="item.company_id"
              :options="getCompanyListItems"
              option-label="label"
              option-value="id"
              filled
              map-options
              emit-value
              label="Компании"
              :rules="[
              (val) => (val) || 'Поле должно быть заполнено',
            ]"
          ></q-select>
        </q-card-section>
        <div id="map"></div>
        <q-card-actions >
          <div class="navigation">
            <q-btn color="negative" @click="deleteCamera"> Удалить </q-btn>
            <q-btn color="positive" type="submit"> Сохранить </q-btn>
            <q-btn color="primary" @click="closeCameraEdit"> Отмена </q-btn>
          </div>
        </q-card-actions>
      </q-form>
    </div>
  </q-card>
  <q-dialog v-model="isActiveSecondModal">
    <CameraDelete
        v-if="isActiveModalDelete"
        :cameraId="item.id"
        :cameraName="item.name"
        @afterAction="this.$emit('afterAction')"
        @hideModal="hideModal"
    />
  </q-dialog>


</template>

<script lang="js">

import store from '@/store'
import { loadYmap } from "vue-yandex-maps"
import { defineComponent } from 'vue'
import CameraDelete from './CameraDelete.vue'

export default defineComponent({
  name: 'CameraEdit',

  components: {
    CameraDelete
    //DataLoading
  },

  props: {
    cameraId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {},
      isActiveSecondModal: false,
      isActiveModalDelete: false,
      typeList: []
    }
  },

  computed: {

    //Формирование списка сервисов
    getServiceListItems() {
      const items = store.getters.getServiceListItems
      let rows = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getServiceId(),
            label: item.getServiceName()
          })
        }
      }
      return rows
    },

    //Формирование списка моделей
    getModelListItems() {
      const items = store.getters.getModelListItems
      let rows = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            id: model.getModelId(),
            label: model.getModelName()
          })
        }
      }
      return rows
    },

    //Формирование списка компаний
    getCompanyListItems() {
      const items = store.getters.getCompanyListItems
      let rows = []
      if (items !== undefined) {
        for (let company of items) {
          rows.push({
            id: company.getCompanyId(),
            label: company.getCompanyName()
          })
        }
      }
      return rows
    },

  },

  methods: {

    //Закрытие модалки
    hideModal() {
      this.isActiveSecondModal = false
      this.isActiveModalDelete = false
    },

    //Закрытие модалки изменения камеры
    closeCameraEdit() {
      this.$emit('hideModal')
    },

    //Открытие модалки удаление камеры
    deleteCamera() {
      this.isActiveModalDelete = true
      console.log('deleteCamera')
      this.isActiveSecondModal = true
    },

    //Сохранение изменений камеры
    saveCamera() {
      let data = {
        id: this.item.id,
        name: this.item.name,
        model_id: this.item.model_id,
        login: this.item.login,
        password: this.item.password,
        survey_period: this.item.survey_period,
        ip: this.item.ip,
        mac: this.item.mac,
        url: this.item.url,
        service_id: this.item.service_id,
        company_id: this.item.company_id,
        latitude: this.item.latitude,
        longitude: this.item.longitude,
        sector: this.item.sector,
        stat_period: Number(this.item.stat_period),
        type: this.item.type
      }

      store.dispatch('updateCamerasItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
      return true
    },

    //Фильтры для сервисов
    getServiceFilters() {
      return [
        {
          field_name: 'service_name',
          operand: '>',
          value: 0,
        }
      ]
    },

    //Фильтры для компаний
    getCompanyFilters() {
      return [
        {
          field_name: 'company_id',
          operand: '>',
          value: 0,
        }
      ]
    },

    //Загрузка данных камеры
    loadData() {
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.cameraId,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCameraId(),
                  name: resolve.getCameraName(),
                  latitude: resolve.getCameraLatitude(),
                  longitude: resolve.getCameraLongitude(),
                  model: resolve.getCameraModel(),
                  model_id: resolve.getCameraModelId(),
                  login: resolve.getCameraLogin(),
                  survey_period: resolve.getCameraSurveyPeriod(),
                  ip: resolve.getCameraIp(),
                  mac: resolve.getCameraMac(),
                  url: resolve.getCameraUrl(),
                  service: resolve.getCameraServiceName(),
                  service_id: resolve.getCameraServiceId(),
                  company: resolve.getCameraCompanyName(),
                  company_id: resolve.getCameraCompanyId(),
                  sector: resolve.getCameraSector(),
                  stat_period: resolve.getCameraStatPeriod(),
                  type: resolve.getCameraType()
                }
                this.loadServiceListItems()
                this.loadModelListItems()
                this.loadCompanyListItems()
                this.map()
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрузка списка сервисов
    loadServiceListItems() {
      store
          .dispatch('getServiceListItems', {
            filter: this.getServiceFilters(),
          })
          .then(
              (resolve) => {
                console.log('getServiceListItems resolve', resolve)
              },
              (reject) => {
                console.log('getServiceListItems reject', reject)
              }
          )
    },

    //Загрузка списка моделей
    loadModelListItems() {
      store
          .dispatch('getModelListItems', {})
          .then(
              (resolve) => {
                console.log('getModelListItems resolve', resolve)
              },
              (reject) => {
                console.log('getModelListItems reject', reject)
              }
          )
    },

    //Загрузка списка компаний
    loadCompanyListItems() {
      store
          .dispatch('getCompanyListItems', {
            filter: this.getCompanyFilters(),
          })
          .then(
              (resolve) => {
                console.log('getCompanyListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCompanyListItems reject', reject)
              }
          )
    },

    camerasTypes(){
      store
        .dispatch('camerasTypes', {})
        .then(
          (resolve) => {
            console.log('camerasTypes resolve', resolve)
            resolve.getItemsList().forEach(res => {
              const item = {
                value: res.getCameraTypeId(),
                label: res.getCameraTypeName()
              }
              this.typeList.push(item)
            })
          },
          (reject) => {
            console.log('camerasTypes reject', reject)
          }
        )
    },

    //Яндекс карты
    async map() {

      await loadYmap({apiKey: 'e98d34f8-157a-43e9-9dc5-46d8dd7e4f59'}, {debug: true});

      setTimeout(() => {
        let mapCenter = [this.item.latitude,this.item.longitude],
            selectedLatitude = this.item.latitude,
            selectedLongitude = this.item.longitude

        function init() {
          let map = new window.ymaps.Map('map', {
                center: mapCenter,
                zoom: 13,
                controls: ['zoomControl', 'searchControl']
              }, {});

          let placemark = new window.ymaps.Placemark(mapCenter, {
          }, {
            preset: 'islands#violetDotIconWithCaption',
            draggable: true
          });

          map.geoObjects.add(placemark)

          map.events.add('click', function (e) {
            let coords = e.get('coords');

            placemark.geometry.setCoordinates(coords);
            selectedLatitude = placemark.geometry.getCoordinates()[0]
            selectedLongitude = placemark.geometry.getCoordinates()[1]

          });

        }

        document.addEventListener('click', () => {
          this.item.latitude = selectedLatitude
          this.item.longitude = selectedLongitude
        })

        window.ymaps.ready(init)
      }, 50)

    }

  },
  mounted() {
    this.camerasTypes()
    this.loadData()
  },

})


</script>

<style lang="scss">

.navigation{
  display: flex;
  width: 100%;
  padding: 0 8px;
  button{
    padding: 12px 24px !important;
    border-radius: 10px;
    &:nth-child(2){
      margin: 0 10px 0 auto;
    }
    &:nth-child(3){
      background: #3742fa !important;
    }
  }
}

</style>
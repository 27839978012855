/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v4.23.2
// source: message.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./message_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.MessageServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.MessageServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateMessageRequest,
 *   !proto.proto.CreateMessageResponse>}
 */
const methodDescriptor_MessageService_CreateMessage = new grpc.web.MethodDescriptor(
  '/proto.MessageService/CreateMessage',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateMessageRequest,
  proto.proto.CreateMessageResponse,
  /**
   * @param {!proto.proto.CreateMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateMessageResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.MessageServiceClient.prototype.createMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.MessageService/CreateMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_CreateMessage,
      callback);
};


/**
 * @param {!proto.proto.CreateMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateMessageResponse>}
 *     Promise that resolves to the response
 */
proto.proto.MessageServicePromiseClient.prototype.createMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.MessageService/CreateMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_CreateMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateMessageRequest,
 *   !proto.proto.UpdateMessageResponse>}
 */
const methodDescriptor_MessageService_UpdateMessage = new grpc.web.MethodDescriptor(
  '/proto.MessageService/UpdateMessage',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateMessageRequest,
  proto.proto.UpdateMessageResponse,
  /**
   * @param {!proto.proto.UpdateMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateMessageResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.MessageServiceClient.prototype.updateMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.MessageService/UpdateMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_UpdateMessage,
      callback);
};


/**
 * @param {!proto.proto.UpdateMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateMessageResponse>}
 *     Promise that resolves to the response
 */
proto.proto.MessageServicePromiseClient.prototype.updateMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.MessageService/UpdateMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_UpdateMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteMessageRequest,
 *   !proto.proto.DeleteMessageResponse>}
 */
const methodDescriptor_MessageService_DeleteMessage = new grpc.web.MethodDescriptor(
  '/proto.MessageService/DeleteMessage',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteMessageRequest,
  proto.proto.DeleteMessageResponse,
  /**
   * @param {!proto.proto.DeleteMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteMessageResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.MessageServiceClient.prototype.deleteMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.MessageService/DeleteMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_DeleteMessage,
      callback);
};


/**
 * @param {!proto.proto.DeleteMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteMessageResponse>}
 *     Promise that resolves to the response
 */
proto.proto.MessageServicePromiseClient.prototype.deleteMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.MessageService/DeleteMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_DeleteMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListMessageRequest,
 *   !proto.proto.ListMessageResponse>}
 */
const methodDescriptor_MessageService_ListMessage = new grpc.web.MethodDescriptor(
  '/proto.MessageService/ListMessage',
  grpc.web.MethodType.UNARY,
  proto.proto.ListMessageRequest,
  proto.proto.ListMessageResponse,
  /**
   * @param {!proto.proto.ListMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListMessageResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.MessageServiceClient.prototype.listMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.MessageService/ListMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_ListMessage,
      callback);
};


/**
 * @param {!proto.proto.ListMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListMessageResponse>}
 *     Promise that resolves to the response
 */
proto.proto.MessageServicePromiseClient.prototype.listMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.MessageService/ListMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_ListMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ItemMessageRequest,
 *   !proto.proto.ItemMessageResponse>}
 */
const methodDescriptor_MessageService_ItemMessage = new grpc.web.MethodDescriptor(
  '/proto.MessageService/ItemMessage',
  grpc.web.MethodType.UNARY,
  proto.proto.ItemMessageRequest,
  proto.proto.ItemMessageResponse,
  /**
   * @param {!proto.proto.ItemMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ItemMessageResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ItemMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ItemMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ItemMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.MessageServiceClient.prototype.itemMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.MessageService/ItemMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_ItemMessage,
      callback);
};


/**
 * @param {!proto.proto.ItemMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ItemMessageResponse>}
 *     Promise that resolves to the response
 */
proto.proto.MessageServicePromiseClient.prototype.itemMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.MessageService/ItemMessage',
      request,
      metadata || {},
      methodDescriptor_MessageService_ItemMessage);
};


module.exports = proto.proto;


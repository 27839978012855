import users from '@/components/modules/users/store/index'
import camera from '@/components/modules/cameras/store/index'
import zone from '@/components/modules/zone/store/index'
import model from '@/components/modules/models/store/index'
import services from '@/components/modules/services/store/index'
import companies from '@/components/modules/company/store/index'
import statistic from '@/components/modules/statistic/store/index'
import message from '@/components/modules/message/store/index'
import change from '@/components/modules/change/store/index'


// Список модулей
const importModules: any = [
    users,
    camera,
    zone,
    model,
    services,
    companies,
    statistic,
    message,
    change
],

items = []

const itemObj: any = {}

for (const i in importModules) {
  for (const j in importModules[i]) {
    itemObj[j] = importModules[i][j]
    items.push(importModules[i][j])
  }
}

// Список модулей хранилищ
export default {
  itemObj,
}

<template>
  <div class="cameras">
    <q-card style="box-shadow: none">
      <div class="row" style="height: 100%">
        <div class="col-12" style="height: 100%;">
          <div class="pre-header">
            <div>
              <h3 style="margin: 0;">Камеры: {{ getCamerasListItems.length }}</h3>
            </div>
            <q-btn
                color="primary"
                :disable="isCamerasListBusy"
                label="Добавить"
                icon="mdi-plus"
                @click="addCamera"
            />
          </div>
          <q-table
              flat
              style="height: 100%;"
              :rows="getCamerasListItems"
              :columns="columns"
              row-key="id"
              :loading="isCamerasListBusy"
              @request="onRequestCamera"
          >
            <template v-slot:top>
              <q-input
                  class="id"
                  dense
                  v-model="selectedId"
                  filled
                  label="ID"
              >
              </q-input>
              <q-input
                  dense
                  v-model="selectedName"
                  filled
                  label="Название"
              >
              </q-input>
              <q-select
                  v-model="selectedModel"
                  :options="getModelListItems"
                  filled
                  emit-value
                  map-options
                  label="Модели"
              ></q-select>
              <q-select
                  v-model="selectedCompany"
                  :options="getCompanyListItems"
                  filled
                  emit-value
                  map-options
                  label="Компании"
              ></q-select>
              <q-select
                  v-model="selectedService"
                  :options="getServiceListItems"
                  filled
                  emit-value
                  map-options
                  label="Сервисы"
              ></q-select>
              <q-btn @click="clearSelect">Очистить</q-btn>
              <q-btn @click="search">Отфильтровать</q-btn>
            </template>
            <template v-slot:body-cell-status="props">
              <q-td :props="props">
                <div class="cameras__name">
                  <p>{{ props.row.status }}</p>
                  <q-tooltip>
                    {{ props.row.status }}
                  </q-tooltip>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-name_model="props">
              <q-td :props="props">
                <div class="options-block" v-if="props.row.name">
                  <p>{{ props.row.name }}</p>
                  <q-tooltip>
                    {{ props.row.name }}
                  </q-tooltip>
                </div>
                <div class="options-block" v-if="props.row.model">
                  <p v-if="props.row.model_id !== 1" class="button" @click="watchModel(props.row.model_id)">{{ props.row.model }}</p>
                  <p v-else>{{ props.row.model }}</p>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-address_mac_url="props">
              <q-td :props="props">
                <div class="options-block" v-if="props.row.address">
                  <p>{{ props.row.address }}</p>
                </div>
                <div class="options-block" v-if="props.row.mac">
                  <p>{{ props.row.mac }}</p>
                </div>
                <div class="options-block" v-if="props.row.url">
                  <p>{{ props.row.url }}</p>
                  <q-tooltip>
                    {{ props.row.url }}
                  </q-tooltip>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-company_service="props">
              <q-td :props="props">
                <div class="options-block" v-if="props.row.company">
                  <p v-if="props.row.company_id !== 1" class="button" @click="watchCompany(props.row.company_id)">{{ props.row.company }}</p>
                  <p v-else>{{ props.row.company }}</p>
                </div>
                <div class="options-block" v-if="props.row.service">
                  <p v-if="props.row.service_id !== 1" class="button" @click="watchService(props.row.service_id)">{{ props.row.service }}</p>
                  <p v-else>{{ props.row.service }}</p>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-login_password="props">
              <q-td class="login" :props="props">
                <div class="options-block" v-if="props.row.login">
                  <p>{{ props.row.login }}</p>
                </div>
              </q-td>
            </template>
            <template v-slot:body-cell-survey_period="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-created="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item clickable @click="actionRow('edit', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path><path d="m195.656 33.271-52.882 52.882" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path></g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="actionRow('watch', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </q-card>

    <q-dialog v-model="isActiveModal">
      <CameraAdd
          v-if="isActiveModalAdd"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
      <CameraEdit
          v-if="isActiveModalEdit"
          :cameraId="selectedCameraId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
    </q-dialog>
  </div>
</template>

<script lang="ts">

import {defineComponent} from 'vue'
import CameraEdit from './CameraEdit.vue'
import CameraAdd from './CameraAdd.vue'
import store from "@/store";


export default defineComponent({
  name: 'CameraList',

  components: {
    CameraEdit,
    CameraAdd,
    //DataLoading
  },

  data() {
    return {
      columns: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          style: 'max-width: 50px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'status',
          label: 'Статус',
          field: 'status',
          align: 'left',
          style: 'width: fit-content;max-width: 90px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'name_model',
          label: 'Название',
          field: 'name_model',
          align: 'left',
          style: 'max-width: 200px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'address_mac_url',
          label: 'IP/MAC/URL',
          field: 'address_mac_url',
          align: 'left',
          style: 'max-width: 200px;text-overflow: ellipsis;overflow: hidden',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'company_service',
          label: 'Компания / Сервисы',
          field: 'company_service',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'login_password',
          label: 'Логин и Пароль',
          field: 'login_password',
          align: 'left',
          style: 'max-width: 200px',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'survey_period',
          label: 'Период опроса',
          field: 'survey_period',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'created',
          label: 'Добавлена',
          field: 'created',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: 'Действие',
          align: 'center',
          field: 'id',
          headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
          style: 'width: 40px'
        }
      ] as any,
      cameraPagination: {
        sortBy: 'camera_id',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      modelPagination: {
        sortBy: 'model_id',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      companyPagination: {
        sortBy: 'company_id',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      servicePagination: {
        sortBy: 'service_id',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      selectedName: '',
      selectedId: null as any,
      selectedModel: null as any,
      selectedCompany: null as any,
      selectedService: null as any,
      isActiveModalEdit: false,
      isActiveModalAdd: false,
      isActiveModalDelete: false,
      isActiveModal: false,
      selectedCameraId: null as any,
      tableKey: 0
    }
  },

  computed: {

    isCamerasListBusy() {
      return store.getters.getIsCamerasListBusy
    },

    //Формирование списка камер
    getCamerasListItems() {
      const items = store.getters.getCamerasListItems
      let rows: any = []
      if (items !== undefined) {
        for (let camera of items) {
          rows.push({
            id: camera.getCameraId(),
            name: camera.getCameraName(),
            status: camera.getCameraStatus(),
            model: camera.getCameraModel(),
            model_id: camera.getCameraModelId(),
            address: camera.getCameraIp(),
            mac: camera.getCameraMac(),
            url: camera.getCameraUrl(),
            company: camera.getCameraCompanyName(),
            company_id: camera.getCameraCompanyId(),
            service: camera.getCameraServiceName(),
            service_id: camera.getCameraServiceId(),
            created: camera.getCameraCreated(),
            login: camera.getCameraLogin(),
            password: camera.getCameraPassword(),
            survey_period: camera.getCameraSurveyPeriod(),
          })
        }
      }
      return rows
    },

    //Формирование списка сервисов
    getServiceListItems() {
      const items = store.getters.getServiceListItems
      let rows: any = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            value: item.getServiceId(),
            label: item.getServiceName()
          })
        }
      }
      return rows
    },

    //Формирование списка моделей
    getModelListItems() {
      const items = store.getters.getModelListItems

      let rows: any = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            value: model.getModelId(),
            label: model.getModelName()
          })
        }
      }
      return rows
    },

    //Формирование списка компаний
    getCompanyListItems() {
      const items = store.getters.getCompanyListItems

      let rows: any = []
      if (items !== undefined) {
        for (let model of items) {
          rows.push({
            value: model.getCompanyId(),
            label: model.getCompanyName()
          })
        }
      }

      return rows
    },

  },

  methods: {

    //Переход на страницу просмотр компании
    watchCompany(id: any){
      this.$router.push('/companies/watch/' + id)
    },

    //Переход на страницу список сервисов
    watchService(id: any){
      this.$router.push('/services?service_id=' + id)
    },

    //Переход на страницу список моделей
    watchModel(id: any){
      this.$router.push('/models?model_id=' + id)
    },

    //Закрыть модальные окна
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
    },

    //Изменить камеру
    editCamera() {
      this.isActiveModalEdit = true
      this.isActiveModal = true
    },

    //Добавить камеру
    addCamera() {
      this.isActiveModalAdd = true
      this.isActiveModal = true
    },

    //Просмотр камеры
    watchCamera(){
      this.$router.push('/cameras/watch/' + this.selectedCameraId)
    },

    //Функционал кнопок в таблице
    actionRow(method: any, id: any) {
      this.selectedCameraId = id
      method === 'edit' ? this.editCamera() :
          method === 'watch' ? this.watchCamera() : console.log('Неизвестный метод')
    },

    //Прогрузка после взаимодействий с камерами
    afterAction() {
      console.log('afterAction')
      this.loadCamerasListItems()
      this.hideModal()
    },

    //Запрос к таблице с камерами
    onRequestCamera(props: any) {
      console.log('onRequestCamera props', props)
      this.cameraPagination = props.pagination
      this.loadCamerasListItems()
    },

    //Фильтр сервисов
    getServiceFilters() {
      return [
        {
          field_name: 'service_name',
          operand: '>',
          value: 0
        }
      ]
    },

    //Фильтр компаний
    getCompanyFilters(){
      return [
        {
          field_name: 'company_id',
          operand: '>',
          value: 0
        }
      ]
    },

    //Фильтр камер
    getCameraFilters(){
      let filter = []
      if(window.location.href.includes('?company_id')){
        const link = new URL(window.location.href).searchParams.get('company_id')
        filter.push({
          field_name: 'camera_company_id',
          operand: '=',
          value: link
        })
      }
      if(window.location.href.includes('?service_id')){
        const link = new URL(window.location.href).searchParams.get('service_id')
        filter.push({
          field_name: 'camera_service_id',
          operand: '=',
          value: link
        })
      }
      if(window.location.href.includes('?model_id')){
        const link = new URL(window.location.href).searchParams.get('model_id')
        filter.push({
          field_name: 'camera_model_id',
          operand: '=',
          value: link
        })
      }
      if(this.selectedModel > 0){
        filter.push({
            field_name: 'camera_model_id',
            operand: '=',
            value: this.selectedModel
          })
      }
      if(this.selectedCompany > 0){
        filter.push({
          field_name: 'camera_company_id',
          operand: '=',
          value: this.selectedCompany
        })
      }
      if(this.selectedService > 0){
        filter.push({
          field_name: 'camera_service_id',
          operand: '=',
          value: this.selectedService
        })
      }
      if(this.selectedName.length > 0){
        filter.push({
            field_name: 'camera_name',
            operand: 'ilike',
            value: this.selectedName
          })
      }
      if(this.selectedId > 0){
        filter.push({
          field_name: 'camera_id',
          operand: '=',
          value: this.selectedId
        })
      }
      return filter
    },

    //Фильтр моделей
    getModelFilters(){
      return [
        {
          field_name: 'model_id',
          operand: '>',
          value: 0
        }
      ]
    },

    //Загрузка списка камер
    loadCamerasListItems() {
      store
          .dispatch('getCamerasListItems', {

            filter: this.getCameraFilters(),
            sort: {
              name: this.cameraPagination.sortBy,
              exp: this.cameraPagination.descending ? 'DESC' : 'ASC',
            }
          })
          .then(
              (resolve) => {
                console.log('getCamerasListItems resolve', resolve)
                this.cameraPagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getCamerasListItems reject', reject)
              }
          )
    },

    //Загрузка списка сервисов
    loadServiceListItems() {
      store
          .dispatch('getServiceListItems', {
            filter: this.getServiceFilters(),
            sort: {
              name: this.servicePagination.sortBy,
              exp: this.servicePagination.descending ? 'DESC' : 'ASC',
            }
          })
          .then(
              (resolve) => {
                console.log('getServiceListItems resolve', resolve)
              },
              (reject) => {
                console.log('getServiceListItems reject', reject)
              }
          )
    },

    //Загрузка списка моделей
    loadModelListItems() {
      store
          .dispatch('getModelListItems', {
            filter: this.getModelFilters(),
            sort: {
              name: this.modelPagination.sortBy,
              exp: this.modelPagination.descending ? 'DESC' : 'ASC',
            }
          })
          .then(
              (resolve) => {
                console.log('getModelListItems resolve', resolve)
              },
              (reject) => {
                console.log('getModelListItems reject', reject)
              }
          )
    },

    //Загрузка списка компаний
    loadCompanyListItems() {
      store
          .dispatch('getCompanyListItems', {
            filter: this.getCompanyFilters(),
            sort: {
              name: this.companyPagination.sortBy,
              exp: this.companyPagination.descending ? 'DESC' : 'ASC',
            }
          })
          .then(
              (resolve) => {
                console.log('getCompanyListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCompanyListItems reject', reject)
              }
          )
    },

    //Поиск
    search(){
      let newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      this.loadCamerasListItems()
    },

    //Очистка полей фильтров и поиска
    clearSelect() {
      let newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      this.selectedId = ''
      this.selectedName = ''
      this.selectedModel = ''
      this.selectedCompany = ''
      this.selectedService = ''
      this.loadCamerasListItems()
    },

    //Вызов функций загрузки данных
    loadData() {
      this.hideModal()
      this.loadCamerasListItems()
      this.loadServiceListItems()
      this.loadModelListItems()
      this.loadCompanyListItems()
    },

  },

  mounted() {
    this.loadData()
  }

})

</script>

<style lang="scss">

.cameras{
  max-width: 1440px;
  margin: 0 auto;
  .pre-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
    }
    .q-btn{
      padding: 12px 24px 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }
  .q-table__top {
    padding: 20px !important;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    border-radius: 10px !important;
    .id{
      &.q-field{
        max-width: 110px;
        .q-field__native{
          min-width: unset;
        }
      }
    }
    .q-field{
      max-width: 215px;
      &__native{
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__control{
        border-radius: 5px;
      }
    }
    .q-field__native {
      min-width: 150px;
    }
    .q-field__inner {
      margin: 0 15px 0 0;
    }

    button{
      margin: 0 0 0 auto;
      height: 50px;
      width: 170px;
      border-radius: 10px;
      background: #3742fa !important;
      color: #fff;
      font-weight: 600;
      &:last-child{
        margin: 0 0 0 20px;
      }
    }
  }

  .q-table__middle{
    margin: 35px 0 0 0;
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .q-td{
      vertical-align: middle;
    }
    .options-block{
      p{
        margin: 8px 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .button{
        color: #027BE3;
        cursor: pointer;
      }
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  .filter {
    margin: 0 0 0 25px;
    padding: 9px 15px;
    font-size: 15px;
  }

  .q-card{
    padding: 0 !important;
  }

  .login{
    p{
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }

  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal{
    height: 56px;

  }

  .q-field--dense .q-field__label{
    font-size: 16px !important;
    top: 18px !important;
  }

  &__name{
    width: inherit;
    width: 100%;
    p{
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

}

</style>
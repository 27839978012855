<template>
  <q-layout view="lHh lpR fFf">
    <q-header elevated class="text-white">
      <q-toolbar>
        <q-toolbar-title>
          <router-link to="/" style="text-decoration: none; color: white">
            <q-avatar>
              <img src="@/assets/img/header-logo.png" alt="Логотип"/>
            </q-avatar>
            EasyPark
          </router-link>
        </q-toolbar-title>
        <q-list v-if="$route.path !== '/'">
          <a href="/cameras">Камеры</a>
          <a href="/companies">Компании</a>
          <a href="/services">Сервисы</a>
          <a href="/models">Модели</a>
          <a href="/users/manage">Пользователи</a>
          <a href="/message">Сообщения</a>
          <a href="/statistic">Статистика</a>
          <a href="/changes">Изменения</a>
        </q-list>
        <q-btn
          @click="clickLogout()"
          color="white"
          text-color="black"
          label="Выход"
          icon="logout"
          no-caps
          unelevated
          class="exit-button"
        />
      </q-toolbar>
    </q-header>
    <q-page-container >
      <Breadcrumbs/>
      <router-view class="router-view" v-slot="{ Component }">
        <transition name="page-opacity" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs.vue'

import store from "@/store";

export default defineComponent({
  name: 'App',

  components: {
    Breadcrumbs,
  },
  data() {
    return {
      transitionName: null as any,
      isMainPage: true
    }
  },

  watch: {
    '$route' (to, from) {
      if (from.path === '/swamp') {
        this.transitionName = 'drain';
      } else {
        this.transitionName = 'slither';
      }
    }
  },

  methods: {
    loadUsersListItems() {
      store
          .dispatch('getUsersListItems', {})
          .then(
              (resolve) => {
                console.log('getUsersListItems resolve', resolve)
              },
              (reject) => {
                console.log('getUsersListItems reject', reject)
                /*if(reject.message.includes('access token is invalid: invalid token: token is expired by')){
                  localStorage.removeItem('accessToken')
                  localStorage.removeItem('refreshToken')
                  location.reload()
                }*/
              }
          )
    },
    clickLogout(){
      store.dispatch('removeToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('user_id')
      location.reload()
    }
  },

})
</script>

<style lang="scss">


@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
  url(../src/assets/fonts/Montserrat.ttf) format("truetype");
}
body{
  overflow-y: scroll;
}

.q-page-container{
  padding-bottom: 70px;
}

body,a,p,span,h1,h2,h3,h4,input,label,input::placeholder,th,td{
  font-family: 'Montserrat', sans-serif;
}

.page-opacity-enter-active,.page-opacity-leave-active{
  transition: 500ms ease all;
}

.page-opacity-enter-from,.page-opacity-leave-to{
  opacity: 0;
}

.q-toolbar__title{
  width: fit-content !important;
  max-width: unset !important;
  min-width: unset !important;
  flex: unset !important;
}

.q-toolbar{
  justify-content: space-between;
}

.exit-button{
  width: 150px !important;
}
.q-list{
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  a{
    margin: 0 10px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding: 4px 16px;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    &::before{
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      background: #fff;
      z-index: -1;
      transition: all 0.3s ease;
      opacity: 0;
      border-radius: 3px;
    }
    &:hover{
      &::before{
        opacity: 0.1;
      }
    }
  }
}

header{
  background-color: #3742fa !important;
  padding: 10px 0;
}

@media screen and (max-width: 1440px){
  .q-list{
    .q-btn{
      margin: 0 8px !important;
    }
  }
  .q-page-container{
    padding: 0 15px;
  }
}

</style>

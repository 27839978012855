import {
    ModelServicePromiseClient
} from "../../proto/pb/model_grpc_web_pb";
import {
    CreateModelRequest,
    CreateModelResponse,
    DeleteModelResponse,
    DeleteModelRequest,
    ListModelRequest,
    ListModelResponse,
    UpdateModelRequest,
    UpdateModelResponse,
    Model,
    ModelFilter,
    ModelFilterItem,
    ModelPagination,
    ModelSort,
} from "../../proto/pb/model_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';


// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class ModelApi {
    items: Array<Model> = [];
    client: ModelServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new ModelServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new ModelFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new ModelFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new ModelSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'model_name';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new ModelPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getModelListItems(filter: any, sort: any, pagination: any){

        const request = new ListModelRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listModel(request, getAuthToken())
    }

    // Получение элемента
    getModelListItem(filter: any){

        const request = new ListModelRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort({}));
        request.setPagination(this.createPagination({}));

        return this.client.listModel(request, getAuthToken())
    }

    // Создание элемента
    createModelItem(data: any){

        const request = new CreateModelRequest();
        console.log(data)
        request.setModelName(data.name)
        request.setModelGrabberAddress(data.grabber_address)
        request.setModelSnapshotAddress(data.snapshot_address)
        request.setModelVendor(data.vendor)
        request.setModelLowResSource(data.low_res_source)
        request.setModelMidResSource(data.mid_res_source)
        request.setModelHiResSource(data.hi_res_source)

        return this.client.createModel(request, getAuthToken())
    }

    // Редактировать элемент
    updateModelItem(data: any){

        const request = new UpdateModelRequest();
        request.setModelId(data.id)
        request.setModelName(data.name)
        request.setModelGrabberAddress(data.grabber_address)
        request.setModelSnapshotAddress(data.snapshot_address)
        request.setModelVendor(data.vendor)
        request.setModelLowResSource(data.low_res_source)
        request.setModelMidResSource(data.mid_res_source)
        request.setModelHiResSource(data.hi_res_source)

        return this.client.updateModel(request, getAuthToken())
    }

    // Удаление элемента
    deleteModelItem(data: any){

        const request = new DeleteModelRequest();
        request.setModelId(data.id);

        return this.client.deleteModel(request, getAuthToken())
    }
}


<template>

  <div class="model-edit">
    <q-card
        class="q-pa-md"
    >
      <q-item>
        <q-card-section>
          <q-item-label class="text-h5"
          >Добавить модель
          </q-item-label
          >
        </q-card-section>
      </q-item>

      <div v-if="!isModelListBusy">
        <q-form
          @submit="saveModel"
          greedy
        >
          <q-card-section>
            <q-input
                v-model="item.name"
                label="Название"
                filled
                lazy-rules
                :rules="[
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]"
            />
          </q-card-section>
          <q-card-section>
            <q-select
                v-model="item.grabber_address"
                :options="grabber_addresses"
                filled
                emit-value
                label="Адрес grabber"
            ></q-select>
          </q-card-section>
          <q-card-section>
            <q-select
                v-model="item.snapshot_address"
                :options="snapshot_addresses"
                filled
                emit-value
                label="Получение snapshot"
            ></q-select>
          </q-card-section>
          <q-card-section class="tittle">
            <h3>Параметры</h3>
          </q-card-section>
          <q-card-section style="display: flex">
            <q-item-section>
              <q-input
                  v-model="item.vendor"
                  label="Vendor"
                  filled
                  lazy-rules
                  style="margin: 0 0 15px 0"
              />
              <q-input
                  v-model="item.low_res_source"
                  label="Video source low"
                  filled
                  lazy-rules
              />
            </q-item-section>
            <q-item-section>
              <q-input
                  v-model="item.mid_res_source"
                  label="Video source medium"
                  filled
                  lazy-rules
                  style="margin: 0 0 15px 0"
              />
              <q-input
                  v-model="item.hi_res_source"
                  label="Video source high"
                  filled
                  lazy-rules
              />
            </q-item-section>
          </q-card-section>
          <q-card-actions style="display: flex;justify-content: space-between;padding: 16px">
            <q-btn color="positive" type="submit"> Сохранить</q-btn>
            <q-btn color="negative" @click="closeModelEdit"> Отмена</q-btn>
          </q-card-actions>
        </q-form>

      </div>
    </q-card>
  </div>

</template>

<script lang="ts">
/*import store from "@/store";*/
import {defineComponent} from 'vue'
import store from "@/store";

export default defineComponent({
  name: "ModelEdit",

  props: {
    modelId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {
        id: '',
        name: '',
        grabber_address: '',
        snapshot_address: '',
        vendor: '',
        low_res_source: '',
        mid_res_source: '',
        hi_res_source: '',
      },
      grabber_addresses: [
        {
          label: 'Test',
          value: 'Test',
        },
        {
          label: 'Test1',
          value: 'Test1',
        },
      ],
      snapshot_addresses: [
        {
          label: 'Test',
          value: 'Test',
        },
        {
          label: 'Test1',
          value: 'Test1',
        },
      ]
    }
  },

  computed: {
    isModelListBusy() {
      return store.getters.getIsModelListBusy
    },
  },

  methods: {
    //Закрыть модалку
    closeModelEdit(){
      this.$router.push('/models')
    },

    //Сохранить изменение модели
    saveModel() {
      let data = {
        id: this.item.id,
        name: this.item.name,
        grabber_address: this.item.grabber_address,
        snapshot_address: this.item.snapshot_address,
        vendor: this.item.vendor,
        low_res_source: this.item.low_res_source,
        mid_res_source: this.item.mid_res_source,
        hi_res_source: this.item.hi_res_source,
      }

      store.dispatch('updateModelItem', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$router.push('/models')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
    },

    //Загрузить данные модели
    loadData() {
      store
          .dispatch('getModelListItem', {
            filter: [
              {
                field_name: 'model_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getModelId(),
                  name: resolve.getModelName(),
                  grabber_address: resolve.getModelGrabberAddress(),
                  snapshot_address: resolve.getModelSnapshotAddress(),
                  vendor: resolve.getModelVendor(),
                  low_res_source: resolve.getModelLowResSource(),
                  mid_res_source: resolve.getModelMidResSource(),
                  hi_res_source: resolve.getModelHiResSource(),
                }
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

  },
  created() {
    this.loadData()
  },

})
</script>

<style lang="scss">

.model-edit{
  .tittle{
    padding: 0 16px;
    margin: 0;
    h3{
      font-size: 22px;
      line-height: 100%;
      margin: 0 0 16px 0;
    }
  }
}

</style>
<template>

  <div class="detection">
    <div class="detection__left">
      <div class="detection__left-information">
        <p>Название: <span>{{ item.name}}</span></p>
        <p class="status">
          Статус:
          <q-spinner v-if="isCameraLoading" size="16px"></q-spinner>
          <span v-if="!isCameraLoading && item.status === 'активна'" class="success">{{ item.status }}</span>
          <span v-else-if="!isCameraLoading && item.status === 'нет связи' || item.status === 'нет статуса'" class="error">{{ item.status }}</span>
          <span v-else-if="!isCameraLoading && item.status.includes('задержка')" class="pending">{{ item.status }}</span>
        </p>
        <p>Адресс:
          <q-spinner v-if="addressLoader" size="16px"></q-spinner>
          <span v-if="!addressLoader">{{ item.address }}</span>
        </p>
        <p>Добавлена:
          <q-spinner v-if="isCameraLoading" size="16px"></q-spinner>
          <span v-if="!isCameraLoading">{{ item.created }}</span>
        </p>
        <p>Модель:
          <q-spinner v-if="isCameraLoading" size="16px"></q-spinner>
          <span v-if="!isCameraLoading">{{ item.model}}</span>
        </p>
        <p>Компания:
          <q-spinner v-if="isCameraLoading" size="16px"></q-spinner>
          <span v-if="!isCameraLoading" class="link" @click="$router.push('/companies/watch/' + item.company_id)">{{ item.company}}</span>
        </p>
        <p>Сервис:
          <q-spinner v-if="isCameraLoading" size="16px"></q-spinner>
          <span v-if="!isCameraLoading" class="link" @click="$router.push('/services?service_id=' + item.company_id)">{{ item.service}}</span>
        </p>
      </div>
      <q-table
          :columns="zoneColumn"
          :rows="zoneList"
          row-key="id"
      >
        <template v-slot:body-cell-type="props">
          <q-td :props="props">
            <q-list>
              <q-item v-if="props.value">
                <q-item-section>
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18px" height="18px"
                       viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="#6BBE66" d="M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z"/></g></svg>
                </q-item-section>
              </q-item>
              <q-item v-else>
                <q-item-section>
                  <svg width="18px" height="18px" id="Layer_1" data-name="Layer 1"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                    <defs></defs>
                    <title>cancel</title>
                    <path style="fill:#c00706;fill-rule:evenodd;"
                          d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"/>
                    <path style="fill:#fff;fill-rule:evenodd;"
                          d="M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"/>
                  </svg>
                </q-item-section>
              </q-item>
            </q-list>
          </q-td>
        </template>
        <template v-slot:no-data>
          <div class="col-12 text-center">Нет данных</div>
        </template>
      </q-table>
      <div class="detection__left-manual">
        <p>Настройка детекции:</p>
        <q-form
            @submit="sendToDetection"
            greedy
        >
          <div class="detection__left-manual__header">
            <div class="detection__left-manual__input">
              <p>Количество проходов распознавания: {{ setting.passes }}</p>
              <q-slider
                  name="passes"
                  v-model="setting.passes"
                  label
                  switch-label-side
                  :min="1"
                  :max="10"
                  :step="1"
              ></q-slider>
            </div>
          </div>
          <div class="detection__left-manual__passes" v-for="(pass,index) in setting.passes" :key="pass">
            <p>Проход №{{ pass }}</p>
            <div class="detection__left-manual__input coordinates-input">
              <p>Координаты:</p>
              <q-input
                  name="coordinates"
                  v-model="setting.coordinates[pass]"
                  @click="modalActivator(index)"
                  readonly
              >
              </q-input>
            </div>
            <div class="detection__left-manual__input">
              <p>Точность распознавания: {{ setting.accuracy[pass] }}</p>
              <q-field
                  borderless
                  stack-label
                  v-model="setting.accuracy[pass]"
                  :rules="[
                    (val) => (val) || 'Точность должна быть задана',
                  ]"
                  style="height: 55px;"
              >
                <q-slider
                    name="accuracy"
                    v-model="setting.accuracy[pass]"
                    switch-label-side
                    label
                    :min="0.1"
                    :max="1"
                    :step="0.1"
                ></q-slider>
              </q-field>
            </div>
            <div class="detection__left-manual__input">
              <p>Метод распознавания:</p>
              <q-field
                  borderless
                  stack-label
                  v-model="setting.type[pass]"
                  :rules="[
                    (val) => (val && val.length > 0) || 'Необходимо выбрать метод распознавания',
                  ]"
                  style="height: 55px;"
              >
                <q-option-group
                    v-model="setting.type[pass]"
                    :options="types"
                    color="primary"
                    inline
                ></q-option-group>
              </q-field>
            </div>
            <div class="detection__left-manual__input">
              <p>Коэффициент для удаления крупных объектов: {{ setting.garbage[pass] }}</p>
              <q-field
                  borderless
                  stack-label
                  v-model="setting.garbage[pass]"
                  :rules="[
                    (val) => (val) || 'Необходимо задать коэфицент',
                  ]"
                  style="height: 55px;"
              >
                <q-slider
                    name="accuracy"
                    v-model="setting.garbage[pass]"
                    switch-label-side
                    label
                    :min="0"
                    :max="1"
                    :step="0.01"
                />
              </q-field>
            </div>
            <div v-if="setting.type[pass] === 'flip' || setting.type[pass] === 'flip/split'" class="detection__left-manual__input">
              <p>Ось вращения:</p>
              <q-radio
                  name="axis"
                  v-model="setting.axis[pass]"
                  val="0"
                  label="Вертикально"
              ></q-radio>
              <q-radio
                  name="axis"
                  v-model="setting.axis[pass]"
                  val="1"
                  label="Горизонтально"
              ></q-radio>
              <q-radio
                  name="axis"
                  v-model="setting.axis[pass]"
                  val="2"
                  label="Вертикально/Горизонтально"
              ></q-radio>
            </div>
          </div>
          <q-btn class="save" color="primary" @click="savePrams">Сохранить</q-btn>
          <q-btn :disabled="this.loader" color="primary" type="submit">Отправить</q-btn>
        </q-form>
      </div>
    </div>
    <div class="detection__right">
      <div class="detection__right-screen">
        <q-spinner
            v-if="loader"
            size="40px"
        ></q-spinner>
        <canvas v-if="!loader" width="900" height="500" ref="camera_canvas" id='canvas'></canvas>
      </div>
      <q-toggle
              v-model="showAllObject"
              label="Показать все объекты"
              @click="this.drawing()"
          />
      <q-table
          :columns="columns"
          :rows="getCurrentCameraStatListItems"
          v-model:selected="selectedRow"
          row-key="id"
          @row-click="onRowClick"
      >
        <template v-slot:body-cell-className="props">
          <q-td v-if="props.row.className === 'free'" :props="props">
            Свободно
          </q-td>
          <q-td v-else :props="props">
            {{ props.row.className }}
          </q-td>
        </template>
        <template v-slot:body-cell-confidence="props">
          <q-td v-if="props.row.className === 'free'" :props="props">
            {{ props.row.vacantNum }}
          </q-td>
          <q-td v-else :props="props">
            {{ props.row.confidence }}
          </q-td>
        </template>
        <template v-slot:body-cell-coordinates="props">
          <q-td class="table-coordinates" :props="props">
            <p>yMin: <span>{{ props.row.yMin }}</span></p>
            <p>xMin: <span>{{ props.row.xMin }}</span></p>
            <p>yMax: <span>{{ props.row.yMax }}</span></p>
            <p>xMax: <span>{{ props.row.xMax }}</span></p>
          </q-td>
        </template>
        <template v-slot:body-cell-action="props">
          <q-td class="action" :props="props">
            <q-list style="display: flex">
              <q-item clickable @click="drawing(props.row)">
                <q-item-section>
                  <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                          d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z"
                          stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                  </svg>
                </q-item-section>
              </q-item>
            </q-list>
          </q-td>
        </template>
        <template v-slot:no-data>
          <q-spinner
              v-if="loader"
              size="40px"
          ></q-spinner>
          <div v-if="!loader" class="col-12 text-center">Нет данных</div>
        </template>
      </q-table>
    </div>
    <q-dialog
        v-model="modalActive"
        persistent
    >
      <q-card style="max-width: 930px;width:930px;height:580px">
        <q-card-section class="coordinates">
          <canvas width="900" height="500" id='coordinates_canvas'></canvas>
          <div class="coordinates__navigation">
            <q-btn color="primary" @click="modalActive=false">Готово</q-btn>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>

</template>

<script lang="js">

import {defineComponent} from "vue";
import store from "@/store";
import {loadYmap} from "vue-yandex-maps/dist/vue-yandex-maps.esm";
import { fabric } from 'fabric'

export default defineComponent({
  name: 'CameraDetection',

  data() {
    return {
      item: {
        id: 0,
        name: '',
        model: '',
        model_id: '',
        company: '',
        company_id: '',
        service: '',
        service_id: '',
        latitude: '',
        longitude: '',
        address: '',
        created: ''
      },
      picture_url: '',
      isCameraLoading: true,
      isCameraOffline: false,
      addressLoader: true,
      selectedRow: [],
      columns: [
        {
          name: 'id',
          label: 'ID',
          field: 'id',
          align: 'left',
          style: 'width: 20px',
          headerStyle: 'font-weight: 600;font-size: 14px;width: 20px'
        },
        {
          name: 'className',
          label: 'Тип',
          field: 'className',
          align: 'left',
          style: 'width: 100px',
          headerStyle: 'font-weight: 600;font-size: 14px;width: 100px'
        },
        /*{
          name: 'classId',
          label: 'ID типа',
          field: 'classId',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },*/
        {
          name: 'confidence',
          label: 'Схожесть',
          field: 'confidence',
          align: 'left',
          style: 'width: 120px',
          headerStyle: 'font-weight: 600;font-size: 14px;width: 120px'
        },
        {
          name: 'coordinates',
          label: 'Координаты',
          field: 'coordinates',
          align: 'left',
          style: 'width: auto',
          headerStyle: 'font-weight: 600;font-size: 14px; width: auto;'
        },
        {
          name: 'action',
          label: 'Действие',
          align: 'center',
          field: 'id',
          headerStyle: 'width: 30px;font-weight: 600;font-size: 14px;',
          style: 'width: 30px;height: 47px;padding: 0',
        }
      ],
      zoneColumn: [
        {
          name: 'type',
          label: 'Тип',
          field: 'type',
          align: 'left',
          style: 'width: 18px;padding: 0 0 0 3px;',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'name',
          label: 'Название',
          field: 'name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'placeAllowed',
          label: 'Мест',
          field: 'placeAllowed',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'busy',
          label: 'Занято',
          field: 'busy',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'free',
          label: 'Свободно',
          field: 'free',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        }
      ],
      zoneType: [false],
      points: [[]],
      loader: true,
      zonePagination: {
        sortBy: 'zone_name',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      setting: {
        id: [],
        accuracy: [],
        type: [],
        coordinates: [],
        garbage: [],
        axis: [],
        passes: 1,
        picture: null
      },
      modalActive: false,
      types: [
        {
          label: 'Обычный',
          value: 'normal'
        },
        {
          label: 'Split',
          value: 'split'
        },
        {
          label: 'Flip',
          value: 'flip'
        },
        {
          label: 'Flip+Split',
          value: 'flip/split'
        }
      ],
      showAllObject: false,
      zoneList: []
    }
  },

  computed: {

    //Данные для рисования зон
    getZonePoint() {
      const items = store.getters.getZoneListItems
      let points = []
      if (items !== undefined) {
        for (let zone of items) {
          points.push({
            points: zone.getZoneSpot(),
            type: zone.getZoneIsAllowed(),
          })
        }
      }
      return points
    },

    //Статистка с камеры с точной информацией о детекции
    getCurrentCameraStatListItems() {
      const items = store.getters.getCurrentCameraStatListItems

      let sortedItem = ''
      if(items !== undefined){
        sortedItem = this.showAllObject ? JSON.parse('[' + items.getDetection().getDetectionObjectsFull() + ']') : JSON.parse('[' + items.getDetection().getDetectionObjects() + ']')
      }

      let points = []
      let id = 1
      for(let detection of sortedItem){
        points.push({
          id: id++,
          yMin: detection.ymin,
          yMax: detection.ymax,
          xMin: detection.xmin,
          xMax: detection.xmax,
          className: detection.class_name,
          confidence: detection.confidence,
          vacantNum: detection.class_name === 'free' ? detection.vacant_num : ''
        })
      }
      return points
    },

  },

  methods: {

    onRowClick(evt, row){
      this.selectedRow = [row]
    },

    //Загрузка крайнего кадра
    loadLastFrame() {
      store
          .dispatch('getItemCamera', {id: this.$route.params.id}).then(
          (resolve) => {
            console.log('loadLastFrame resolve', resolve)
          },
          (reject) => {
            console.log('loadLastFrame reject', reject)
          }
      )
    },

    //Загрузка данных камеры
    loadData() {
      store
          .dispatch('getCamerasListItem', {
            filter: [
              {
                field_name: 'camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  id: resolve.getCameraId(),
                  name: resolve.getCameraName(),
                  status: resolve.getCameraStatus(),
                  created: resolve.getCameraCreated(),
                  model: resolve.getCameraModel(),
                  model_id: resolve.getCameraModelId(),
                  company: resolve.getCameraCompanyName(),
                  company_id: resolve.getCameraCompanyId(),
                  service: resolve.getCameraServiceName(),
                  service_id: resolve.getCameraServiceId(),
                  latitude: resolve.getCameraLatitude(),
                  longitude: resolve.getCameraLongitude(),
                }
                this.isCameraLoading = false
                this.isCameraOffline = false
                setTimeout(() => {
                  this.map()
                }, 500)
                this.loadZoneListItems(true)
                this.loadLastFrame()
                this.loadCameraParams()
              },
              (reject) => {
                this.isCameraOffline = true
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрузка настроек проходов
    loadCameraParams(){
      store
          .dispatch('getCurrentCameraParams', {
            item: this.$route.params.id
          })
          .then(
              (resolve) => {
                console.log('loadCameraParams resolve', resolve)
                let paramsList = resolve.getParamsSetList()
                paramsList.length >= 1 ? this.setting.passes = paramsList.length : this.setting.passes = 1
                this.initDetectionSettings()
                paramsList.forEach((unit,idx) => {
                  this.setting.type[idx + 1] = unit.getDtMethod()
                  this.setting.accuracy[idx + 1] = unit.getDtConfidence() ? Number(unit.getDtConfidence().toFixed(1)) : 0.5
                  this.setting.coordinates[idx + 1] = unit.getDtSegment() !== '' ? unit.getDtSegment() : 0
                  this.setting.garbage[idx + 1] = unit.getDtGarbage() ? Number(unit.getDtGarbage().toFixed(2)) : 0.95
                  this.setting.axis[idx + 1] = unit.getDtAxis() ? String(unit.getDtAxis()) : ''
                })
                this.sendToDetection()
              },
              (reject) => {
                console.log('loadCameraParams reject', reject)
              }
          )
    },

    //Загрузка списка зон камеры
    loadZoneListItems(isResetPagination) {
      if (isResetPagination) {
        this.zonePagination.page = 1
        this.zonePagination.rowsNumber = 0
      }
      store
          .dispatch('getZoneListItems', {
            filter: [
              {
                field_name: 'zone_camera_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
            sort: {
              name: this.zonePagination.sortBy,
              exp: this.zonePagination.descending ? 'DESC' : 'ASC',
            },
            pagination: {
              page: this.zonePagination.page,
              pages: 0,
              cnt: this.zonePagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getZoneListItems resolve', resolve)
                this.zonePagination.rowsNumber = resolve.pagination.getCnt()
              },
              (reject) => {
                console.log('getZoneListItems reject', reject)
              }
          )
    },

    //Запрос на детекцию
    loadCurrentCameraStatListItems(data){
      store
          .dispatch('getCurrentCameraStatListItems',{item: data}).then(
          (resolve) => {
            console.log('getCurrentCameraStatListItems', resolve)
            this.picture_url = resolve.getShotImageUrl()
            this.loader = false
            this.loadZoneListItems(true)
            this.loadLatestCameraStatListItems()
            setTimeout(() => {this.drawing()},100)
            const itemList = resolve.getStatList()
            this.zoneList = []
            itemList.forEach(item => {
              console.log(item)
              const statUnit = {
                type: item.getStatZoneIsAllowed(),
                name: item.getStatZoneName(),
                placeAllowed: item.getStatAllowedCount(),
                busy: item.getStatOccupiedCount(),
                free: item.getStatVacantCount()
              }
              this.zoneList.push(statUnit)
            })
            
          },
          (reject) => {
            console.log('getCurrentCameraStatListItems', reject)
            this.loader = false
            setTimeout(() => {this.drawError()}, 100)
          }
      )
    },

    //Запрос списка статиски камеры
    loadLatestCameraStatListItems(){
      store
          .dispatch('getLatestCameraStatListItems', {id: this.$route.params.id}).then(
          (resolve) => {
            console.log('getLatestCameraStatListItems', resolve)
          },
          (reject) => {
            console.log('getLatestCameraStatListItems', reject)
          }
      )
    },

    //Прорисовка кадра с камеры/зон/детекций
    drawing(detection) {

      setTimeout(() => {
        this.points = [[]]
        let zoneList = this.getZonePoint
        let points = []
        let regexX = /"x":\d{1,3}/gm;
        let regexY = /"y":\d{1,3}/gm;
        zoneList.forEach( ( zone ) => {
          points.push(zone.points.split('},{'))
          this.zoneType.push(zone.type)
        })
        points.forEach(( pointList ) => {
          let setSpot = []
          pointList.forEach( (point) => {
            let spotObj = {
              x: 0,
              y: 0
            }
            let spotX = point.match(regexX)
            let spotY = point.match(regexY)
            if(spotX !== null){
              spotX.forEach( (spot) => {
                spotObj.x = Number(spot.replace('"x":',''))
              })
            }
            if(spotY !== null){
              spotY.forEach( (spot) => {
                spotObj.y = Number(spot.replace('"y":',''))
              })
            }
            setSpot.push(spotObj)
          })
          this.points.push(setSpot)

        })
      }, 100)

      setTimeout( () => {
        let detections = []
        this.getCurrentCameraStatListItems.forEach( detection => {

          const dtcn = {
            type: detection.className,
            points: [{x: detection.xMin,y:detection.yMax },{x: detection.xMax, y: detection.yMax}, {x: detection.xMax, y: detection.yMin}, {x: detection.xMin, y: detection.yMin}]
          }

          detections.push(dtcn)
        })

        let canvas = document.getElementById("canvas");
        canvas.width = 900;
        canvas.height = 500;
        let ctx = canvas.getContext("2d");
        const cameraImage = new Image();
        cameraImage.src = this.picture_url;
        const zoneType = this.zoneType
        let points = this.points
        cameraImage.onload = function() {
          ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
          points.forEach((point,idx) => {
            if(zoneType[idx]){
              ctx.fillStyle = 'rgba(0,232,39,0.2)';
              ctx.strokeStyle = 'rgba(0,232,39,0.2)';
            }else{
              ctx.fillStyle = 'rgba(232,0,0,0.2)';
              ctx.strokeStyle = 'rgba(232,0,0,0.2)';
            }
            let closed = true;
            ctx.beginPath();
            for (const p of point) {
              ctx.lineTo(p.x, p.y)
            }
            closed && ctx.closePath();
            ctx.stroke();
            closed && ctx.fill();
            for (const p of point) {
              ctx.beginPath();
              ctx.moveTo(p.x + 10, p.y);
              ctx.fill();
              ctx.stroke();
            }

          })
          if(detections){
            detections.forEach(detection => {
              detection.type === 'free' ? ctx.strokeStyle = 'rgba(0,217,255,0.6)' : ctx.strokeStyle = 'rgb(0,232,39)';
              detection.type === 'free' ? ctx.fillStyle = 'rgba(0,217,255,0.4)' : '';
              ctx.lineWidth = 3;
              let closed = true;
              ctx.beginPath();
              for (const p of detection.points) {
                ctx.lineTo(p.x, p.y)
              }
              closed && ctx.closePath();
              ctx.stroke();
              if(detection.type === 'free'){
                closed && ctx.fill();
                for (const p of detection.points) {
                  ctx.beginPath();
                  ctx.moveTo(p.x + 10, p.y);
                  ctx.fill();
                  ctx.stroke();
                }
              }
            })
          }
          if(detection){
            let detectionPoints = [ {x: detection.xMin,y:detection.yMax },{x: detection.xMax, y: detection.yMax}, {x: detection.xMax, y: detection.yMin}, {x: detection.xMin, y: detection.yMin} ]
            ctx.strokeStyle = 'rgb(232,0,0)';
            ctx.lineWidth = 3;
            let closed = true;
            ctx.beginPath();
            for (const p of detectionPoints) {
              ctx.lineTo(p.x, p.y)
            }
            closed && ctx.closePath();
            ctx.stroke();
          }
        }
      },150)

    },

    //Прорисовка ошибки кадра
    drawError(){
      let cameraImage = new Image();
      cameraImage.src = 'https://mediapure.ru/wp-content/uploads/2017/12/error-1021x580.jpg';
      let canvas = document.getElementById("canvas");
      canvas.width = 900;
      canvas.height = 500;
      let ctx = canvas.getContext("2d");
      cameraImage.onload = function() {
        ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
      }
    },

    //Активация модального окна
    modalActivator(id){
      this.modalActive = true
      setTimeout(() => {
        this.draw(id)
        /*this.drawCoordinates(id)*/
      },150)
    },

    //Функция задачи координат на кадре
    draw(passId){ 
      let canvas = this.__canvas = new fabric.Canvas('coordinates_canvas'),
          deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

      fabric.Image.fromURL(this.picture_url, (img) => {
        img.set({
          id: 1,
          width : canvas.width,
          height : canvas.height,
          selectable: false
        });
        canvas.add(img).renderAll();
        let savedCoordinates
        if(this.setting.coordinates[passId+1] !== undefined && JSON.parse(this.setting.coordinates[passId+1])){
          savedCoordinates = {
            top: JSON.parse(this.setting.coordinates[passId+1]).ymin,
            left: JSON.parse(this.setting.coordinates[passId+1]).xmin,
            width: JSON.parse(this.setting.coordinates[passId+1]).xmax - JSON.parse(this.setting.coordinates[passId+1]).xmin,
            height: JSON.parse(this.setting.coordinates[passId+1]).ymax - JSON.parse(this.setting.coordinates[passId+1]).ymin
          }

          let rect = new fabric.Rect({
            top : savedCoordinates.top,
            left : savedCoordinates.left,
            width : savedCoordinates.width,
            height : savedCoordinates.height,
            fill : 'transparent',
            stroke: 'red',
            strokewidth: 3
          });

          canvas.add(rect);
        }
      });

      let img = document.createElement('img');
      img.src = deleteIcon;

      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = 'blue';
      fabric.Object.prototype.cornerStyle = 'circle';

      const deleteObject = (eventData, transform) => {
        let target = transform.target;
        let canvas = target.canvas;
        this.setting.coordinates[passId+1] = 0;
        canvas.remove(target);
        canvas.requestRenderAll();
      }

      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: deleteObject,
        render: renderIcon,
        cornerSize: 24
      });

      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        let size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size/2, -size/2, size, size);
        ctx.restore();
      }

      canvas.on('mouse:down', function (option) {
        if (option.target === null || option.target.id === 1) {
          let startY = option.e.offsetY,
              startX = option.e.offsetX;

          let rect = new fabric.Rect({
            top : startY,
            left : startX,
            width : 0,
            height : 0,
            fill : 'transparent',
            stroke: 'red',
            strokewidth: 3
          });

          rect.setControlsVisibility({ mtr: false })

          canvas.add(rect);

          canvas.on('mouse:move', function (option) {
            let e = option.e;
            rect.set('width', e.offsetX - startX);
            rect.set('height', e.offsetY - startY);
            rect.setCoords();
          });


        }
      });

      let coordinates = []

      canvas.on('mouse:up', () => {
        canvas._objects.length > 2 ? canvas._objects.splice(1,1) : ''
        coordinates = []
        canvas._objects.forEach((unit,idx) => {
          let coordinate = {
            xmin: 10000,
            ymin: 10000,
            xmax: 0,
            ymax: 0
          }
          unit.width < 20 || unit.height < 20 ? canvas._objects.splice(idx,1) : ''
          if(unit.aCoords && idx !== 0){
            for(let coords in unit.aCoords){
              unit.aCoords[coords].x < coordinate.xmin ? coordinate.xmin = Number(unit.aCoords[coords].x.toFixed(1)) : ''
              unit.aCoords[coords].y < coordinate.ymin ? coordinate.ymin = Number(unit.aCoords[coords].y.toFixed(1)) : ''
              unit.aCoords[coords].x > coordinate.xmax ? coordinate.xmax = Number(unit.aCoords[coords].x.toFixed(1)) : ''
              unit.aCoords[coords].y > coordinate.ymax ? coordinate.ymax = Number(unit.aCoords[coords].y.toFixed(1)) : ''
            }
          }
          coordinates.push(coordinate)
        })
        coordinates.shift()
        coordinates.forEach( unit => {
          this.setting.coordinates[passId+1] = JSON.stringify(unit)
        })
        canvas.off('mouse:move');

      });

    },

    //Получение адреса
    async map() {

      await loadYmap({apiKey: 'e98d34f8-157a-43e9-9dc5-46d8dd7e4f59'}, {debug: true})

      const cameraCoordinates = [this.item.latitude,this.item.longitude]
      const init = () => {
        let names = []
        window.ymaps.geocode(cameraCoordinates).then((res) => {
          res.geoObjects.each( (obj) => {
            names.push(obj.properties.get('name'));
          })
          this.item.address = names[0]
          this.addressLoader = false
        })
      }
      window.ymaps.ready(init)

    },

    //Формирование объекта для отправки на детекцию по клику
    sendToDetection(){

      let data = {
        camera_id: Number(this.$route.params.id),
        local_image: this.setting.picture === null ? '' : this.setting.picture,
        params_set: []
      };

      this.setting.accuracy.forEach((unit,idx) => {

        let coordinate
        if(this.setting.coordinates[idx] !== undefined && JSON.parse(this.setting.coordinates[idx])){
          coordinate = {
            ymin: Math.floor(JSON.parse(this.setting.coordinates[idx]).ymin),
            xmin: Math.floor(JSON.parse(this.setting.coordinates[idx]).xmin),
            ymax: Math.floor(JSON.parse(this.setting.coordinates[idx]).ymax),
            xmax: Math.floor(JSON.parse(this.setting.coordinates[idx]).xmax)
          }
        }

        let param = {
          dt_params_id: Number(this.$route.params.id),
          dt_camera_id: Number(this.$route.params.id),
          dt_method: this.setting.type[idx] ? this.setting.type[idx] : '',
          dt_confidence: this.setting.accuracy[idx] ? Number(this.setting.accuracy[idx]) : '',
          dt_segment: this.setting.coordinates[idx] ? coordinate : '',
          dt_garbage: this.setting.garbage[idx] ? Number(this.setting.garbage[idx]) : '',
          dt_axis: this.setting.axis[idx] ? Number(this.setting.axis[idx]) : ''
        }
        data.params_set.push(param)
      })

      data.params_set.length = this.setting.passes

      if(data.local_image !== ''){
        const file = data.local_image;
        const reader = new FileReader();

        reader.onloadend = () => {
          data.local_image = (reader.result);
          this.loadCurrentCameraStatListItems(data)
        }
        reader.readAsDataURL(file);
      }else{
        this.loadCurrentCameraStatListItems(data)
      }
    },

    //Сохранение параметров проходов камеры
    savePrams(){
      let data = {
        dt_camera_id: Number(this.$route.params.id),
        params_set: []
      }

      this.setting.accuracy.forEach((unit,idx) => {
        let param = {
          dt_camera_id: Number(this.$route.params.id),
          dt_method: this.setting.type[idx],
          dt_confidence: Number(this.setting.accuracy[idx]),
          dt_segment: this.setting.coordinates[idx] ? String(this.setting.coordinates[idx]) : '',
          dt_garbage: Number(this.setting.garbage[idx]),
          dt_axis: this.setting.axis[idx] ? Number(this.setting.axis[idx]) : ''
        }
        data.params_set.push(param)
      })

      data.params_set.length = this.setting.passes

      store.dispatch('updateCameraParams', data).then(
          (resolve) => {
            console.log('resolve', resolve)
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
    },

    initDetectionSettings() {
      for (let i = 1; i <= this.setting.passes; i++) {
        this.setting.type[i] = ''
        this.setting.accuracy[i] = 0.5
        this.setting.garbage[i] = 0.95
        this.setting.coordinates[i] = ''
        this.setting.axis[i] = ''
      }
    }

  },

  mounted() {
    this.loadData()
  },
})

</script>

<style lang="scss">

.detection{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1440px;
  margin: 0 auto 40px auto;
  &__left{
    display: flex;
    flex-direction: column;
    max-width: 570px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    height: fit-content;
    &-information{
      p{
        padding: 15px 0;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        span{
          font-weight: 400;
          padding: 0 0 0 5px;
        }
        &:first-child{
          padding: 0 0 15px 0;
        }
      }
      .success, .error,.pending {
        background: #21BA45;
        color: #fff;
        padding: 2px 5px 3px 5px;
        margin: -3px 0 0 15px;
        border-radius: 6px;
      }

      .error {
        background: #c00706;
      }
      .link{
        color: #027BE3;
        cursor: pointer;
      }
      .pending{
        background: #c08b06;
      }
    }
    .q-table__container{
      margin: 20px 0 0 0;
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    }
    &-manual{
      display: flex;
      flex-wrap: wrap;
      .q-form{
        width: 100%;
      }
      p{
        width: 100%;
        margin: 25px 0;
        padding: 15px 0 0 0;
        font-size: 16px;
        font-weight: 600;
      }
      button{
        margin: 20px 0 0 0;
        background: #3742fa !important;
      }
      &__header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .detection__left-manual__input{
          display: flex;
          flex-direction: column;
          width: 100%;
          .q-field__control{
            color: #3742fa !important;
          }
          .q-slider{
            margin: auto 0;
            padding: 0 8px;
            .q-slider__thumb,.q-slider__track{
              color: #3742fa !important;
            }
          }
          p{
            padding: 0;
          }
        }
      }
      &__input{
        .q-radio__label{
          color: #000;
        }
        p{
          padding: 15px 0 0 0;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
        }
      }
      &__passes{
        margin: 25px 0 0 0;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        p{
          width: 100%;
          padding: 15px 0 0 0;
          font-size: 14px;
          font-weight: 600;
          margin: 0;
        }
        .detection__left-manual__input{
          &:nth-child(2){
            width: calc(45% - 15px);
            .q-field__control{
              height: 40px;
              padding: 0;
            }
          }
          &:nth-child(3){
            width: calc(55% - 15px);
            display: flex;
            flex-direction: column;
            .q-slider{
              margin: auto 0;
              padding: 0 8px;
              .q-slider__thumb,.q-slider__track{
                color: #3742fa !important;
              }
            }
          }
          &:nth-child(4),&:nth-child(6){
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .q-radio{
              &:nth-child(2){
                margin: 0 0 0 -10px;
              }
            }
            .q-radio__inner--truthy{
              color: #3742fa !important;
            }
          }
          &:nth-child(5){
            width: 100%;
            display: flex;
            flex-direction: column;
            .q-slider{
              margin: auto 0;
              padding: 0 8px;
              .q-slider__thumb,.q-slider__track{
                color: #3742fa !important;
              }
            }
          }
          &.coordinates-input{
            input{
              cursor: pointer;
            }
          }
        }
      }
    }
    .q-form{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .save{
        margin: 20px auto 0 0;
      }
    }

  }
  &__right{
    display: flex;
    flex-direction: column;
    max-width: 850px;
    height: fit-content;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    &-screen{
      position: relative;
      width: 100%;
      height: 450px;
      .q-spinner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    canvas{
      width: 100%;
      height: 450px;
      object-fit: cover;
      border: 1px solid #000;
    }
    p{
      padding: 0;
      font-size: 18px;
      margin: 0 0 25px 0;
    }
    .q-table__container{
      margin: 20px 0 0 0;
      border-radius: 10px;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      .table-coordinates{
        margin: 0;
        display: flex;
        align-items: center;
        p{
          margin: 0 15px 0 0;
          font-size: 14px;
          font-weight: 600;
          span{
            font-weight: 400;
            padding: 0 0 0 5px;
          }
        }
      }
      .action{
        .q-list{
          height: 47px;
        }
      }
      .q-table__bottom--nodata{
        height: 290px;
        position: relative;
        .q-spinner{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
}
.coordinates{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  canvas{
    width: 900px;
    height: 500px;
  }
  &__navigation{
    display: flex;
    justify-content: flex-end;
    .q-btn{
      margin: 0 0 0 15px;
      background: #3742fa !important;
    }
  }
}

</style>
<template>
  <div class="statistic-list">
    <q-card style="box-shadow: none">
      <div class="row">
        <div class="col-12">
          <div class="pre-header">
            <h3 style="margin: 0;">Статистика парковки: {{ getStatListItems.length }}</h3>
          </div>
          <q-table
              flat
              :rows="getStatListItems"
              :columns="columns"
              row-key="id"
              @request="onRequestStatistic"
              v-model:pagination="statisticPagination"
          >
            <template v-slot:top>
              <q-input
                  dense
                  v-model="selectedCameraUuid"
                  filled
                  label="UUID камеры"
              >
              </q-input>
              <q-select
                  v-model="selectedCameraId"
                  :options="getCamerasNameList"
                  filled
                  emit-value
                  map-options
                  label="Название камеры"
              ></q-select>
              <q-input
                  dense
                  v-model="selectedZoneUuid"
                  filled
                  label="ID"
              >
              </q-input>
              <q-select
                  v-model="selectedZoneId"
                  :options="getZoneNameList"
                  filled
                  emit-value
                  map-options
                  label="Название зоны"
              ></q-select>
              <q-btn class="button" @click="clearSelect">Очистить</q-btn>
              <q-btn class="button" @click="search">Отфильтровать</q-btn>
            </template>
            <template v-slot:body-cell-camera_name="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-zone_name="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-shot_created="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-occupied_count="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-allowed_count="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item clickable @click="actionRow('watch', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </q-card>
  </div>
</template>



<script lang="ts">

/*import store from '@/store'*/
import {defineComponent} from 'vue'
import store from "@/store";

export default defineComponent({
  name: "StatisticList",

  data(){
    return{
      columns: [
        {
          name: 'camera_name',
          label: 'Камера',
          field: 'camera_name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'zone_name',
          label: 'Зона',
          field: 'zone_name',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'shot_created',
          label: 'Дата снимка',
          field: 'shot_created',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'occupied_count',
          label: 'Занято мест',
          field: 'occupied_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'allowed_count',
          label: 'Разрешено',
          field: 'allowed_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'detected_count',
          label: 'Объекты',
          field: 'detected_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: '',
          align: 'left',
          field: 'id',
          headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
          style: 'width: 40px'
        }
      ] as any,
      selectedCameraUuid: null as any,
      selectedCameraId: '' as any,
      selectedZoneUuid: null as any,
      selectedZoneId: '' as any,
      statisticPagination: {
        sortBy: 'stat_shot_created',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      selectedDetectionId: null as any
    }
  },

  computed: {

    isStatListBusy() {
      return store.getters.getIsStatListBusy
    },

    //Формирование списка статистик
    getStatListItems() {
      const items = store.getters.getStatListItems
      let rows: any = []
      if (items !== undefined) {
        for (let item of items) {
          rows.push({
            id: item.getStatId(),
            camera_id: item.getStatCameraId(),
            camera_name: item.getStatCameraName(),
            zone_id: item.getStatZoneId(),
            zone_name: item.getStatZoneName(),
            shot_created: item.getStatShotCreated(),
            occupied_count: item.getStatOccupiedCount(),
            allowed_count: item.getStatAllowedCount(),
            detected_count: item.getStatDetectedCount()
          })
        }
      }
      return rows
    },

    //Формирование списка пользователей для фильтра
    getCamerasNameList() {
      const items = store.getters.getCamerasListItems

      let rows: any = []
      if (items !== undefined) {
        for (let camera of items) {
          rows.push({
            value: camera.getCameraId(),
            label: camera.getCameraName()
          })
        }
      }
      return rows
    },

    //Формирование списка зон для фильтра
    getZoneNameList(){
      const zones = store.getters.getZoneListItems

      let rows: any = []
      if (zones !== undefined) {
        for (let zone of zones) {
          rows.push({
            value: zone.getZoneId(),
            label: zone.getZoneName()
          })
        }
      }
      return rows
    }

  },

  methods: {

    //Просмотр детекции
    watchDetection(){
      this.$router.push('/statistic/' + this.selectedDetectionId)
    },

    //Функционал кнопок в таблице
    actionRow(method: any, id: any) {
      this.selectedDetectionId = id
      method === 'watch' ? this.watchDetection() : console.log('Неизвестный метод')
    },

    //Запрос к таблице с статистикой
    onRequestStatistic(props: any) {
      console.log('onRequestStatistic props', props)
      this.statisticPagination = props.pagination
      this.loadStatisticListItems()
    },

    //Фильтры статистики
    getFilters() {
      let filter = []
      if(this.selectedCameraUuid > 0){
        filter.push({
          field_name: 'stat_camera_id',
          operand: '=',
          value: this.selectedCameraUuid
        })
      }
      if(this.selectedCameraId > 0){
        filter.push({
          field_name: 'stat_camera_id',
          operand: '=',
          value: this.selectedCameraId
        })
      }
      if(this.selectedZoneUuid > 0){
        filter.push({
          field_name: 'stat_zone_id',
          operand: '=',
          value: this.selectedZoneUuid
        })
      }
      if(this.selectedZoneId > 0){
        filter.push({
          field_name: 'stat_zone_id',
          operand: '=',
          value: this.selectedZoneId
        })
      }

      else{
        filter.push({
          field_name: 'stat_camera_id',
          operand: '>',
          value: 0
        })
      }
      return filter
    },

    //Загрузика списка статистики
    loadStatisticListItems() {
      store
          .dispatch('getStatListItems', {
            filter: this.getFilters(),
            sort: {
              name: 'stat_shot_created',
              exp: 'desc',
            },
            pagination: {
              page: this.statisticPagination.page,
              limit: this.statisticPagination.rowsPerPage,
              pages: 0,
              cnt: this.statisticPagination.rowsNumber,
            },
          })
          .then(
              (resolve) => {
                console.log('getStatListItems resolve', resolve)
                this.statisticPagination.rowsNumber = resolve.pagination.getPages()
              },
              (reject) => {
                console.log('getStatListItems reject', reject)
              }
          )
    },

    //Поиск
    search(){
      this.loadStatisticListItems()
    },

    //Очистка поиска
    clearSelect() {
      this.selectedCameraUuid = null as any
      this.selectedCameraId = ''
      this.selectedZoneUuid = null as any
      this.selectedZoneId = ''
      this.loadStatisticListItems()
    },

    //Загрузка списка камер
    loadCamerasList() {
      store.dispatch('getCamerasListItems', {})
          .then(
              (resolve) => {
                console.log('getCamerasListItems resolve', resolve)
              },
              (reject) => {
                console.log('getCamerasListItems reject', reject)
              }
          )
    },

    //Загрузка списка зон
    loadZoneList(){
      store.dispatch('getZoneListItems',{})
          .then(
              (res) => {
                console.log('zoneList success', res)
              },
              (rej) => {
                console.log('zoneList error', rej)
              }
          )
    }

  },

  mounted() {
    this.loadZoneList()
    this.loadCamerasList()
    this.loadStatisticListItems()
  },

})
</script>

<style lang="scss">

.statistic-list{
  max-width: 1440px;
  margin: 0 auto;

  .pre-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
    }
    .q-btn{
      padding: 12px 16px;
      background: #3742fa !important;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
    }
  }

  .q-table__top {
    padding: 20px !important;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    border-radius: 10px !important;
    .q-field{
      max-width: 215px;
      &__native{
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__control{
        border-radius: 5px;
      }
    }
    .q-field__native {
      min-width: 150px;
    }
    .q-field__inner {
      margin: 0 15px 0 0;
    }

    button{
      margin: 0 0 0 auto;
      height: 50px;
      width: 170px;
      border-radius: 10px;
      background: #3742fa !important;
      color: #fff;
      font-weight: 600;
      &:last-child{
        margin: 0 0 0 20px;
      }
    }
  }

  .q-table__middle{
    margin: 35px 0 0 0;
    border-radius: 10px !important;
    padding: 20px !important;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    .q-td{
      vertical-align: middle;
    }
  }

  .q-table__bottom{
    border-top: unset;
  }

  .filter {
    margin: 0 0 0 25px;
    padding: 9px 15px;
    font-size: 15px;
  }
  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal{
    height: 56px;

  }
  .q-field--dense .q-field__label{
    font-size: 16px !important;
    top: 18px !important;
  }
}


</style>
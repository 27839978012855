import ZoneStatistic from "../views/zone/ZoneStatistic.vue";
import ZoneAdd from "../views/zone/ZoneAdd.vue"
import ZoneEdit from "../views/zone/ZoneEdit.vue"

const routes = [
    {
        path: '/cameras/watch/:id/zone/:selectedZoneId',
        name: 'ZoneStatistic',
        component: ZoneStatistic,
        meta: {
            breadCrumb: [
                { text: 'Камеры', to: { name: 'CameraList' }},
                { text: 'Камера', to: { name: 'CameraWatch'}},
                { text: 'Статистика зоны' }
            ],
        }
    },
    {
        path: '/cameras/watch/:id/zone-add',
        name: 'ZoneAdd',
        component: ZoneAdd,
        meta: {
            breadCrumb: [
                { text: 'Камеры', to: { name: 'CameraList' }},
                { text: 'Камера', to: { name: 'CameraWatch'}},
                { text: 'Добавление зоны' }
            ],
        }
    },
    {
        path: '/cameras/watch/:id/zone-edit/:selectedZoneId',
        name: 'ZoneEdit',
        component: ZoneEdit,
        meta: {
            breadCrumb: [
                { text: 'Камеры', to: { name: 'CameraList' }},
                { text: 'Камера', to: { name: 'CameraWatch'}},
                { text: 'Изменение зоны' }
            ],
        }
    },
]

export default {
    routes
}
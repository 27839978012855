import {
    ChangeServicePromiseClient
} from "../../proto/pb/change_grpc_web_pb"
import {
    ListChangeRequest,
    Change,
    ChangeFilter,
    ChangeFilterItem,
    ChangePagination,
    ChangeSort,
    ListChangeObjectsRequest,
    ListChangeEventsRequest
} from "../../proto/pb/change_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';



export class ChangeApi {
    items: Array<Change> = [];
    client: ChangeServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new ChangeServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new ChangeFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new ChangeFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new ChangeSort();
        const name:string = data.name !== undefined ? data.name.toString() : 'change_id';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'desc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new ChangePagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : 100000;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getChangeListItems(filter: any, sort: any, pagination: any){

        const request = new ListChangeRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listChange(request, getAuthToken())
    }

    changeTypes(){
        const request = new ListChangeEventsRequest()
        
        return this.client.listChangeEvents(request, getAuthToken())
    }

    changeEvents(){
        const request = new ListChangeObjectsRequest()
        
        return this.client.listChangeObjects(request, getAuthToken())
    }

}


<template>
  <q-card style="width: 1024px; max-width: 80vw" class="q-pa-md">
    <q-item>
      <q-card-section>
        <q-item-label class="text-h5">
          <p>Удаление системного пользователя</p>
          <p>«{{ item.name }}»</p>
        </q-item-label>
      </q-card-section>
    </q-item>

    <q-separator />

    <div v-if="!isUsersListBusy">
      <q-card-section>
        <p>Вы действительно хотите удалить пользователя ?</p>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn color="negative" @click="actualDelete"> Удалить </q-btn>
        <q-btn color="primary" @click="closeDelete"> Отмена </q-btn>
      </q-card-actions>
    </div>
  </q-card>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserDelete',

  props: {
    userId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      item: {
        id: 0,
        name: '',
      },
    }
  },

  computed: {
    isUsersListBusy() {
      return store.getters.getIsUsersListBusy
    },
  },

  methods: {

    //Удаление пользователя
    actualDelete() {
      store.dispatch('deleteUsersListItem', { id: this.userId }).then(
        (resolve) => {
          console.log('resolve', resolve)
          this.$emit('afterAction')
        },
        (reject) => {
          console.log('reject', reject)
        }
      )
    },

    //Закрыть удаление пользователя
    closeDelete() {
      this.$emit('hideModal')
    },

    //Загрузка данных пользователя
    loadData() {
      store
        .dispatch('getUsersListItem', {
          filter: [
            {
              field_name: 'user_id',
              operand: '=',
              value: this.userId,
            },
          ],
        })
        .then(
          (resolve) => {
            console.log('loadData resolve', resolve)
            this.item = {
              id: resolve.getUserId(),
              name: resolve.getUsername(),
            }
          },
          (reject) => {
            console.log('loadData reject', reject)
          }
        )
    },
  },

  created() {
    this.loadData()
  },
})
</script>

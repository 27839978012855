import {
    CompanyServicePromiseClient
} from "../../proto/pb/company_grpc_web_pb";
import {
    CreateCompanyRequest,
    DeleteCompanyRequest,
    ListCompanyRequest,
    UpdateCompanyRequest,
    Company,
    CompanyFilter,
    CompanyFilterItem,
    CompanyPagination,
    CompanySort,
    LinkCompanyUserRequest,
    ListUsersInCompanyRequest
} from "../../proto/pb/company_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class CompanyApi {
    items: Array<Company> = [];
    client: CompanyServicePromiseClient;
    metadata: any;

    constructor() {
        const host:string = (window as any).VUE_APP_API_URL ?? '';
        this.client = new CompanyServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
    }

    // Установка и получение фильтров
    createFilter(data: any){

        const filter = new CompanyFilter();

        // Устанавливаем фильтры
        if (data && data.length) {
            for (const i in data) {
                if (data[i].field_name && ('' !== data[i].field_name)) {
                    const fItem = new CompanyFilterItem();
                    fItem.setFieldName(data[i].field_name.toString());
                    fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                    fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                    fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                    filter.addItems(fItem);
                }
            }
        }

        return filter;
    }

    // Установка и получение сортировки
    createSort(data: any){

        const sort = new CompanySort();
        const name:string = data.name !== undefined ? data.name.toString() : 'company_name';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    // Установка и получение пагинации
    createPagination(data: any){

        const pagination = new CompanyPagination();
        const page:number = data.page !== undefined ? Number(data.page) : 1;
        const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
        const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
        const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

        // Устанавливаем пагинацию
        pagination.setPage(page);
        pagination.setLimit(limit);
        pagination.setPages(pages);

        return pagination;
    }

    // Список элементов
    getCompanyListItems(filter: any, sort: any, pagination: any){

        const request = new ListCompanyRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listCompany(request, getAuthToken())
    }

    // Получение элемента
    getCompanyListItem(filter: any){

        const request = new ListCompanyRequest();

        // Устанавливаем параметры запроса
        request.setFilter(this.createFilter(filter));
        request.setSort(this.createSort({}));
        request.setPagination(this.createPagination({}));

        return this.client.listCompany(request, getAuthToken())
    }

    // Создание элемента
    createCompanyItem(data: any){

        const request = new CreateCompanyRequest();
        request.setCompanyName(data.name)

        return this.client.createCompany(request, getAuthToken())
    }

    // Редактировать элемент
    updateCompanyItem(data: any){

        const request = new UpdateCompanyRequest();
        request.setCompanyId(data.id)
        request.setCompanyName(data.name)

        return this.client.updateCompany(request, getAuthToken())
    }

    // Удаление элемента
    deleteCompanyItem(data: any){

        const request = new DeleteCompanyRequest();
        request.setCompanyId(data.id);

        return this.client.deleteCompany(request, getAuthToken())
    }

    linkCompanyUserItem(data: any){
        const request = new LinkCompanyUserRequest();
        request.setUsersId(data.user_id)
        request.setCompaniesId(data.company_id)
        request.setAction(data.action)

        return this.client.linkCompanyUser(request, getAuthToken())
    }

    createCompanyUsersSort(data: any){

        const sort = new CompanySort();
        const name:string = data.name !== undefined ? data.name.toString() : 'first_name';
        const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

        // Устанавливаем параметры сортировки
        sort.setName(name);
        sort.setExp(exp);

        return sort;
    }

    getCompanyUsersListItems(data: any,sort: any, pagination: any){

        const request = new ListUsersInCompanyRequest();

        // Устанавливаем параметры запроса
        request.setCompanyId(data.id);
        request.setSort(this.createCompanyUsersSort(sort));
        request.setPagination(this.createPagination(pagination));

        return this.client.listUsersInCompany(request, getAuthToken())
    }

}
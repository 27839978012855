/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v4.24.4
// source: zone.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.proto = require('./zone_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ZoneServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.proto.ZoneServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.CreateZoneRequest,
 *   !proto.proto.CreateZoneResponse>}
 */
const methodDescriptor_ZoneService_CreateZone = new grpc.web.MethodDescriptor(
  '/proto.ZoneService/CreateZone',
  grpc.web.MethodType.UNARY,
  proto.proto.CreateZoneRequest,
  proto.proto.CreateZoneResponse,
  /**
   * @param {!proto.proto.CreateZoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.CreateZoneResponse.deserializeBinary
);


/**
 * @param {!proto.proto.CreateZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.CreateZoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.CreateZoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ZoneServiceClient.prototype.createZone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ZoneService/CreateZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_CreateZone,
      callback);
};


/**
 * @param {!proto.proto.CreateZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.CreateZoneResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ZoneServicePromiseClient.prototype.createZone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ZoneService/CreateZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_CreateZone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.UpdateZoneRequest,
 *   !proto.proto.UpdateZoneResponse>}
 */
const methodDescriptor_ZoneService_UpdateZone = new grpc.web.MethodDescriptor(
  '/proto.ZoneService/UpdateZone',
  grpc.web.MethodType.UNARY,
  proto.proto.UpdateZoneRequest,
  proto.proto.UpdateZoneResponse,
  /**
   * @param {!proto.proto.UpdateZoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.UpdateZoneResponse.deserializeBinary
);


/**
 * @param {!proto.proto.UpdateZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.UpdateZoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.UpdateZoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ZoneServiceClient.prototype.updateZone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ZoneService/UpdateZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_UpdateZone,
      callback);
};


/**
 * @param {!proto.proto.UpdateZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.UpdateZoneResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ZoneServicePromiseClient.prototype.updateZone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ZoneService/UpdateZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_UpdateZone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.DeleteZoneRequest,
 *   !proto.proto.DeleteZoneResponse>}
 */
const methodDescriptor_ZoneService_DeleteZone = new grpc.web.MethodDescriptor(
  '/proto.ZoneService/DeleteZone',
  grpc.web.MethodType.UNARY,
  proto.proto.DeleteZoneRequest,
  proto.proto.DeleteZoneResponse,
  /**
   * @param {!proto.proto.DeleteZoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.DeleteZoneResponse.deserializeBinary
);


/**
 * @param {!proto.proto.DeleteZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.DeleteZoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.DeleteZoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ZoneServiceClient.prototype.deleteZone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ZoneService/DeleteZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_DeleteZone,
      callback);
};


/**
 * @param {!proto.proto.DeleteZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.DeleteZoneResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ZoneServicePromiseClient.prototype.deleteZone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ZoneService/DeleteZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_DeleteZone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.proto.ListZoneRequest,
 *   !proto.proto.ListZoneResponse>}
 */
const methodDescriptor_ZoneService_ListZone = new grpc.web.MethodDescriptor(
  '/proto.ZoneService/ListZone',
  grpc.web.MethodType.UNARY,
  proto.proto.ListZoneRequest,
  proto.proto.ListZoneResponse,
  /**
   * @param {!proto.proto.ListZoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.proto.ListZoneResponse.deserializeBinary
);


/**
 * @param {!proto.proto.ListZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.proto.ListZoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.proto.ListZoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.proto.ZoneServiceClient.prototype.listZone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/proto.ZoneService/ListZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_ListZone,
      callback);
};


/**
 * @param {!proto.proto.ListZoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.proto.ListZoneResponse>}
 *     Promise that resolves to the response
 */
proto.proto.ZoneServicePromiseClient.prototype.listZone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/proto.ZoneService/ListZone',
      request,
      metadata || {},
      methodDescriptor_ZoneService_ListZone);
};


module.exports = proto.proto;


import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "company-edit" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_CompanyDelete = _resolveComponent("CompanyDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-pa-md" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { class: "text-h5" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Изменить компанию ")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (!_ctx.isCompanyListBusy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_q_form, {
                onSubmit: _ctx.saveCompany,
                greedy: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_input, {
                        modelValue: _ctx.item.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event)),
                        label: "Название",
                        filled: "",
                        "lazy-rules": "",
                        rules: [
              (val) => (val && val.length > 0) || 'Поле должно быть заполнено',
            ]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_q_card_actions, { style: {"display":"flex","justify-content":"space-between","padding":"16px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_btn, {
                        color: "positive",
                        type: "submit"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Сохранить")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_btn, {
                        color: "negative",
                        onClick: _ctx.deleteCompany
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Удалить")
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_q_btn, {
                        color: "primary",
                        onClick: _ctx.closeCompanyEdit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Отмена")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isActiveModal) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalDelete)
          ? (_openBlock(), _createBlock(_component_CompanyDelete, {
              key: 0,
              companyId: _ctx.item.id,
              companyName: _ctx.item.name,
              onAfterAction: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('afterAction'))),
              onHideModal: _ctx.hideModal
            }, null, 8, ["companyId", "companyName", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
<template>
  <q-form class="manual-detection"
          @submit="sendToDetection"
          greedy
  >
    <q-card class="manual-detection__unit" v-for="(pass,index) in detection.length" :key="pass">
      <q-card-section class="manual-detection__settings">
        <q-input
            v-model="detection[index].params"
            label="Параметры детекции"
            color="indigo-14"
            filled
            type="textarea"
        />
        <q-file
            name="picture"
            v-model="detection[index].picture"
            label="Выбрать изображение"
            color="indigo-14"
            lazy-rules
            max-file-size="1048576"
            :rules="[
              (val) => (val && val.length !== 0) || 'Необходимо загрузить изображение',
            ]"
        />
      </q-card-section>
      <q-card-section v-if="detectionResponse" class="manual-detection__output">
        <canvas :id='this.detection[index].canvasName'></canvas>
        <q-table
            flat
            :rows="this.detection[index].detectionsResponse"
            :columns="detectionColumns"
            key="id"
        >
          <template v-slot:body-cell-coordinates="props">
            <q-td class="table-coordinates" :props="props">
              <p>yMin: <span>{{ props.row.ymin }}</span></p>
              <p>xMin: <span>{{ props.row.xmin }}</span></p>
              <p>yMax: <span>{{ props.row.ymax }}</span></p>
              <p>xMax: <span>{{ props.row.xmax }}</span></p>
            </q-td>
          </template>
          <template v-slot:body-cell-action="props">
            <q-td class="action" :props="props">
              <q-list>
                <q-item clickable @click="drawing(this.detection[index].id,props.rowIndex)">
                  <q-item-section>
                    <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                            d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z"
                            stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-td>
          </template>
        </q-table>
      </q-card-section>
      <q-btn class="manual-detection__close" @click="removeDetection(index)">
        <svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>
      </q-btn>
    </q-card>
    <div class="q-gutter-sm manual-detection__navigation">
      <q-btn color="indigo-14" @click="addDetection">Добавить</q-btn>
      <q-btn color="indigo-14" type="submit">Отправить</q-btn>
    </div>
  </q-form>
</template>

<script lang="js">

import { defineComponent,ref } from "vue";
import store from "@/store";

export default defineComponent({

  name: 'CameraDetection',

  data() {
    return {
      detection: [
        {
          id: [],
          params: [],
          picture: [],
          pictureData: [],
          pictureResponse: [],
          detectionsResponse: [],
          altResponse: []
        }
      ],
      detectionColumns: [
        {
          name: 'class_name',
          label: 'Тип',
          field: 'class_name',
          align: 'left',
          style: 'width: 100px',
          headerStyle: 'font-weight: 600;font-size: 14px;width: 100px'
        },
        {
          name: 'confidence',
          label: 'Схожесть',
          field: 'confidence',
          align: 'left',
          style: 'width: 120px',
          headerStyle: 'font-weight: 600;font-size: 14px;width: 120px'
        },
        {
          name: 'coordinates',
          label: 'Координаты',
          field: 'coordinates',
          align: 'left',
          style: 'width: 300px;height: 49px;',
          headerStyle: 'font-weight: 600;font-size: 14px; width: 300px;'
        },
        {
          name: 'action',
          align: 'center',
          field: 'id',
          headerStyle: 'width: 30px;font-weight: 600;font-size: 14px;',
          style: 'width: 30px;padding: 0',
        }
      ],
      fileBuffer: [],
      detectionResponse: false
    }
  },

  methods: {

    addDetection(){
      let detectionObj = {
        params: [],
        picture: []
      }
      this.detection.push(detectionObj)
    },

    removeDetection(id){
      this.detection.splice(id,1)
    },

    sendManualDetection(data){
      let detection = [],
          defaultSettings = {
        dt_params_id: 1,
        dt_camera_id: 1,
        dt_method: "normal",
        dt_confidence: 0.5,
        dt_segment: "",
        dt_garbage: 0.95,
        dt_axis: -1
      }
      data.forEach(unit => {
        let detectionUnit = {
          params: unit.params.length !== 0 ? unit.params : JSON.stringify(defaultSettings),
          picture: unit.pictureData
        }
        detection.push(detectionUnit)
      })
      store
          .dispatch('sendManualDetection',{detection}).then(
          (resolve) => {
            console.log('sendManualDetection', resolve)
            resolve.array[0].forEach((unit,idx) => {
              this.detection[idx].id = idx
              this.detection[idx].canvasName = 'canvas-' + idx
              this.detection[idx].pictureResponse = unit[1]
              this.detection[idx].altResponse = unit[2]
              this.detection[idx].detectionsResponse = JSON.parse('[' + unit[0] + ']')
            })
            this.detectionResponse = true
            setTimeout(() =>{
              this.detection.forEach(dtc => {
                this.drawing(dtc.id)
              })
            },150)
          },
          (reject) => {
            console.log('sendManualDetection', reject)
          }
      )
    },

    //Прорисовка кадра с камеры/зон/детекций
    drawing(id,selectedId) {
      let canvas = document.getElementById('canvas-' + id);
      canvas.width = 900;
      canvas.height = 500;
      let ctx = canvas.getContext("2d");
      const cameraImage = new Image();
      cameraImage.src = this.detection[id].pictureResponse;
      cameraImage.onload = () => {
        ctx.drawImage(cameraImage, 0, 0, canvas.width, canvas.height);
        this.detection[id].detectionsResponse.forEach((detection,idx) => {
          selectedId === idx ? ctx.strokeStyle = 'rgb(232,0,0)' : ctx.strokeStyle = 'rgba(0,232,39,0.4)'
          let detectionPoints = [ {x: detection.xmin,y:detection.ymax },{x: detection.xmax, y: detection.ymax}, {x: detection.xmax, y: detection.ymin}, {x: detection.xmin, y: detection.ymin} ]
          ctx.lineWidth = 3;
          let closed = true;
          ctx.beginPath();
          for (const p of detectionPoints) {
            ctx.lineTo(p.x, p.y)
          }
          closed && ctx.closePath();
          ctx.stroke();
        })
      }
    },

    sendToDetection(){

      let detections = ref(this.detection)

      detections.value.forEach(unit => {
        const file = unit.picture;
        const reader = new FileReader();

        reader.onloadend = () => {
          unit.pictureData = (reader.result);
        }
        reader.readAsDataURL(file);
      })

      setTimeout(() => {
        this.sendManualDetection(detections.value)
      },150)
    }

  }

})

</script>

<style lang="scss">
  .manual-detection{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: 0 auto 40px auto;
    &__unit{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      -webkit-box-shadow: 0 0 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0 0 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0 0 20px 1px rgba(34, 60, 80, 0.2);
      margin: 0 0 20px 0;
    }
    &__settings{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 350px;
      width: 100%;
      padding: 0 !important;
      label{
        width: 100%;
        &:nth-child(1){
          .q-field__control{
            min-height: 300px;
          }
        }
      }
      textarea{
        resize: none !important;
      }
    }
    &__close{
      align-self: flex-start !important;
      padding: 3px !important;
      svg{
        width: 20px;
        height: 20px;
      }
    }
    &__output{
      display: flex;
      justify-content: space-between;
      width: calc(100% - 390px);
      padding: 0 !important;
      canvas{
        width: 450px;
        height: 350px;
      }
      .table-coordinates{
        display: flex;
        align-items: center;
        p{
          margin: 0;
          &:not(:first-child){
            margin: 0 0 0 5px;
          }
        }
      }
    }
  }
</style>
import {
  UserServicePromiseClient
} from "../../proto/pb/user_grpc_web_pb";
import {
  CreateUserRequest,
  DeleteUserRequest,
  ListUserRequest,
  UpdateUserRequest,
  User,
  UserFilter,
  UserFilterItem,
  UserPagination,
  UserSort,
  ListCompaniesOfUserRequest,
  ListRolesRequest
} from "../../proto/pb/user_pb";
import { RefreshTokenInterceptor } from '../../../users/services/refreshTokenInterceptor';
import { getAuthToken } from '../../../users/services/authMetadata';

// Количество на странице по-умолчанию
const defaultLimit:number = 100;

export class UsersApi {
  items: Array<User> = [];
  client: UserServicePromiseClient;
  metadata: any;

  constructor() {
    const host:string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new UserServicePromiseClient(host, null, {unaryInterceptors: [new RefreshTokenInterceptor()]});
  }

  // Установка и получение фильтров
  createFilter(data: any){

    const filter = new UserFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
        for (const i in data) {
            if (data[i].field_name && ('' !== data[i].field_name)) {
                const fItem = new UserFilterItem();
                fItem.setFieldName(data[i].field_name.toString());
                fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
                fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
                fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
                filter.addItems(fItem);
            }
        }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any){

    const sort = new UserSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'username';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any){

    const pagination = new UserPagination();
    const page:number = data.page !== undefined ? Number(data.page) : 1;
    const limit:number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages:number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt:number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getUsersListItems(filter: any, sort: any, pagination: any){

    const request = new ListUserRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listUser(request, getAuthToken())
  }

  // Получение элемента
  getUsersListItem(filter: any){

    const request = new ListUserRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listUser(request, getAuthToken())
  }

  // Создание элемента
  createUsersItem(data: any){

    const request = new CreateUserRequest();
    request.setFirstName(data.first_name);
    request.setLastName(data.last_name);
    request.setMiddleName(data.middle_name);
    request.setEmail(data.email);
    request.setUserRole(data.user_role);
    request.setUsername(data.username);
    request.setPassword(data.password);

    return this.client.createUser(request, getAuthToken())
  }

  // Редактировать элемент
  updateUsersItem(data: any){

    const request = new UpdateUserRequest();
    request.setUserId(data.id);
    request.setFirstName(data.first_name);
    request.setLastName(data.last_name);
    request.setMiddleName(data.middle_name);
    request.setEmail(data.email);
    request.setUserRole(data.user_role);
    request.setUsername(data.username);
    request.setPassword(data.password);

    return this.client.updateUser(request, getAuthToken())
  }

  // Удаление элемента
  deleteUsersItem(data: any){

    const request = new DeleteUserRequest();
    request.setUserId(data.id);

    return this.client.deleteUser(request, getAuthToken())
  }

  createUserCompaniesSort(data: any){

    const sort = new UserSort();
    const name:string = data.name !== undefined ? data.name.toString() : 'company_name';
    const exp:string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  getUserCompaniesListItems(data: any,sort: any, pagination: any){

    const request = new ListCompaniesOfUserRequest();

    // Устанавливаем параметры запроса
    request.setUserId(data.id);
    request.setSort(this.createUserCompaniesSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listCompanyOfUser(request, getAuthToken())
  }

  getUserRolesListItems(){

    const request = new ListRolesRequest()

    return this.client.listRoles(request, getAuthToken())
  }

  getUserListMenu(filter: any, sort: any, pagination: any){
    const request = new ListUserRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listUserMenu(request, getAuthToken())
  }

}

<template>

  <div class="user-watch">
    <div class="user-watch__header">
      <h3>{{ item.username }}</h3>
      <div class="company-watch__header-navigation">
        <q-btn color="primary" @click="editUser()"> Изменить </q-btn>
        <q-btn color="negative" @click="deleteUser()"> Удалить </q-btn>
      </div>
    </div>
    <div class="user-watch__body">
      <div class="user-watch__profile">
        <div class="user-watch__profile-header">
          <h3>Профиль</h3>
        </div>
        <div class="user-watch__profile-body">
          <ul>
            <li>
              <h3>Email:</h3>
              <p>{{ item.email }}</p>
            </li>
            <li>
              <h3>Имя:</h3>
              <p>{{ item.first_name }}</p>
            </li>
            <li>
              <h3>Фамилия:</h3>
              <p>{{ item.last_name }}</p>
            </li>
            <li>
              <h3>Админ:</h3>
              <p>{{ item.role }}</p>
            </li>
            <li>
              <h3>Добавлен:</h3>
              <p>{{ item.created }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="user-watch__companies">
        <div class="user-watch__companies-header">
          <h3>Компании: </h3>
          <q-btn color="white" text-color="black" @click="addCompanyUser()"> Добавить </q-btn>
        </div>
        <div class="user-watch__companies-body">
          <q-table
              flat
              :rows="getUserCompaniesListItems"
              :columns="columns"
              row-key="id"
              :loading="isUserCompaniesListBusy"
          >
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item clickable @click="deleteLink(props.value)">
                    <q-item-section>
                      <svg fill="#ff0000" version="1.1" id="Capa_1" width="18px" height="18px" viewBox="0 0 482.428 482.429" xml:space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path> <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path> <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path> <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path> </g> </g> </g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="watchCompany(props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </div>
    <q-dialog v-model="isActiveModal">
      <UserEdit
          v-if="isActiveModalEdit"
          :userId="selectedUserId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
      <UserDelete
          v-if="isActiveModalDelete"
          :userId="selectedUserId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
      <UserCompanyAdd
          v-if="isActiveModalAddCompanyUser"
          :userId="selectedUserId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
      <UserCompanyUnlink
          v-if="isActiveModalDeleteUserCompanyLink"
          :userId="selectedUserId"
          :companyId="selectedCompanyId"
          @afterAction="afterAction"
          @hideModal="hideModal"
      />
    </q-dialog>
  </div>

</template>

<script lang="ts">

import {defineComponent} from 'vue'
/*import store from "@/store";*/
import UserDelete from "@/components/modules/users/views/users/UserDelete.vue";
import UserEdit from "@/components/modules/users/views/users/UserEdit.vue";
import UserCompanyAdd from "@/components/modules/users/views/users/UserCompanyAdd.vue";
import UserCompanyUnlink from "@/components/modules/users/views/users/UserCompanyUnlink.vue";
import store from "@/store";
export default defineComponent({

  name: "UserWatch",

  components: {
    UserEdit,
    UserDelete,
    UserCompanyAdd,
    UserCompanyUnlink
  },

  data(){
    return{
      item: {
        user_id: 0,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        role: '',
        created: ''
      },
      columns: [
        {
          name: 'name',
          label: '',
          field: 'name',
          align: 'left',
          style: 'width: 100%'
        },
        {
          name: 'action',
          label: '',
          field: 'id',
          align: 'center'
        }
      ],
      isActiveModal: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      isActiveModalAddCompanyUser: false,
      isActiveModalDeleteUserCompanyLink: false,
      selectedUserId: null as any,
      selectedCompanyId: null as any,
      selectedCompany: null as any,
    }
  },

  computed: {

    isUserCompaniesListBusy() {
      return store.getters.getIsUserCamerasListBusy
    },

    //Формирование списка компаний пользователя
    getUserCompaniesListItems(){
      const items = store.getters.getUserCompaniesListItems
      let rows: any = []
      if (items !== undefined) {
        for (let company of items) {
          rows.push({
            name: company.getCompanyName(),
            id: company.getCompanyId()
          })
        }
      }
      return rows
    }

  },

  methods: {

    //Закрытие модальных окон
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.isActiveModalAddCompanyUser = false
      this.isActiveModalDeleteUserCompanyLink = false
      this.selectedUserId = null
    },

    //Редактирование пользователя
    editUser() {
      this.selectedUserId = this.item.user_id
      this.isActiveModalEdit = true
      console.log('editUser')
      this.isActiveModal = true
    },

    //Удаление пользователя
    deleteUser() {
      this.selectedUserId = this.item.user_id
      this.isActiveModalDelete = true
      console.log('deleteUser')
      this.isActiveModal = true
    },

    //Добавление связи пользователь-компания
    addCompanyUser() {
      this.selectedUserId = this.item.user_id
      this.isActiveModalAddCompanyUser = true
      console.log('addCompanyUser')
      this.isActiveModal = true
    },

    //Удаление связи пользователь компания
    deleteLink(id: any){
      this.selectedUserId = this.item.user_id
      this.selectedCompanyId = id
      this.isActiveModalDeleteUserCompanyLink = true
      console.log('deleteLink')
      this.isActiveModal = true
    },

    //Просмотр компании
    watchCompany(id: any){
      this.$router.push('/companies/watch/' + id)
    },

    //Прогрузка после дейсвтия
    afterAction() {
      console.log('afterAction')
      this.loadData()
      this.hideModal()
      this.getUserCompaniesList()
    },

    //Загрузка данных пользователя
    loadData() {
      store
          .dispatch('getUsersListItem', {
            filter: [
              {
                field_name: 'user_id',
                operand: '=',
                value: this.$route.params.id,
              },
            ],
          })
          .then(
              (resolve) => {
                console.log('loadData resolve', resolve)
                this.item = {
                  user_id: resolve.getUserId(),
                  username: resolve.getUsername(),
                  email: resolve.getEmail(),
                  first_name: resolve.getFirstName(),
                  last_name: resolve.getLastName(),
                  role: resolve.getUserRole(),
                  created: ''
                }
              },
              (reject) => {
                console.log('loadData reject', reject)
              }
          )
    },

    //Загрузка списка компаний пользователя
    getUserCompaniesList(){
      store
          .dispatch('getUserCompaniesListItems', {
            id: this.$route.params.id
          })
          .then(
              (resolve) => {
                console.log('getUserCompaniesListItems resolve', resolve)
              },
              (reject) => {
                console.log('getUserCompaniesListItems reject', reject)
              }
          )
    }

  },
  created() {
    this.loadData()
    this.getUserCompaniesList()
  }

})
</script>

<style lang="scss">

.user-watch{
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 30px 0;
    h3{
      font-weight: 500;
      margin: 0;
    }
    .q-btn{
      padding: 12px 16px;
      border-radius: 10px;
      box-shadow: unset !important;
      font-weight: 600;
      &:first-child{
        background: #3742fa !important;
        margin: 0 10px 0 0;
      }
    }
  }
  &__body{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__profile,&__companies{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: calc(50% - 10px);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
    &-header{
      background: #3742fa;
      border-bottom: 1px solid #b3b3b3;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 20px;
      border-radius: 10px 10px 0 0;
      height: 77px;
      h3{
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        color: #fff;
      }
    }
    .q-table__container{
      border-radius: 0 0 10px 10px;
      box-shadow: unset;
    }
  }
  &__profile {
    &-body {
      ul {
        padding: 18px 20px;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        li {
          display: flex;
          align-items: flex-start;
          list-style: none;
          width: 100%;
          &:not(:last-child), &:not(:first-child) {
            padding: 15px 0;
          }

          h3 {
            width: 160px;
            text-align: left;
            flex-shrink: 0;
          }

          h3, p {
            font-size: 16px;
            font-weight: 700;
            padding: 0;
            margin: 0;
            line-height: 100%;
          }

          p {
            font-weight: 400;
            padding: 0 0 0 15px;
          }
        }
      }
    }
  }
  &__companies{
    &-header{
      justify-content: space-between;
    }
    table{
      thead{
        tr{
          height: 0;
        }
      }
    }
  }
}

</style>
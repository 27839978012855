<template>
  <q-card style="width: 1024px; max-width: 80vw" class="q-pa-md">
    <q-separator />

    <div v-if="!isUserCompaniesListBusy">
      <q-card-section>
        <p>Вы действительно хотите удалить компанию у пользователя ?</p>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn color="negative" @click="actualDelete"> Удалить </q-btn>
        <q-btn color="primary" @click="closeDelete"> Отмена </q-btn>
      </q-card-actions>
    </div>
  </q-card>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UserCompanyUnlink',

  props: {
    userId: {
      type: Number,
      default: 0
    },
    companyId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      item: {
        id: 0,
        name: '',
      },
    }
  },

  computed: {
    isUserCompaniesListBusy() {
      return store.getters.getIsUserCamerasListBusy
    },
  },

  methods: {

    //Удалить связь компании и пользователя
    actualDelete() {
      let data = {
        user_id: this.userId,
        company_id: this.companyId,
        action: 'unlink'
      }
      store.dispatch('linkCompanyUser', data).then(
          (resolve) => {
            console.log('resolve', resolve)
            this.$emit('afterAction')
          },
          (reject) => {
            console.log('reject', reject)
          }
      )
      return true
    },

    //Закрыть форму удаления связи пользователя и компании
    closeDelete() {
      this.$emit('hideModal')
    },

  },
  
})
</script>

<template>
  <div class="users" style="height: calc(100% - 50px);">
    <q-card style="box-shadow: none">
      <div class="row">
        <div class="pre-header">
            <h3 style="margin: 0;">Пользователи: {{ getUsersListItems.length }}</h3>
            <q-btn
                color="primary"
                :disable="isUsersListBusy"
                label="Добавить"
                icon="mdi-plus"
                @click="addUser"
                v-if="userRole === 'super' || userRole === 'admin'"
            />
        </div>
        <div class="col-12">
          <q-table
            flat
            :rows="getUsersListItems"
            :columns="columns"
            row-key="id"
            :loading="isUsersListBusy"
            v-model:pagination="userPagination"
            :rows-per-page-options="rowsPerPageUserOptions"
            @request="onRequestUser"
            :key="tableKey"
          >
            <template v-slot:top>
              <q-input
                  dense
                  v-model="selectedId"
                  filled
                  label="ID"
              >
              </q-input>
              <q-input
                  dense
                  v-model="selectedName"
                  filled
                  label="Название"
              >
              </q-input>
              <q-btn @click="clearSelect">Очистить</q-btn>
              <q-btn @click="search">Отфильтровать</q-btn>
            </template>
            <template v-slot:body-cell-name="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-email="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-role="props">
              <q-td :props="props">
                <span>{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-company_count="props">
              <q-td :props="props">
                <span class="button" @click="watchCompanies(props.row.name)">{{ props.value }}</span>
              </q-td>
            </template>
            <template v-slot:body-cell-action="props">
              <q-td :props="props">
                <q-list style="display: flex">
                  <q-item clickable @click="actionRow('delete', props.value)">
                    <q-item-section>
                      <svg fill="#ff0000" version="1.1" id="Capa_1" width="18px" height="18px" viewBox="0 0 482.428 482.429" xml:space="preserve" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098 c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117 h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828 C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879 C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096 c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266 c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979 V115.744z"></path> <path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"></path> <path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07 c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"></path> <path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07 c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"></path> </g> </g> </g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="actionRow('edit', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m104.175 90.97-4.252 38.384 38.383-4.252L247.923 15.427V2.497L226.78-18.646h-12.93zm98.164-96.96 31.671 31.67" class="cls-1" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path><path d="m195.656 33.271-52.882 52.882" style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke:#000000;stroke-width:12;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:5;stroke-dasharray:none;stroke-opacity:1" transform="translate(-77.923 40.646)"></path></g></svg>
                    </q-item-section>
                  </q-item>
                  <q-item clickable @click="actionRow('watch', props.value)">
                    <q-item-section>
                      <svg width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 20L15.8033 15.8033M18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C14.6421 18 18 14.6421 18 10.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-td>
            </template>
            <template v-slot:no-data>
              <div class="col-12 text-center">Нет данных</div>
            </template>
          </q-table>
        </div>
      </div>
    </q-card>

    <q-dialog v-model="isActiveModal">
      <UserAdd
        v-if="isActiveModalAdd"
        @afterAction="afterAction"
        @hideModal="hideModal"
      />
      <UserEdit
        v-if="isActiveModalEdit"
        :userId="selectedUserId"
        @afterAction="afterAction"
        @hideModal="hideModal"
      />
      <UserDelete
        v-if="isActiveModalDelete"
        :userId="selectedUserId"
        @afterAction="afterAction"
        @hideModal="hideModal"
      />
    </q-dialog>
  </div>
</template>

<script lang="ts">
import store from '@/store'
import { defineComponent } from 'vue'
import UserAdd from './UserAdd.vue'
import UserDelete from './UserDelete.vue'
import UserEdit from './UserEdit.vue'


export default defineComponent({
  name: 'UsersList',

  components: {
    UserAdd,
    UserDelete,
    UserEdit,
  },

  data() {
    return {
      columns: [
        {
          name: 'name',
          label: 'Ф.И.О',
          field: 'fio',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'email',
          label: 'E-mail',
          field: 'email',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'role',
          label: 'Роль',
          field: 'role',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'company_count',
          label: 'Количество компаний',
          field: 'company_count',
          align: 'left',
          headerStyle: 'font-weight: 600;font-size: 14px;'
        },
        {
          name: 'action',
          label: 'Действие',
          field: 'id',
          align: 'center',
          headerStyle: 'width: 100px;font-weight: 600;font-size: 14px;',
          style: 'width: 40px',
        }
      ] as any,
      userPagination: {
        sortBy: 'username',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      rowsPerPageUserOptions: [5,10, 25, 50, 100],
      selectedId: null as any,
      selectedName: '',
      isActiveModal: false,
      isActiveModalAdd: false,
      isActiveModalEdit: false,
      isActiveModalDelete: false,
      selectedUserId: null as any,
      tableKey: 0,
      userRole: localStorage.getItem('userRole')
    }
  },

  computed: {
    isUsersListBusy() {
      return store.getters.getIsUsersListBusy
    },

    //Формирование списка пользователей
    getUsersListItems() {
      const users = store.getters.getUsersListItems

      let rows: any = []
      if (users !== undefined) {
        for (let user of users) {
            rows.push({
              id: user.getUserId(),
              name: user.getFirstName(),
              email: user.getEmail(),
              fio: user.getLastName() + ' ' + user.getFirstName() + ' ' + user.getMiddleName(),
              role: user.getUserRole(),
              company_count: user.getCompanyCount(),
              companies: []
            })
        }
      }
      return rows
    },

  },

  methods: {

    //Просмотр компании
    watchCompanies(name: any){
      this.$router.push('/companies?username=' + name)
    },

    //Закрытие модальных окон
    hideModal() {
      this.isActiveModal = false
      this.isActiveModalAdd = false
      this.isActiveModalEdit = false
      this.isActiveModalDelete = false
      this.selectedUserId = null
    },

    //Просмотр пользователя
    watchUser(){
      this.$router.push('/users/watch/' + this.selectedUserId)
    },

    //Добавление пользователя
    addUser() {
      this.isActiveModalAdd = true
      this.isActiveModal = true
    },

    //Изменение пользователя
    editUser() {
      this.isActiveModalEdit = true
      this.isActiveModal = true
    },

    //Удаление пользователя
    deleteUser() {
      this.isActiveModalDelete = true
      console.log('deleteUser')
      this.isActiveModal = true
    },

    //Функционал кнопок таблицы
    actionRow(method: any, id: any) {
      this.selectedUserId = id
      method === 'watch' ? this.watchUser() :
          method === 'edit' ? this.editUser() :
              method === 'delete' ? this.deleteUser() : console.log('Неизвестный метод')
    },

    //Прогрузка данных после действия
    afterAction() {
      console.log('afterAction')
      this.loadUsersListItems(true)
      this.hideModal()
    },

    //Запрос к таблице
    onRequestUser(props: any) {
      this.userPagination = props.pagination
      this.loadUsersListItems(false)
    },

    //Очистка поиска
    clearSelect() {
      let newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      this.selectedId = ''
      this.selectedName = ''
      this.loadUsersListItems(true)
    },

    //Поиск
    search(){
      let newURL = location.href.split("?")[0];
      window.history.pushState('object', document.title, newURL);
      this.loadUsersListItems(true)
    },

    //Фильтр пользователей
    getFilters() {
      let filter = []
      if(this.selectedName.length > 0){
        filter.push({
          field_name: 'first_name',
          operand: 'ilike',
          value: this.selectedName,
          or: [
            {
              field_name: 'last_name',
              operand: 'ilike',
              value: this.selectedName,
            },
            {
              field_name: 'middle_name',
              operand: 'ilike',
              value: this.selectedName,
            }
          ]
        })
      }
      if(this.selectedId > 0){
        filter.push({
          field_name: 'user_id',
          operand: '=',
          value: this.selectedId
        })
      }
      return filter
    },

    //Загрузка списка пользователей
    loadUsersListItems(isResetPagination: boolean) {
      if (isResetPagination) {
        this.userPagination.page = 1
        this.userPagination.rowsNumber = 0
      }
      store
        .dispatch('getUsersListItems', {
          filter: this.getFilters(),
          sort: {
            name: this.userPagination.sortBy,
            exp: this.userPagination.descending ? 'DESC' : 'ASC',
          },
          pagination: {
            page: this.userPagination.page,
            limit: this.userPagination.rowsPerPage,
            pages: 0,
            cnt: this.userPagination.rowsNumber,
          },
        })
        .then(
          (resolve) => {
            console.log('getUsersListItems resolve', resolve)
            this.userPagination.rowsNumber = resolve.pagination.getCnt()
            this.getUsersListItems.forEach((user: any) => {
              this.getUserCompaniesList(user)
            })
          },
          (reject) => {
            console.log('getUsersListItems reject', reject)
          }
        )
    },

    //Загрузка списка компаний пользователей
    getUserCompaniesList(user: any){
      store
          .dispatch('getUserCompaniesListItems', {
            id: user.id
          })
          .then(
              (resolve) => {
                console.log('getUserCompaniesListItems resolve', resolve)
                resolve.items.forEach((company: any) => {
                  user.companies.push(company.array[1])
                })
                this.tableKey++
                setTimeout(() => {
                  if(window.location.href.includes('?company_name')){
                    const link = new URL(window.location.href).searchParams.get('company_name')
                    this.getUsersListItems.forEach((userUnit: any,idx: any) => {
                      !userUnit.companies.includes(link) ? this.getUsersListItems.splice(idx, 1) : ''
                      this.tableKey++
                    })
                  }
                },50)
              },
              (reject) => {
                console.log('getUserCompaniesListItems reject', reject)
              }
          )
    },

    //Загрузка данных
    loadData() {
      this.hideModal()
      this.loadUsersListItems(true)
    },
  },

  created() {
    this.loadData()
  },
})
</script>

<style lang="scss">

  .users{
    max-width: 1440px;
    margin: 0 auto;
    .pre-header{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 30px 0;
      h3{
        font-weight: 500;
      }
      .q-btn{
        padding: 12px 16px;
        background: #3742fa !important;
        border-radius: 10px;
        box-shadow: unset !important;
        font-weight: 600;
      }
    }
    .search{
      padding: 20px !important;
      justify-content: flex-start;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      border-radius: 10px !important;
    }
    .q-card{
      padding: 0 !important;
    }
    .q-table__top {
      padding: 20px !important;
      justify-content: flex-start;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      border-radius: 10px !important;
      .q-field{
        max-width: 215px;
        &__native{
          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &__control{
          border-radius: 5px;
        }
      }
      .q-field__native {
        min-width: 150px;
      }
      .q-field__inner {
        margin: 0 15px 0 0;
      }

      button{
        margin: 0 0 0 auto;
        height: 50px;
        width: 170px;
        border-radius: 10px;
        background: #3742fa !important;
        color: #fff;
        font-weight: 600;
        &:last-child{
          margin: 0 0 0 20px;
        }
      }
    }

    .q-table__middle{
      margin: 35px 0 0 0;
      border-radius: 10px !important;
      padding: 20px !important;
      -webkit-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 0px 20px 1px rgba(34, 60, 80, 0.2);
      .q-td{
        vertical-align: middle;
      }
      .button{
        color: #027BE3;
        cursor: pointer;
      }
    }
    .q-field--dense .q-field__control, .q-field--dense .q-field__marginal{
      height: 56px;
    }
    .q-field--dense .q-field__label{
      font-size: 16px !important;
      top: 18px !important;
    }

    .q-table__bottom{
      border-top: unset;
    }

  }

</style>
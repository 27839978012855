/* eslint-disable */
// source: camera.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.Camera', null, global);
goog.exportSymbol('proto.proto.CameraFilter', null, global);
goog.exportSymbol('proto.proto.CameraFilterItem', null, global);
goog.exportSymbol('proto.proto.CameraPagination', null, global);
goog.exportSymbol('proto.proto.CameraSort', null, global);
goog.exportSymbol('proto.proto.CameraStat', null, global);
goog.exportSymbol('proto.proto.CameraStatAndImgSyncResponse', null, global);
goog.exportSymbol('proto.proto.CameraType', null, global);
goog.exportSymbol('proto.proto.CamerasTypesRequest', null, global);
goog.exportSymbol('proto.proto.CamerasTypesResponse', null, global);
goog.exportSymbol('proto.proto.CreateCameraRequest', null, global);
goog.exportSymbol('proto.proto.CreateCameraResponse', null, global);
goog.exportSymbol('proto.proto.DeleteCameraRequest', null, global);
goog.exportSymbol('proto.proto.DeleteCameraResponse', null, global);
goog.exportSymbol('proto.proto.ItemCameraRequest', null, global);
goog.exportSymbol('proto.proto.ItemCameraResponse', null, global);
goog.exportSymbol('proto.proto.ListCameraRequest', null, global);
goog.exportSymbol('proto.proto.ListCameraResponse', null, global);
goog.exportSymbol('proto.proto.ListNearCameraRequest', null, global);
goog.exportSymbol('proto.proto.PictureUpdateRequest', null, global);
goog.exportSymbol('proto.proto.PingCameraResponse', null, global);
goog.exportSymbol('proto.proto.UpdateCameraRequest', null, global);
goog.exportSymbol('proto.proto.UpdateCameraResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Camera = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Camera, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Camera.displayName = 'proto.proto.Camera';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CameraType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraType.displayName = 'proto.proto.CameraType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CameraStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraStat.displayName = 'proto.proto.CameraStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CameraFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraFilterItem.displayName = 'proto.proto.CameraFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CameraFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.CameraFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraFilter.displayName = 'proto.proto.CameraFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CameraSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraSort.displayName = 'proto.proto.CameraSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CameraPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraPagination.displayName = 'proto.proto.CameraPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemCameraRequest.displayName = 'proto.proto.ItemCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ItemCameraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ItemCameraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ItemCameraResponse.displayName = 'proto.proto.ItemCameraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListCameraRequest.displayName = 'proto.proto.ListCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListCameraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListCameraResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListCameraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListCameraResponse.displayName = 'proto.proto.ListCameraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListNearCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListNearCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListNearCameraRequest.displayName = 'proto.proto.ListNearCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateCameraRequest.displayName = 'proto.proto.CreateCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateCameraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateCameraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateCameraResponse.displayName = 'proto.proto.CreateCameraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateCameraRequest.displayName = 'proto.proto.UpdateCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PictureUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PictureUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PictureUpdateRequest.displayName = 'proto.proto.PictureUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateCameraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateCameraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateCameraResponse.displayName = 'proto.proto.UpdateCameraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteCameraRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteCameraRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteCameraRequest.displayName = 'proto.proto.DeleteCameraRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.DeleteCameraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.DeleteCameraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.DeleteCameraResponse.displayName = 'proto.proto.DeleteCameraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PingCameraResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PingCameraResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PingCameraResponse.displayName = 'proto.proto.PingCameraResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CameraStatAndImgSyncResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CameraStatAndImgSyncResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.CameraStatAndImgSyncResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CameraStatAndImgSyncResponse.displayName = 'proto.proto.CameraStatAndImgSyncResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CamerasTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CamerasTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CamerasTypesRequest.displayName = 'proto.proto.CamerasTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CamerasTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.CamerasTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.CamerasTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CamerasTypesResponse.displayName = 'proto.proto.CamerasTypesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Camera.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Camera.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Camera} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Camera.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cameraName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cameraModel: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cameraModelId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    cameraIp: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cameraMac: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cameraUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cameraCompanyName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cameraCompanyId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cameraServiceName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cameraServiceId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cameraLogin: jspb.Message.getFieldWithDefault(msg, 12, ""),
    cameraPassword: jspb.Message.getFieldWithDefault(msg, 13, ""),
    cameraSurveyPeriod: jspb.Message.getFieldWithDefault(msg, 14, 0),
    cameraCreated: jspb.Message.getFieldWithDefault(msg, 15, ""),
    cameraUuid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cameraPictureKey: jspb.Message.getFieldWithDefault(msg, 17, ""),
    cameraPictureUrl: jspb.Message.getFieldWithDefault(msg, 18, ""),
    cameraType: jspb.Message.getFieldWithDefault(msg, 19, 0),
    cameraTypeName: jspb.Message.getFieldWithDefault(msg, 20, ""),
    cameraLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    cameraLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    cameraDistance: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    cameraSector: jspb.Message.getFieldWithDefault(msg, 24, ""),
    cameraStatPeriod: jspb.Message.getFieldWithDefault(msg, 25, 0),
    cameraVacantCount: jspb.Message.getFieldWithDefault(msg, 26, 0),
    cameraStatus: jspb.Message.getFieldWithDefault(msg, 27, ""),
    cameraPictureUrlBackup: jspb.Message.getFieldWithDefault(msg, 28, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Camera}
 */
proto.proto.Camera.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Camera;
  return proto.proto.Camera.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Camera} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Camera}
 */
proto.proto.Camera.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraModel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraIp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraMac(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraCompanyName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraCompanyId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraServiceName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraServiceId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraLogin(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPassword(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraSurveyPeriod(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraCreated(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraUuid(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureKey(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureUrl(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraTypeName(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraLatitude(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraLongitude(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraDistance(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraSector(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraStatPeriod(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraVacantCount(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraStatus(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureUrlBackup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Camera.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Camera.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Camera} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Camera.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCameraName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCameraModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCameraModelId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCameraIp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCameraMac();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCameraUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCameraCompanyName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCameraCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getCameraServiceName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCameraServiceId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCameraLogin();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCameraPassword();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCameraSurveyPeriod();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getCameraCreated();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCameraUuid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCameraPictureKey();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCameraPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCameraType();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getCameraTypeName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCameraLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      21,
      f
    );
  }
  f = message.getCameraLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      22,
      f
    );
  }
  f = message.getCameraDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      23,
      f
    );
  }
  f = message.getCameraSector();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCameraStatPeriod();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getCameraVacantCount();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getCameraStatus();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCameraPictureUrlBackup();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string camera_name = 2;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string camera_model = 3;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 camera_model_id = 4;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string camera_ip = 5;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraIp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string camera_mac = 6;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraMac = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string camera_url = 7;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string camera_company_name = 8;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 camera_company_id = 9;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string camera_service_name = 10;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 camera_service_id = 11;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string camera_login = 12;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string camera_password = 13;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 camera_survey_period = 14;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraSurveyPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraSurveyPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string camera_created = 15;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string camera_uuid = 16;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string camera_picture_key = 17;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraPictureKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraPictureKey = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string camera_picture_url = 18;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 camera_type = 19;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraType = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string camera_type_name = 20;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional float camera_latitude = 21;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional float camera_longitude = 22;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional float camera_distance = 23;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional string camera_sector = 24;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraSector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraSector = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int64 camera_stat_period = 25;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraStatPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraStatPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int64 camera_vacant_count = 26;
 * @return {number}
 */
proto.proto.Camera.prototype.getCameraVacantCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraVacantCount = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string camera_status = 27;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string camera_picture_url_backup = 28;
 * @return {string}
 */
proto.proto.Camera.prototype.getCameraPictureUrlBackup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Camera} returns this
 */
proto.proto.Camera.prototype.setCameraPictureUrlBackup = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraType.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraType.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cameraTypeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cameraTypeNote: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraType}
 */
proto.proto.CameraType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraType;
  return proto.proto.CameraType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraType}
 */
proto.proto.CameraType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraTypeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraTypeNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraTypeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCameraTypeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCameraTypeNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 camera_type_id = 1;
 * @return {number}
 */
proto.proto.CameraType.prototype.getCameraTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraType} returns this
 */
proto.proto.CameraType.prototype.setCameraTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string camera_type_name = 2;
 * @return {string}
 */
proto.proto.CameraType.prototype.getCameraTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraType} returns this
 */
proto.proto.CameraType.prototype.setCameraTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string camera_type_note = 3;
 * @return {string}
 */
proto.proto.CameraType.prototype.getCameraTypeNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraType} returns this
 */
proto.proto.CameraType.prototype.setCameraTypeNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraStat.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    statId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statCameraId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statCameraName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    statCameraPictureUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    statZoneId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    statZoneName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statZoneSpot: jspb.Message.getFieldWithDefault(msg, 7, ""),
    statZoneIsAllowed: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    statShotCreated: jspb.Message.getFieldWithDefault(msg, 9, ""),
    statOccupiedCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    statVacantCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    statAllowedCount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    statDetectedCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    statDetectionId: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraStat}
 */
proto.proto.CameraStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraStat;
  return proto.proto.CameraStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraStat}
 */
proto.proto.CameraStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatCameraId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatCameraName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatCameraPictureUrl(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatZoneId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatZoneName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatZoneSpot(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatZoneIsAllowed(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatShotCreated(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatOccupiedCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatVacantCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatAllowedCount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatDetectedCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatDetectionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStatCameraId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStatCameraName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatCameraPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatZoneId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatZoneName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatZoneSpot();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatZoneIsAllowed();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getStatShotCreated();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatOccupiedCount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getStatVacantCount();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getStatAllowedCount();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getStatDetectedCount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getStatDetectionId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
};


/**
 * optional int64 stat_id = 1;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 stat_camera_id = 2;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string stat_camera_name = 3;
 * @return {string}
 */
proto.proto.CameraStat.prototype.getStatCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string stat_camera_picture_url = 4;
 * @return {string}
 */
proto.proto.CameraStat.prototype.getStatCameraPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatCameraPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 stat_zone_id = 5;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatZoneId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatZoneId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string stat_zone_name = 6;
 * @return {string}
 */
proto.proto.CameraStat.prototype.getStatZoneName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatZoneName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string stat_zone_spot = 7;
 * @return {string}
 */
proto.proto.CameraStat.prototype.getStatZoneSpot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatZoneSpot = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool stat_zone_is_allowed = 8;
 * @return {boolean}
 */
proto.proto.CameraStat.prototype.getStatZoneIsAllowed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatZoneIsAllowed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string stat_shot_created = 9;
 * @return {string}
 */
proto.proto.CameraStat.prototype.getStatShotCreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatShotCreated = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 stat_occupied_count = 10;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatOccupiedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatOccupiedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 stat_vacant_count = 11;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatVacantCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatVacantCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 stat_allowed_count = 12;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatAllowedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatAllowedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 stat_detected_count = 13;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatDetectedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatDetectedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 stat_detection_id = 14;
 * @return {number}
 */
proto.proto.CameraStat.prototype.getStatDetectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraStat} returns this
 */
proto.proto.CameraStat.prototype.setStatDetectionId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.CameraFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraFilterItem}
 */
proto.proto.CameraFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraFilterItem;
  return proto.proto.CameraFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraFilterItem}
 */
proto.proto.CameraFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.CameraFilter;
      reader.readMessage(value,proto.proto.CameraFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.CameraFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.CameraFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraFilterItem} returns this
 */
proto.proto.CameraFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.CameraFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraFilterItem} returns this
 */
proto.proto.CameraFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.CameraFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraFilterItem} returns this
 */
proto.proto.CameraFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CameraFilter or = 4;
 * @return {?proto.proto.CameraFilter}
 */
proto.proto.CameraFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.CameraFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.CameraFilter, 4));
};


/**
 * @param {?proto.proto.CameraFilter|undefined} value
 * @return {!proto.proto.CameraFilterItem} returns this
*/
proto.proto.CameraFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CameraFilterItem} returns this
 */
proto.proto.CameraFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CameraFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CameraFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.CameraFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraFilter}
 */
proto.proto.CameraFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraFilter;
  return proto.proto.CameraFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraFilter}
 */
proto.proto.CameraFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CameraFilterItem;
      reader.readMessage(value,proto.proto.CameraFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.CameraFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CameraFilterItem items = 1;
 * @return {!Array<!proto.proto.CameraFilterItem>}
 */
proto.proto.CameraFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.CameraFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CameraFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.CameraFilterItem>} value
 * @return {!proto.proto.CameraFilter} returns this
*/
proto.proto.CameraFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.CameraFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CameraFilterItem}
 */
proto.proto.CameraFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.CameraFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CameraFilter} returns this
 */
proto.proto.CameraFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraSort}
 */
proto.proto.CameraSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraSort;
  return proto.proto.CameraSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraSort}
 */
proto.proto.CameraSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.CameraSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraSort} returns this
 */
proto.proto.CameraSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.CameraSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraSort} returns this
 */
proto.proto.CameraSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraPagination}
 */
proto.proto.CameraPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraPagination;
  return proto.proto.CameraPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraPagination}
 */
proto.proto.CameraPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.CameraPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraPagination} returns this
 */
proto.proto.CameraPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.CameraPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraPagination} returns this
 */
proto.proto.CameraPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.CameraPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraPagination} returns this
 */
proto.proto.CameraPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.CameraPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CameraPagination} returns this
 */
proto.proto.CameraPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemCameraRequest}
 */
proto.proto.ItemCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemCameraRequest;
  return proto.proto.ItemCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemCameraRequest}
 */
proto.proto.ItemCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.ItemCameraRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ItemCameraRequest} returns this
 */
proto.proto.ItemCameraRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ItemCameraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ItemCameraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ItemCameraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemCameraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.proto.Camera.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ItemCameraResponse}
 */
proto.proto.ItemCameraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ItemCameraResponse;
  return proto.proto.ItemCameraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ItemCameraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ItemCameraResponse}
 */
proto.proto.ItemCameraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Camera;
      reader.readMessage(value,proto.proto.Camera.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ItemCameraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ItemCameraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ItemCameraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ItemCameraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Camera.serializeBinaryToWriter
    );
  }
};


/**
 * optional Camera item = 1;
 * @return {?proto.proto.Camera}
 */
proto.proto.ItemCameraResponse.prototype.getItem = function() {
  return /** @type{?proto.proto.Camera} */ (
    jspb.Message.getWrapperField(this, proto.proto.Camera, 1));
};


/**
 * @param {?proto.proto.Camera|undefined} value
 * @return {!proto.proto.ItemCameraResponse} returns this
*/
proto.proto.ItemCameraResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ItemCameraResponse} returns this
 */
proto.proto.ItemCameraResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ItemCameraResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.CameraFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.CameraSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.CameraPagination.toObject(includeInstance, f),
    mycompanyidlist: jspb.Message.getFieldWithDefault(msg, 4, ""),
    inmycompanyonly: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListCameraRequest}
 */
proto.proto.ListCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListCameraRequest;
  return proto.proto.ListCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListCameraRequest}
 */
proto.proto.ListCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CameraFilter;
      reader.readMessage(value,proto.proto.CameraFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.CameraSort;
      reader.readMessage(value,proto.proto.CameraSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.CameraPagination;
      reader.readMessage(value,proto.proto.CameraPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMycompanyidlist(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInmycompanyonly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.CameraFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.CameraSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.CameraPagination.serializeBinaryToWriter
    );
  }
  f = message.getMycompanyidlist();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInmycompanyonly();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional CameraFilter filter = 1;
 * @return {?proto.proto.CameraFilter}
 */
proto.proto.ListCameraRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.CameraFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.CameraFilter, 1));
};


/**
 * @param {?proto.proto.CameraFilter|undefined} value
 * @return {!proto.proto.ListCameraRequest} returns this
*/
proto.proto.ListCameraRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListCameraRequest} returns this
 */
proto.proto.ListCameraRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListCameraRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CameraSort sort = 2;
 * @return {?proto.proto.CameraSort}
 */
proto.proto.ListCameraRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.CameraSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.CameraSort, 2));
};


/**
 * @param {?proto.proto.CameraSort|undefined} value
 * @return {!proto.proto.ListCameraRequest} returns this
*/
proto.proto.ListCameraRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListCameraRequest} returns this
 */
proto.proto.ListCameraRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListCameraRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CameraPagination pagination = 3;
 * @return {?proto.proto.CameraPagination}
 */
proto.proto.ListCameraRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.CameraPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.CameraPagination, 3));
};


/**
 * @param {?proto.proto.CameraPagination|undefined} value
 * @return {!proto.proto.ListCameraRequest} returns this
*/
proto.proto.ListCameraRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListCameraRequest} returns this
 */
proto.proto.ListCameraRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListCameraRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string myCompanyIdList = 4;
 * @return {string}
 */
proto.proto.ListCameraRequest.prototype.getMycompanyidlist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListCameraRequest} returns this
 */
proto.proto.ListCameraRequest.prototype.setMycompanyidlist = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool inMyCompanyOnly = 5;
 * @return {boolean}
 */
proto.proto.ListCameraRequest.prototype.getInmycompanyonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListCameraRequest} returns this
 */
proto.proto.ListCameraRequest.prototype.setInmycompanyonly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListCameraResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListCameraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListCameraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListCameraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCameraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.Camera.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListCameraRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListCameraResponse}
 */
proto.proto.ListCameraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListCameraResponse;
  return proto.proto.ListCameraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListCameraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListCameraResponse}
 */
proto.proto.ListCameraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Camera;
      reader.readMessage(value,proto.proto.Camera.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListCameraRequest;
      reader.readMessage(value,proto.proto.ListCameraRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListCameraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListCameraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListCameraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListCameraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Camera.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListCameraRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Camera items = 1;
 * @return {!Array<!proto.proto.Camera>}
 */
proto.proto.ListCameraResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.Camera>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Camera, 1));
};


/**
 * @param {!Array<!proto.proto.Camera>} value
 * @return {!proto.proto.ListCameraResponse} returns this
*/
proto.proto.ListCameraResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Camera=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Camera}
 */
proto.proto.ListCameraResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Camera, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListCameraResponse} returns this
 */
proto.proto.ListCameraResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListCameraRequest params = 2;
 * @return {?proto.proto.ListCameraRequest}
 */
proto.proto.ListCameraResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListCameraRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListCameraRequest, 2));
};


/**
 * @param {?proto.proto.ListCameraRequest|undefined} value
 * @return {!proto.proto.ListCameraResponse} returns this
*/
proto.proto.ListCameraResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListCameraResponse} returns this
 */
proto.proto.ListCameraResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListCameraResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListNearCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListNearCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListNearCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListNearCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    myLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    myLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    distance: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListNearCameraRequest}
 */
proto.proto.ListNearCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListNearCameraRequest;
  return proto.proto.ListNearCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListNearCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListNearCameraRequest}
 */
proto.proto.ListNearCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMyLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMyLongitude(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListNearCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListNearCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListNearCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListNearCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMyLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMyLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional float my_latitude = 1;
 * @return {number}
 */
proto.proto.ListNearCameraRequest.prototype.getMyLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListNearCameraRequest} returns this
 */
proto.proto.ListNearCameraRequest.prototype.setMyLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float my_longitude = 2;
 * @return {number}
 */
proto.proto.ListNearCameraRequest.prototype.getMyLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListNearCameraRequest} returns this
 */
proto.proto.ListNearCameraRequest.prototype.setMyLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 distance = 3;
 * @return {number}
 */
proto.proto.ListNearCameraRequest.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListNearCameraRequest} returns this
 */
proto.proto.ListNearCameraRequest.prototype.setDistance = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cameraModelId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cameraIp: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cameraMac: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cameraUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cameraCompanyId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cameraServiceId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cameraLogin: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cameraPassword: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cameraSurveyPeriod: jspb.Message.getFieldWithDefault(msg, 10, 0),
    cameraType: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cameraLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    cameraLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    cameraSector: jspb.Message.getFieldWithDefault(msg, 14, ""),
    cameraStatPeriod: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateCameraRequest}
 */
proto.proto.CreateCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateCameraRequest;
  return proto.proto.CreateCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateCameraRequest}
 */
proto.proto.CreateCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraModelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraIp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraMac(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraUrl(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraCompanyId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraServiceId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraLogin(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPassword(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraSurveyPeriod(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraType(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraLatitude(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraLongitude(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraSector(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraStatPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCameraModelId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCameraIp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCameraMac();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCameraUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCameraCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCameraServiceId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCameraLogin();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCameraPassword();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCameraSurveyPeriod();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getCameraType();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCameraLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getCameraLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getCameraSector();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCameraStatPeriod();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
};


/**
 * optional string camera_name = 1;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 camera_model_id = 2;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string camera_ip = 3;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraIp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string camera_mac = 4;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraMac = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string camera_url = 5;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 camera_company_id = 6;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 camera_service_id = 7;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string camera_login = 8;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string camera_password = 9;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 camera_survey_period = 10;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraSurveyPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraSurveyPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 camera_type = 11;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraType = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional float camera_latitude = 12;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float camera_longitude = 13;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional string camera_sector = 14;
 * @return {string}
 */
proto.proto.CreateCameraRequest.prototype.getCameraSector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraSector = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 camera_stat_period = 15;
 * @return {number}
 */
proto.proto.CreateCameraRequest.prototype.getCameraStatPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraRequest} returns this
 */
proto.proto.CreateCameraRequest.prototype.setCameraStatPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateCameraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateCameraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateCameraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateCameraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateCameraResponse}
 */
proto.proto.CreateCameraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateCameraResponse;
  return proto.proto.CreateCameraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateCameraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateCameraResponse}
 */
proto.proto.CreateCameraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateCameraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateCameraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateCameraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateCameraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.CreateCameraResponse.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateCameraResponse} returns this
 */
proto.proto.CreateCameraResponse.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cameraName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cameraModelId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cameraIp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cameraMac: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cameraUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cameraCompanyId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    cameraServiceId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cameraLogin: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cameraPassword: jspb.Message.getFieldWithDefault(msg, 10, ""),
    cameraSurveyPeriod: jspb.Message.getFieldWithDefault(msg, 11, 0),
    cameraType: jspb.Message.getFieldWithDefault(msg, 12, 0),
    cameraLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    cameraLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    cameraSector: jspb.Message.getFieldWithDefault(msg, 15, ""),
    cameraStatPeriod: jspb.Message.getFieldWithDefault(msg, 16, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateCameraRequest}
 */
proto.proto.UpdateCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateCameraRequest;
  return proto.proto.UpdateCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateCameraRequest}
 */
proto.proto.UpdateCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraModelId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraIp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraMac(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraUrl(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraCompanyId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraServiceId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraLogin(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPassword(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraSurveyPeriod(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraType(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraLatitude(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCameraLongitude(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraSector(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraStatPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCameraName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCameraModelId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCameraIp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCameraMac();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCameraUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCameraCompanyId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getCameraServiceId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCameraLogin();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCameraPassword();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCameraSurveyPeriod();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCameraType();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCameraLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
  f = message.getCameraLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getCameraSector();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCameraStatPeriod();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string camera_name = 2;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 camera_model_id = 3;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraModelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraModelId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string camera_ip = 4;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraIp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraIp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string camera_mac = 5;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraMac = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraMac = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string camera_url = 6;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 camera_company_id = 7;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraCompanyId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraCompanyId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 camera_service_id = 8;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraServiceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraServiceId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string camera_login = 9;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string camera_password = 10;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 camera_survey_period = 11;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraSurveyPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraSurveyPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 camera_type = 12;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraType = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional float camera_latitude = 13;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional float camera_longitude = 14;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string camera_sector = 15;
 * @return {string}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraSector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraSector = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 camera_stat_period = 16;
 * @return {number}
 */
proto.proto.UpdateCameraRequest.prototype.getCameraStatPeriod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraRequest} returns this
 */
proto.proto.UpdateCameraRequest.prototype.setCameraStatPeriod = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PictureUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PictureUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PictureUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PictureUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cameraPictureKey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cameraPictureUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cameraPictureUrlBackup: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PictureUpdateRequest}
 */
proto.proto.PictureUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PictureUpdateRequest;
  return proto.proto.PictureUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PictureUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PictureUpdateRequest}
 */
proto.proto.PictureUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureUrl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureUrlBackup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PictureUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PictureUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PictureUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PictureUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCameraPictureKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCameraPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCameraPictureUrlBackup();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.PictureUpdateRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PictureUpdateRequest} returns this
 */
proto.proto.PictureUpdateRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string camera_picture_key = 2;
 * @return {string}
 */
proto.proto.PictureUpdateRequest.prototype.getCameraPictureKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PictureUpdateRequest} returns this
 */
proto.proto.PictureUpdateRequest.prototype.setCameraPictureKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string camera_picture_url = 3;
 * @return {string}
 */
proto.proto.PictureUpdateRequest.prototype.getCameraPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PictureUpdateRequest} returns this
 */
proto.proto.PictureUpdateRequest.prototype.setCameraPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string camera_picture_url_backup = 4;
 * @return {string}
 */
proto.proto.PictureUpdateRequest.prototype.getCameraPictureUrlBackup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PictureUpdateRequest} returns this
 */
proto.proto.PictureUpdateRequest.prototype.setCameraPictureUrlBackup = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateCameraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateCameraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateCameraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCameraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateCameraResponse}
 */
proto.proto.UpdateCameraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateCameraResponse;
  return proto.proto.UpdateCameraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateCameraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateCameraResponse}
 */
proto.proto.UpdateCameraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateCameraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateCameraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateCameraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateCameraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.UpdateCameraResponse.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateCameraResponse} returns this
 */
proto.proto.UpdateCameraResponse.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteCameraRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteCameraRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteCameraRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteCameraRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteCameraRequest}
 */
proto.proto.DeleteCameraRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteCameraRequest;
  return proto.proto.DeleteCameraRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteCameraRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteCameraRequest}
 */
proto.proto.DeleteCameraRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteCameraRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteCameraRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteCameraRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteCameraRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.DeleteCameraRequest.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteCameraRequest} returns this
 */
proto.proto.DeleteCameraRequest.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.DeleteCameraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.DeleteCameraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.DeleteCameraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteCameraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.DeleteCameraResponse}
 */
proto.proto.DeleteCameraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.DeleteCameraResponse;
  return proto.proto.DeleteCameraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.DeleteCameraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.DeleteCameraResponse}
 */
proto.proto.DeleteCameraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCameraId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.DeleteCameraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.DeleteCameraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.DeleteCameraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.DeleteCameraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 camera_id = 1;
 * @return {number}
 */
proto.proto.DeleteCameraResponse.prototype.getCameraId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.DeleteCameraResponse} returns this
 */
proto.proto.DeleteCameraResponse.prototype.setCameraId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PingCameraResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PingCameraResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PingCameraResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PingCameraResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraStatus: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PingCameraResponse}
 */
proto.proto.PingCameraResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PingCameraResponse;
  return proto.proto.PingCameraResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PingCameraResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PingCameraResponse}
 */
proto.proto.PingCameraResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PingCameraResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PingCameraResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PingCameraResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PingCameraResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string camera_status = 1;
 * @return {string}
 */
proto.proto.PingCameraResponse.prototype.getCameraStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.PingCameraResponse} returns this
 */
proto.proto.PingCameraResponse.prototype.setCameraStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CameraStatAndImgSyncResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CameraStatAndImgSyncResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CameraStatAndImgSyncResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraStatAndImgSyncResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraStatList: jspb.Message.toObjectList(msg.getCameraStatList(),
    proto.proto.CameraStat.toObject, includeInstance),
    cameraPictureUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CameraStatAndImgSyncResponse}
 */
proto.proto.CameraStatAndImgSyncResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CameraStatAndImgSyncResponse;
  return proto.proto.CameraStatAndImgSyncResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CameraStatAndImgSyncResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CameraStatAndImgSyncResponse}
 */
proto.proto.CameraStatAndImgSyncResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CameraStat;
      reader.readMessage(value,proto.proto.CameraStat.deserializeBinaryFromReader);
      msg.addCameraStat(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraPictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CameraStatAndImgSyncResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CameraStatAndImgSyncResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CameraStatAndImgSyncResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraStatList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.CameraStat.serializeBinaryToWriter
    );
  }
  f = message.getCameraPictureUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated CameraStat camera_stat = 1;
 * @return {!Array<!proto.proto.CameraStat>}
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.getCameraStatList = function() {
  return /** @type{!Array<!proto.proto.CameraStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CameraStat, 1));
};


/**
 * @param {!Array<!proto.proto.CameraStat>} value
 * @return {!proto.proto.CameraStatAndImgSyncResponse} returns this
*/
proto.proto.CameraStatAndImgSyncResponse.prototype.setCameraStatList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.CameraStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CameraStat}
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.addCameraStat = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.CameraStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CameraStatAndImgSyncResponse} returns this
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.clearCameraStatList = function() {
  return this.setCameraStatList([]);
};


/**
 * optional string camera_picture_url = 2;
 * @return {string}
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.getCameraPictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CameraStatAndImgSyncResponse} returns this
 */
proto.proto.CameraStatAndImgSyncResponse.prototype.setCameraPictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CamerasTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CamerasTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CamerasTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CamerasTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CamerasTypesRequest}
 */
proto.proto.CamerasTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CamerasTypesRequest;
  return proto.proto.CamerasTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CamerasTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CamerasTypesRequest}
 */
proto.proto.CamerasTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CamerasTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CamerasTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CamerasTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CamerasTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.CamerasTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CamerasTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CamerasTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CamerasTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CamerasTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.CameraType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CamerasTypesResponse}
 */
proto.proto.CamerasTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CamerasTypesResponse;
  return proto.proto.CamerasTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CamerasTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CamerasTypesResponse}
 */
proto.proto.CamerasTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.CameraType;
      reader.readMessage(value,proto.proto.CameraType.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CamerasTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CamerasTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CamerasTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CamerasTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.CameraType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CameraType items = 1;
 * @return {!Array<!proto.proto.CameraType>}
 */
proto.proto.CamerasTypesResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.CameraType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.CameraType, 1));
};


/**
 * @param {!Array<!proto.proto.CameraType>} value
 * @return {!proto.proto.CamerasTypesResponse} returns this
*/
proto.proto.CamerasTypesResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.CameraType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.CameraType}
 */
proto.proto.CamerasTypesResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.CameraType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.CamerasTypesResponse} returns this
 */
proto.proto.CamerasTypesResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
